import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import API from "../API/API";
import HeaderComp from "../components/HeaderComp";
import SubMenu from "../components/SubMenu";
import Analisis from "../screens/sustentabilidadscreens/Analisis";
import FundacionAra from "../screens/sustentabilidadscreens/FundacionAra";
import Informe from "../screens/sustentabilidadscreens/Informe";
import Vivienda from "../screens/sustentabilidadscreens/Vivienda";
import "../style/globalstyle.css";
import { GetContent, GetImageFromContent, GetPage } from "../scripts";
import DeclaratoriaEdge from "../screens/sustentabilidadscreens/DeclaratoriaEdge";

const submenu = [
  {
    id: 1,
    name: "Vivienda y entorno sustentable",
    pushto: "/sustentabilidad/vivienda",
  },
  { id: 2, name: "ISV Análisis", pushto: "/sustentabilidad/analisis" },
  {
    id: 3,
    name: "Informe",
    pushto: "/sustentabilidad/informe",
  },
  {
    id: 4,
    name: "Fundacion ARA",
    pushto: "/sustentabilidad/fundacion-ara",
  },
  {
    id: 5,
    name: "Declaratoria de compromiso de Edge",
    pushto: "/sustentabilidad/declaratoria-edge",
  },
];
const submenueng = [
  {
    id: 1,
    name: "Housing and sustainable environment",
    pushto: "/sustentabilidad/vivienda",
  },
  { id: 2, name: "hsi analysis", pushto: "/sustentabilidad/analisis" },
  {
    id: 3,
    name: "report",
    pushto: "/sustentabilidad/informe",
  },
  {
    id: 4,
    name: "ara foundation",
    pushto: "/sustentabilidad/fundacion-ara",
  },
  {
    id: 5,
    name: "EDGE Commitment Statement",
    pushto: "/sustentabilidad/declaratoria-edge",
  },
];

class SustentabilidadPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "sustainability").id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], "header", "main");
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="sustentabilidad"
          imageBackground={GetImageFromContent(
            this.headerImage,
            "img",
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === "_es" ? submenu : submenueng}
        />
        <div>
          <Switch>
            <Route
              path="/sustentabilidad/vivienda"
              exact
              component={Vivienda}
            />
            <Route
              path="/sustentabilidad/analisis"
              exact
              component={Analisis}
            />
            <Route path="/sustentabilidad/informe" exact component={Informe} />
            <Route
              path="/sustentabilidad/fundacion-ara"
              exact
              component={FundacionAra}
            />
            <Route path="/sustentabilidad/declaratoria-edge" exact component={DeclaratoriaEdge} />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(SustentabilidadPage)
);
