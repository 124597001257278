import React from 'react';
import moment from 'moment';

const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return '';
};

const timeLimit = {
  type: 'minutes',
  total: 1,
};

class GoogleGClid extends React.Component {
  constructor(props) {
    super(props);
    this.checkTimelimit = this.checkTimelimit.bind(this);
  }

  componentDidMount() {
    let returnGClid = '';
    const searchGClid = getQueryVariable('gclid');
    if (searchGClid !== '') {
    } else {
    }
    const savedGClid = sessionStorage.getItem('gclid_data');
    const savedGClidDate = sessionStorage.getItem('gclid_date');
    if (
      savedGClid !== undefined &&
      savedGClid !== null &&
      savedGClidDate !== undefined &&
      savedGClidDate !== null
    ) {
      if (savedGClid !== searchGClid && searchGClid !== '') {
        sessionStorage.setItem('gclid_data', searchGClid);
        sessionStorage.setItem('gclid_date', moment().toJSON());
        returnGClid = searchGClid;
      } else {
        let timeLimitUp = false;
        const dateToCheck = moment(savedGClidDate).add(
          timeLimit.total,
          timeLimit.type
        );

        if (dateToCheck.isBefore(moment())) {
          timeLimitUp = true;
        }
        if (timeLimitUp === true) {
          sessionStorage.removeItem('gclid_data');
          sessionStorage.removeItem('gclid_date');
        } else {
          returnGClid = savedGClid;
        }
      }
    } else if (searchGClid !== '') {
      sessionStorage.setItem('gclid_data', searchGClid);
      sessionStorage.setItem('gclid_date', moment().toJSON());
      returnGClid = searchGClid;
    }

    if (this.props.onGetGClid !== undefined) {
      this.props.onGetGClid(returnGClid);
    }
  }

  checkTimelimit(gClickDate) {
    let timeLimitUp = false;
    const dateToCheck = moment(gClickDate).add(timeLimit.total, timeLimit.type);

    if (dateToCheck.isBefore(moment())) {
      timeLimitUp = true;
    }
    return timeLimitUp;
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default GoogleGClid;
