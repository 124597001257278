import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container, Grid, Responsive } from 'semantic-ui-react';
import API from '../../API/API';
import { imghome1 } from '../../assets/Assets';
import { financiera, hometxt } from '../../assets/Language';
import MaskedImg from '../../components/MaskedImg';
import {
  GetArrayALike,
  GetContent,
  GetFileFromContent,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent,
  GetTextFromContent,
  UpdateArrayFile,
  UpdateArrayImage,
} from '../../scripts';

const files = [
  {
    id: 1,
    title: 'Emisiones de Certificados Bursátiles Propuestas HR Ratings',
    filename: 'Descargar',
    date: 'Noviembre 22, 2017',
    img: imghome1,
  },
  {
    id: 2,
    title: 'Emisiones de Certificados Bursátiles Propuestas HR Ratings',
    filename: 'Descargar',
    date: 'Noviembre 22, 2017',
    img: imghome1,
  },
  {
    id: 3,
    title: 'Emisiones de Certificados Bursátiles Propuestas HR Ratings',
    filename: 'Descargar',
    date: 'Noviembre 22, 2017',
    img: imghome1,
  },
  {
    id: 4,
    title: 'Emisiones de Certificados Bursátiles Propuestas HR Ratings',
    filename: 'Descargar',
    date: 'Noviembre 22, 2017',
    img: imghome1,
  },
];

class Calificaciones extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.comercialImage = {};
    this.footerContent = {};
    this.currentValue = '';
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'finacial').id;

      api.getContentOf(pageId).then((response) => {
        this.sectionContent = GetContent(response[0], 'ratings', 'info');

        this.array = GetArrayALike(response[0], 'ratings', 'review');

        api.getImageOf(pageId).then((responseImages) => {
          this.array = UpdateArrayImage(
            responseImages[0],
            'ratings',
            this.array
          );
          api.getFilesOf(pageId).then((responseFiles) => {
            this.array = UpdateArrayFile(
              responseFiles[0],
              'ratings',
              this.array
            );

            this.forceUpdate();
          });

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '3rem',
            }}
          >
            {financiera.calificaciones[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <Grid columns={2} style={{ paddingTop: '3rem' }}>
            {this.array.map((item) => {
              return (
                <Grid.Column width={8}>
                  <MaskedImg
                    singleButton
                    title={GetTextFromContent(
                      item,
                      'title',
                      this.props.currentlanguage
                    )}
                    fileName={hometxt.descargar[this.props.currentlanguage]}
                    date={GetTextFromContent(
                      item,
                      'date',
                      this.props.currentlanguage
                    )}
                    img={GetImageFromContent(
                      item,
                      'img',
                      this.props.currentlanguage
                    )}
                    file={GetFileFromContent(
                      item,
                      'file',
                      this.props.currentlanguage
                    )}
                  />
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '1rem',
            }}
          >
            {financiera.calificaciones[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <Grid columns={2} style={{ paddingTop: '1rem' }}>
            {this.array.map((item) => {
              return (
                <Grid.Column width={8}>
                  <MaskedImg
                    width="46vw"
                    titleSize="2rem"
                    titlePadding="1rem"
                    singleButton
                    title={GetTextFromContent(
                      item,
                      'title',
                      this.props.currentlanguage
                    )}
                    fileName={hometxt.descargar[this.props.currentlanguage]}
                    date={GetTextFromContent(
                      item,
                      'date',
                      this.props.currentlanguage
                    )}
                    img={GetImageFromContent(
                      item,
                      'img',
                      this.props.currentlanguage
                    )}
                    file={GetFileFromContent(
                      item,
                      'file',
                      this.props.currentlanguage
                    )}
                  />
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: '2.5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '1rem',
            }}
          >
            {financiera.calificaciones[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <Grid stackable columns={2} style={{ paddingTop: '1rem' }}>
            {this.array.map((item) => {
              return (
                <Grid.Column style={{}}>
                  <MaskedImg
                    singleButton
                    title={GetTextFromContent(
                      item,
                      'title',
                      this.props.currentlanguage
                    )}
                    fileName={hometxt.descargar[this.props.currentlanguage]}
                    date={GetTextFromContent(
                      item,
                      'date',
                      this.props.currentlanguage
                    )}
                    img={GetImageFromContent(
                      item,
                      'img',
                      this.props.currentlanguage
                    )}
                    file={GetFileFromContent(
                      item,
                      'file',
                      this.props.currentlanguage
                    )}
                  />
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Calificaciones)
);
