import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, List, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { corporativo } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import ModalGeneralInfo from "../../components/ModalGeneralInfo";
import {
  GetContent,
  GetPage,
  GetRichTextFromContent,
  GetSlider,
  GetTextFromContent
} from "../../scripts";

const cargos = [
  { id: 1, cargo: "▴  Consejeros Independientes", value: "▴" },
  { id: 2, cargo: "■    Consejeros Relacionados", value: "■" },
  { id: 3, cargo: "●    Consejeros Patrimoniales", value: "●" }
];

const cargoseng = [
  { id: 1, cargo: "▴  Independent Board Member", value: "▴" },
  { id: 2, cargo: "■    Related Board Member", value: "■" },
  { id: 3, cargo: "●    Owner Board Member", value: "●" }
];

class ConsejoComites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentSelected: {}
    };
    this.verifyPages = this.verifyPages.bind(this);
    this.topInfoContent = {};
    this.arrayAdmins = [];
    this.arrayAudits = [];
    this.arrayCorporate = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;
      api.getContentOf(pageId).then(response => {
        this.topInfoContent = GetContent(response[0], "council", "topInfo");
        if (this.arrayAdmins.length === 0) {
          const tempAdmins = GetSlider(
            response[0],
            "council",
            "administrative"
          );
          for (let i = 0; i < tempAdmins.length; i++) {
            const currentAdmin = tempAdmins[i];
            const addAdmin = {
              id: i,
              name: GetTextFromContent(
                currentAdmin,
                "name",
                this.props.currentlanguage
              ),
              cargo: GetTextFromContent(
                currentAdmin,
                "position",
                this.props.currentlanguage
              ),
              suplente: GetTextFromContent(
                currentAdmin,
                "replacement",
                this.props.currentlanguage
              ),
              distintivo: GetTextFromContent(
                currentAdmin,
                "distinctive",
                this.props.currentlanguage
              ),
              body: GetRichTextFromContent(
                currentAdmin,
                "body",
                this.props.currentlanguage
              )
            };
            this.arrayAdmins.push(addAdmin);
          }
        }
        if (this.arrayAudits.length === 0) {
          const tempAudit = GetSlider(response[0], "council", "audit");
          for (let i = 0; i < tempAudit.length; i++) {
            const currentAudit = tempAudit[i];
            const addAudit = {
              id: i,
              name: GetTextFromContent(
                currentAudit,
                "name",
                this.props.currentlanguage
              ),
              cargo: GetTextFromContent(
                currentAudit,
                "position",
                this.props.currentlanguage
              ),
              body: GetRichTextFromContent(
                currentAudit,
                "body",
                this.props.currentlanguage
              )
            };
            this.arrayAudits.push(addAudit);
          }
        }
        if (this.arrayCorporate.length === 0) {
          const tempCorporate = GetSlider(response[0], "council", "corporate");
          for (let i = 0; i < tempCorporate.length; i++) {
            const currentCorporate = tempCorporate[i];
            const addCorporate = {
              id: i,
              name: GetTextFromContent(
                currentCorporate,
                "name",
                this.props.currentlanguage
              ),
              cargo: GetTextFromContent(
                currentCorporate,
                "position",
                this.props.currentlanguage
              ),
              body: GetRichTextFromContent(
                currentCorporate,
                "body",
                this.props.currentlanguage
              )
            };
            this.arrayCorporate.push(addCorporate);
          }
        }
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {corporativo.consejo[this.props.currentlanguage]}
          </h1>

          <Comptitle
            simple
            title={corporativo.consejotitle[this.props.currentlanguage]}
            body={GetTextFromContent(
              this.topInfoContent,
              "title0",
              this.props.currentlanguage
            )}
            titlesize="1.5rem"
            style={{ padding: "2rem 0rem 1rem" }}
          />

          <h1 style={{ fontSize: "1rem" }}>
            {GetTextFromContent(
              this.topInfoContent,
              "title1",
              this.props.currentlanguage
            )}
          </h1>

          <Grid divided="vertically" style={{ padding: "3rem 0rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={6}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.suplente[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAdmins.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name} {item.distintivo}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{item.suplente}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <List style={{ paddingBottom: "1rem" }}>
            {this.props.currentlanguage === "_es"
              ? cargos.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })
              : cargoseng.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })}
          </List>

          <p>{corporativo.desconsejo[this.props.currentlanguage]}</p>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.comitetitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audnombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audcargo[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAudits.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.practicastitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audnombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audcargo[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayCorporate.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {corporativo.consejo[this.props.currentlanguage]}
          </h1>

          <Comptitle
            simple
            title={corporativo.consejotitle[this.props.currentlanguage]}
            body={GetTextFromContent(
              this.topInfoContent,
              "title0",
              this.props.currentlanguage
            )}
            titlesize="1.5rem"
            style={{ padding: "2rem 0rem 1rem" }}
          />

          <h1 style={{ fontSize: "1rem" }}>
            {GetTextFromContent(
              this.topInfoContent,
              "title1",
              this.props.currentlanguage
            )}
          </h1>

          <Grid divided="vertically" style={{ padding: "3rem 0rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={6} style={{}}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={3}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={7}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.suplente[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAdmins.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{}}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name} {item.distintivo}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label>{item.suplente}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <List style={{ paddingBottom: "1rem" }}>
            {this.props.currentlanguage === "_es"
              ? cargos.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })
              : cargoseng.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })}
          </List>

          <p>{corporativo.desconsejo[this.props.currentlanguage]}</p>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.comitetitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audnombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.audcargo[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAudits.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>
          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.practicastitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayCorporate.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {corporativo.consejo[this.props.currentlanguage]}
          </h1>

          <Comptitle
            simple
            title={corporativo.consejotitle[this.props.currentlanguage]}
            body={GetTextFromContent(
              this.topInfoContent,
              "title0",
              this.props.currentlanguage
            )}
            titlesize="1.5rem"
            style={{ padding: "1rem 0rem 1rem" }}
          />

          <h1 style={{ fontSize: "1rem" }}>
            {GetTextFromContent(
              this.topInfoContent,
              "title1",
              this.props.currentlanguage
            )}
          </h1>

          <Grid divided="vertically" style={{ padding: "3rem 0rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={5} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={7}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.suplente[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAdmins.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={5} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name} {item.distintivo}
                    </a>
                  </Grid.Column>
                  <Grid.Column style={{ wordWrap: "break-word" }} width={6}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <label>{item.suplente}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <List style={{ paddingBottom: "1rem" }}>
            {this.props.currentlanguage === "_es"
              ? cargos.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })
              : cargoseng.map(item => {
                  return <List.Item>{item.cargo}</List.Item>;
                })}
          </List>

          <p>{corporativo.desconsejo[this.props.currentlanguage]}</p>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.comitetitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={10} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={6}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayAudits.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={10} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>
          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.practicastitle[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayCorporate.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={12} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
          <p>{corporativo.audnota[this.props.currentlanguage]}</p>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
        <ModalGeneralInfo
          showModal={this.state.showModal}
          closeModal={() => {
            this.setState({ showModal: false });
          }}
          title={this.state.currentSelected.name}
          body={this.state.currentSelected.body}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ConsejoComites)
);
