import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  Accordion,
  Container,
  List,
  Responsive,
  Transition
} from "semantic-ui-react";
import "../style/globalstyle.css";

class MenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.options = [];
    // this.verifyCategories = this.verifyCategories.bind(this);
  }

  // verifyCategories(categories) {
  //   if (categories.length > 0) {
  //     
  //     this.options = [];
  //     for (let index = 0; index < categories.length; index++) {
  //       const element = categories[index];
  //       const tempOption = {
  //         fullObj: element,
  //         text: element.name,
  //         key: element.id
  //       };
  //       this.options.push(tempOption);
  //     }
  //     this.forceUpdate();
  //   }
  // }
  // componentDidMount() {
  //   this.verifyCategories(this.props.categories);
  // }

  // componentWillReceiveProps(nextProps) {
  //   this.verifyCategories(nextProps.categories);
  // }

  handleItemClick = navigateto => {
    this.props.push(navigateto);
  };

  state = { activeIndex: 0, activeItem: "home", visible: false };

  handleClicka = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  toggleVisibility = () => this.setState({ visible: !this.state.visible });

  renderInTablet() {
    const { activeIndex, activeItem, visible } = this.state;

    return (
      <Container style={{ textAlign: "center" }}>
        <List className="animatedmenu">
          {this.props.listitem.map((item, index) => {
            if (item.suboptions.length > 0) {
              return (
                <Accordion
                  key={item.id}
                  style={{
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  <Accordion.Title
                    active={
                      activeIndex === item.id && visible === true ? true : false
                    }
                    index={index}
                    onClick={() => {
                      this.setState({ activeIndex: item.id });
                      this.toggleVisibility();
                    }}
                    style={{
                      color: "white",
                      fontSize: "1.75rem"
                    }}
                  >
                    {item.name}
                    {/* {menulan[item["id"]][this.props.currentlanguage]} */}
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === item.id && visible === true}
                  >
                    <div id="insidediv" style={{ padding: "0.5rem 0rem 1rem" }}>
                      {item.suboptions.map(option => (
                        <Transition
                          visible={
                            activeIndex === item.id && visible === true
                              ? true
                              : false
                          }
                          animation="vertical flip"
                          duration={400}
                        >
                          <List.Item
                            style={{
                              paddingBottom:
                                activeIndex === item.id && visible === true
                                  ? "1rem"
                                  : "0rem",
                              textAlign: "center",
                              fontWeight: "normal",
                              fontSize: "1.5rem",
                              lineHeight: 1.0
                            }}
                            key={option.key}
                            onClick={() => {
                              this.props.onClickMobileMenu();
                              this.props.push(option.navigateto);
                              this.setState({
                                activeIndex: 0,
                                visible: false
                              });
                            }}
                          >
                            {option.name}
                          </List.Item>
                        </Transition>
                      ))}
                    </div>
                  </Accordion.Content>
                </Accordion>
              );
            } else {
              return (
                <List.Item
                  key={item.id}
                  active={activeItem === item.name}
                  onClick={() => {
                    this.props.onClickMobileMenu();
                    this.handleItemClick(item.navigateto);
                  }}
                  style={{
                    color: "white",
                    fontSize: "1.7rem",
                    textAlign: "center"
                  }}
                >
                  {item.name}
                  {/* {menulan[item["id"]][this.props.currentlanguage]} */}
                </List.Item>
              );
            }
          })}
        </List>
      </Container>
    );
  }

  renderInMobile() {
    const { activeIndex, activeItem, visible } = this.state;

    return (
      <Container style={{ textAlign: "center" }}>
        <List className="animatedmenu">
          {this.props.listitem.map((item, index) => {
            if (item.suboptions.length > 0) {
              return (
                <Accordion
                  key={item.id}
                  style={{
                    color: "white",
                    textAlign: "center"
                  }}
                >
                  <Accordion.Title
                    active={
                      activeIndex === item.id && visible === true ? true : false
                    }
                    index={index}
                    onClick={() => {
                      this.setState({ activeIndex: item.id });
                      this.toggleVisibility();
                    }}
                    style={{
                      color: "white",
                      fontSize: "1.75rem"
                    }}
                  >
                    {item.name}
                    {/* {menulan[item["id"]][this.props.currentlanguage]} */}
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === item.id && visible === true}
                  >
                    <div id="insidediv" style={{ padding: "0.5rem 0rem 1rem" }}>
                      {item.suboptions.map(option => (
                        <Transition
                          visible={
                            activeIndex === item.id && visible === true
                              ? true
                              : false
                          }
                          animation="vertical flip"
                          duration={400}
                        >
                          <List.Item
                            style={{
                              paddingBottom:
                                activeIndex === item.id && visible === true
                                  ? "1rem"
                                  : "0rem",
                              textAlign: "center",
                              fontWeight: "normal",
                              fontSize: "1.5rem",
                              lineHeight: 1.0
                            }}
                            key={option.key}
                            onClick={() => {
                              this.props.onClickMobileMenu();
                              this.props.push(option.navigateto);
                              this.setState({
                                activeIndex: 0,
                                visible: false
                              });
                            }}
                          >
                            {option.name}
                          </List.Item>
                        </Transition>
                      ))}
                    </div>
                  </Accordion.Content>
                </Accordion>
              );
            } else {
              return (
                <List.Item
                  key={item.id}
                  active={activeItem === item.name}
                  onClick={() => {
                    this.props.onClickMobileMenu();
                    this.handleItemClick(item.navigateto);
                  }}
                  style={{
                    color: "white",
                    fontSize: "1.7rem",
                    textAlign: "center"
                  }}
                >
                  {item.name}
                  {/* {menulan[item["id"]][this.props.currentlanguage]} */}
                </List.Item>
              );
            }
          })}
        </List>
      </Container>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages } = data;
  return {
    pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuItems)
);
