import React, { Component } from "react";
import Home1 from "../screens/homepagescreens/Home1";
import Home2 from "../screens/homepagescreens/Home2";
import Home3 from "../screens/homepagescreens/Home3";
import Home4 from "../screens/homepagescreens/Home4";
import Home5 from "../screens/homepagescreens/Home5";
import "../style/globalstyle.css";

class HomePage extends Component {
  render() {
    return (
      <div>
        <Home1 />
        <Home2 />
        <Home3 />
        <Home4 />
        <Home5 />
      </div>
    );
  }
}
export default HomePage;
