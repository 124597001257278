import bannerHomev from "./bannerhome.png";
import img1 from "./img1.png";
import logoc from "./logo.png";
import certificationc from "./certification.png";
import esrc from "./esr.png";
import esrcolorr from "./esrcolor.png";
import centrocomercialc from "./centros-comerciales.jpg";
import img01 from "./imgconsorcio.jpg";
import casasaraimg from "./casas-ara.png";
import araresidencialimg from "./ara-residencial.png";
import rialtaimg from "./rialta.png";
import dreamlagoonsimg from "./dream-lagoons.png";
import departamentosaraimg from "./integrara.png";
import ingresos from "./01.png";
import utilidadneta from "./02.png";
import deudaneta from "./03.png";
import consorciobanner1 from "./perfil-corporativo.jpg";
import financierobanner from "./informacion-financiera.jpg";
import bursatilbanner from "./bursatil.jpg";
import corporativobanner from "./gobierno-corporativo.jpg";
import sustentabilidadbanner from "./sustentabilidad.jpg";
import eventosbanner from "./eventos.jpg";
import mision1 from "./mision.jpg";
import valores1 from "./valores.jpg";
import revista1 from "./2014.jpg";
import imgfort01 from "./mejiko.png";
import imgfort02 from "./solidez.png";
import imgfort03 from "./diversificacion.png";
import imgfort04 from "./integracion.png";
import imgfort05 from "./procesos.png";
import imgfort06 from "./equipo.png";
import imgfort07 from "./salu2.png";
import dividendos1 from "./dividendosagosto.png";
import premio01 from "./premio1.png";
import search1 from "./search.png";
import email1 from "./email.png";
import logocasasara from "./logocasasara.png";
import logoss from "./logos.png";
import logoss4 from "./logos4.png";
import logoscomerciales from "./logoscomerciales.png";
import centroscomerciales from "./centroscomerciales.png";
import grafica from "./graph.png";
import presenciageo from "./mapita.png";
import estructuraaccionaria from "./grafiquita.png";
import estructuraaccionaria2 from "./grafiquita2.png";
import emailicon02 from "./mail2icon.png";
import phoneicon01 from "./phoneicon.png";
import fundacionara1 from "./fundacionara.png";
import icongrid01 from "./icon1.png";
import icongrid02 from "./icon2.png";
import icongrid03 from "./icon3.png";
import icongrid04 from "./icon4.png";
import arabg1 from "./ara.png";
import consorcioaralogo01 from "./consorcioara-logo.png";
import buzon01 from "./buzon_02.jpg";
import esrWhite from "./ESRactual_white.png";

export const bannerHome = bannerHomev;
export const image1 = img1;
export const logo = logoc;
export const certification = certificationc;
export const esr = esrc;
export const esrcolor = esrcolorr;
export const banner1 = centrocomercialc;
export const imghome1 = img01;
export const casasara = casasaraimg;
export const araresidencial = araresidencialimg;
export const rialta = rialtaimg;
export const dreamlagoons = dreamlagoonsimg;
export const departamentos = departamentosaraimg;
export const graph1 = ingresos;
export const graph2 = utilidadneta;
export const graph3 = deudaneta;
export const bannerconsorcio = consorciobanner1;
export const bannerfinanciero = financierobanner;
export const bannerbursatil = bursatilbanner;
export const bannercorporativo = corporativobanner;
export const bannersustentabilidad = sustentabilidadbanner;
export const bannereventos = eventosbanner;
export const mision = mision1;
export const valores = valores1;
export const revista = revista1;
export const imgfort1 = imgfort01;
export const imgfort2 = imgfort02;
export const imgfort3 = imgfort03;
export const imgfort4 = imgfort04;
export const imgfort5 = imgfort05;
export const imgfort6 = imgfort06;
export const imgfort7 = imgfort07;
export const dividendos = dividendos1;
export const premio1 = premio01;
export const search = search1;
export const email = email1;
export const logoscasas = logocasasara;
export const logos = logoss;
export const logos4 = logoss4;
export const logoscom = logoscomerciales;
export const tabla = centroscomerciales;
export const graph = grafica;
export const pregeo = presenciageo;
export const imgestructura = estructuraaccionaria;
export const imgestructura2 = estructuraaccionaria2;
export const emailicon = emailicon02;
export const phoneicon = phoneicon01;
export const fundacionara = fundacionara1;
export const icongrid1 = icongrid01;
export const icongrid2 = icongrid02;
export const icongrid3 = icongrid03;
export const icongrid4 = icongrid04;
export const arabg = arabg1;
export const logoara = consorcioaralogo01;
export const bannerbuzon = buzon01;
export const esrnew = esrWhite;
