// Import package from node modules
import * as types from "../types";

export const setPages = pages => {
  return {
    type: types.SET_PAGES,
    pages: pages
  };
};

export const setAPI = api => {
  return {
    type: types.SET_API,
    api: api
  };
};

export const setLanguage = currentlanguage => {
  return {
    type: types.SET_LANGUAGE,
    currentlanguage: currentlanguage
  };
};
