import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { logos, logos4, logoscasas } from "../../assets/Assets";
import { consorcioara } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import {
  GetArrayALike,
  GetContent,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent,
  GetTextFromContent,
  UpdateArrayImage,
  GetSlider
} from "../../scripts";

const getIndexText = index => {
  switch (index) {
    case 0: {
      return "interes";
    }
    case 1: {
      return "tipo";
    }
    case 2: {
      return "residencial";
    }
    case 3: {
      return "comerciales";
    }
    default: {
      return "";
    }
  }
};

class PortafolioDeProductos extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.comercialPortfolio = {};
    this.comercialImage = {};
    this.footerContent = {};
    this.currentValue = "";
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.comercialPortfolio = GetContent(
          response[0],
          "productPortfolio",
          "centroComercial"
        );
        this.array = GetArrayALike(
          response[0],
          "productPortfolio",
          "portfolio"
        );

        

        api.getImageOf(pageId).then(responseImages => {
          
          this.array = UpdateArrayImage(
            responseImages[0],
            "productPortfolio",
            this.array
          );

          this.comercialImage = GetContent(
            responseImages[0],
            "productPortfolio",
            "centroComercial"
          );
          this.footerContent = GetContent(
            responseImages[0],
            "productPortfolio",
            "footer"
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.portafolio[this.props.currentlanguage]}
          </h1>
          {this.array.map((item, index) => {
            return (
              <Grid style={{ paddingBottom: "4rem" }}>
                <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                  {
                    consorcioara[getIndexText(index)][
                      this.props.currentlanguage
                    ]
                  }
                </h1>
                <Grid.Row
                  style={{ backgroundColor: "#edf4fa", marginBottom: "1rem" }}
                >
                  <Image.Group>
                    <Image
                      src={GetImageFromContent(
                        item,
                        "img",
                        this.props.currentlanguage
                      )}
                      style={{ maxHeight: "12rem" }}
                    />
                    ;
                  </Image.Group>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column width={6}>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "priceRange",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.rangoPrecios[this.props.currentlanguage]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "financing",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.financiamiento[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "monthly",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.ingresoMensual[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "umam",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title="UMAM***:"
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "socioeconomic",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.socioeconomico[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid.Row style={{ paddingBottom: "2rem" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: GetRichTextFromContent(
                            item,
                            "noteCall",
                            this.props.currentlanguage
                          )
                        }}
                      />
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            );
          })}
          <Grid style={{ paddingBottom: "3rem" }}>
            <Grid.Row>
              <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                {consorcioara.comerciales[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row
              style={{ backgroundColor: "#edf4fa", marginBottom: "2rem" }}
            >
              <Image.Group>
                <Image
                  src={GetImageFromContent(
                    this.comercialImage,
                    "imgMain",
                    this.props.currentlanguage
                  )}
                  style={{ maxHeight: "12rem" }}
                />
                ;
              </Image.Group>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: "6rem" }}>
              <Image
                src={GetImageFromContent(
                  this.comercialImage,
                  "imgBody",
                  this.props.currentlanguage
                )}
              />
            </Grid.Row>
            <Grid.Row>
              <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                {consorcioara.ingresos[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row>
              <Image
                centered
                src={GetImageFromContent(
                  this.footerContent,
                  "img",
                  this.props.currentlanguage
                )}
              />
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.portafolio[this.props.currentlanguage]}
          </h1>
          {this.array.map((item, index) => {
            return (
              <Grid style={{ paddingBottom: "4rem" }}>
                <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                  {
                    consorcioara[getIndexText(index)][
                      this.props.currentlanguage
                    ]
                  }
                </h1>
                <Grid.Row
                  style={{ backgroundColor: "#edf4fa", marginBottom: "1rem" }}
                >
                  <Image.Group>
                    <Image
                      src={GetImageFromContent(
                        item,
                        "img",
                        this.props.currentlanguage
                      )}
                      style={{ maxHeight: "12rem", maxWidth: "96%" }}
                    />
                    ;
                  </Image.Group>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column width={6}>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "priceRange",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.rangoPrecios[this.props.currentlanguage]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "financing",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.financiamiento[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "monthly",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.ingresoMensual[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "umam",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title="UMAM***:"
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: "0.5rem" }}>
                      <Comptitle
                        simple
                        body={GetTextFromContent(
                          item,
                          "socioeconomic",
                          this.props.currentlanguage
                        )}
                        bodycolor="#00457b"
                        titlesize="1.5rem"
                        title={
                          consorcioara.socioeconomico[
                            this.props.currentlanguage
                          ]
                        }
                        bodysize="1.2rem"
                      />
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid.Row style={{ paddingBottom: "2rem" }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: GetRichTextFromContent(
                            item,
                            "noteCall",
                            this.props.currentlanguage
                          )
                        }}
                      />
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            );
          })}
          <Grid style={{ paddingBottom: "3rem" }}>
            <Grid.Row>
              <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                {consorcioara.comerciales[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row
              style={{ backgroundColor: "#edf4fa", marginBottom: "2rem" }}
            >
              <Image.Group>
                <Image
                  src={GetImageFromContent(
                    this.comercialImage,
                    "imgMain",
                    this.props.currentlanguage
                  )}
                  style={{ maxHeight: "12rem", maxWidth: "96%", margin: 0 }}
                />
                ;
              </Image.Group>
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: "6rem" }}>
              <Image
                src={GetImageFromContent(
                  this.comercialImage,
                  "imgBody",
                  this.props.currentlanguage
                )}
              />
            </Grid.Row>
            <Grid.Row>
              <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                {consorcioara.ingresos[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row>
              <Image
                centered
                style={{ margin: 0 }}
                src={GetImageFromContent(
                  this.footerContent,
                  "img",
                  this.props.currentlanguage
                )}
              />
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.portafolio[this.props.currentlanguage]}
          </h1>
        </Container>
        {this.array.map((item, index) => {
          return (
            <Grid stackable style={{ paddingBottom: "4rem" }}>
              <h1 style={{ fontSize: "2rem", paddingBottom: "1rem" }}>
                {consorcioara[getIndexText(index)][this.props.currentlanguage]}
              </h1>
              <Grid.Row
                style={{ backgroundColor: "#edf4fa", marginBottom: "1rem" }}
              >
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        item,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left center",
                    width: "100%",
                    height: "7rem",
                    padding: "4rem 0rem"
                  }}
                />
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column width={6} style={{ paddingBottom: "0rem" }}>
                  <Comptitle
                    simple
                    body={GetTextFromContent(
                      item,
                      "priceRange",
                      this.props.currentlanguage
                    )}
                    bodycolor="#00457b"
                    titlesize="1.5rem"
                    title={
                      consorcioara.rangoPrecios[this.props.currentlanguage]
                    }
                    bodysize="1.2rem"
                  />

                  <Comptitle
                    simple
                    body={GetTextFromContent(
                      item,
                      "financing",
                      this.props.currentlanguage
                    )}
                    bodycolor="#00457b"
                    titlesize="1.5rem"
                    title={
                      consorcioara.financiamiento[this.props.currentlanguage]
                    }
                    bodysize="1.2rem"
                  />

                  <Comptitle
                    simple
                    body={GetTextFromContent(
                      item,
                      "monthly",
                      this.props.currentlanguage
                    )}
                    bodycolor="#00457b"
                    titlesize="1.5rem"
                    title={
                      consorcioara.ingresoMensual[this.props.currentlanguage]
                    }
                    bodysize="1.2rem"
                  />
                  <Comptitle
                    simple
                    body={GetTextFromContent(
                      item,
                      "umam",
                      this.props.currentlanguage
                    )}
                    bodycolor="#00457b"
                    titlesize="1.5rem"
                    title="UMAM***:"
                    bodysize="1.2rem"
                  />

                  <Comptitle
                    simple
                    body={GetTextFromContent(
                      item,
                      "socioeconomic",
                      this.props.currentlanguage
                    )}
                    bodycolor="#00457b"
                    titlesize="1.5rem"
                    title={
                      consorcioara.socioeconomico[this.props.currentlanguage]
                    }
                    bodysize="1.2rem"
                  />
                </Grid.Column>
                <Grid.Column width={5}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: GetRichTextFromContent(
                        item,
                        "noteCall",
                        this.props.currentlanguage
                      )
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        })}
        <div style={{}}>
          <Grid stackable style={{ paddingBottom: "3rem" }}>
            <Grid.Row>
              <h1
                style={{
                  fontSize: "2rem",
                  paddingBottom: "1rem",
                  paddingLeft: "1rem"
                }}
              >
                {consorcioara.comerciales[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row
              style={{ backgroundColor: "#edf4fa", marginBottom: "2rem" }}
            >
              <div
                style={{
                  backgroundImage:
                    "url(" +
                    GetImageFromContent(
                      this.comercialImage,
                      "imgMain",
                      this.props.currentlanguage
                    ) +
                    ")",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left center",
                  width: "100%",
                  height: "7rem",
                  padding: "4rem 0rem"
                }}
              />
            </Grid.Row>
            <Grid.Row style={{ paddingBottom: "6rem" }}>
              <Image
                src={GetImageFromContent(
                  this.comercialImage,
                  "imgBody",
                  this.props.currentlanguage
                )}
              />
            </Grid.Row>
            <Grid.Row>
              <h1
                style={{
                  fontSize: "2rem",
                  paddingBottom: "1rem",
                  paddingLeft: "1rem"
                }}
              >
                {consorcioara.ingresos[this.props.currentlanguage]}
              </h1>
            </Grid.Row>
            <Grid.Row>
              <div
                style={{ height: "13rem", width: "100%", paddingTop: "1rem" }}
              >
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        this.footerContent,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "100%"
                  }}
                />
              </div>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(PortafolioDeProductos)
);
