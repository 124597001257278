import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import Slider from "react-slick";
import { Icon, Responsive } from "semantic-ui-react";
import API from "../API/API";
import {
  GetImageFromContent,
  GetSlider,
  GetTextFromContent,
  UpdateArrayImage
} from "../scripts";

class SliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.dickref = React.createRef();
    this.slider = [];
    this.verifyPages = this.verifyPages.bind(this);
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = pages[0].id;

      api.getContentOf(pageId).then(response => {
        if (this.slider.length === 0) {
          this.slider = GetSlider(response[0], "principal", "homeSlider");

          this.forceUpdate();
        }

        api.getImageOf(pageId).then(responseImages => {
          this.slider = UpdateArrayImage(
            responseImages[0],
            "principal",
            this.slider
          );

          this.forceUpdate();
        });
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      arrows: false
    };
    return (
      <div align="center" style={{ width: "100%" }}>
        <div
          style={{
            zIndex: 6,
            top: "108rem",
            left: "10.3vw",
            position: "absolute",
            display: "flex",
            height: "7rem",
            width: "3rem",
            backgroundColor: "#FFFFFF",
            justifyContent: "center"
          }}
          onClick={() => {
            this.dickref.current.slickPrev();
          }}
        >
          <Icon
            name="chevron left"
            size="large"
            style={{ color: "#002A6B", alignSelf: "center" }}
            onClick={() => {
              this.dickref.current.slickPrev();
            }}
          />
        </div>
        <div
          style={{
            zIndex: 5,
            top: "108rem",
            left: "85.66vw",
            position: "absolute",
            display: "flex",
            height: "7rem",
            width: "3rem",
            backgroundColor: "#FFFFFF",
            justifyContent: "center"
          }}
          onClick={() => {
            this.dickref.current.slickNext();
          }}
        >
          <Icon
            name="chevron right"
            size="large"
            style={{ color: "#002A6B", alignSelf: "center" }}
            onClick={() => {
              this.dickref.current.slickNext();
            }}
          />
        </div>
        <Slider ref={this.dickref} {...settings} style={{}}>
          {this.slider.map((data, index) => {
            return (
              <div style={{ width: "100%", height: "20rem" }} key={index}>
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        data,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                >
                  <div
                    style={{
                      background: this.props.bgtextcolor,
                      color: "black",
                      width: "100%",
                      padding: 10,
                      textAlign: this.props.centered
                    }}
                  >
                    <p
                      style={{
                        color: this.props.desccolor,
                        fontSize: this.props.bodysize,
                        padding: "1.5rem"
                      }}
                    >
                      {GetTextFromContent(
                        data,
                        "title",
                        this.props.currentlanguage
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  renderInTablet() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    return (
      <div align="center" style={{ paddingTop: "3rem" }}>
        <Slider ref={this.dickref} {...settings}>
          {this.slider.map((data, index) => {
            return (
              <div style={{ width: "100%", height: "20rem" }} key={index}>
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        data,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                >
                  <div
                    style={{
                      background: this.props.bgtextcolor,
                      color: "black",
                      width: "100%",
                      padding: 10,
                      textAlign: this.props.centered
                    }}
                  >
                    <p
                      style={{
                        color: this.props.desccolor,
                        fontSize: this.props.bodysize,
                        padding: "1.5rem"
                      }}
                    >
                      {GetTextFromContent(
                        data,
                        "title",
                        this.props.currentlanguage
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  renderInMobile() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 6000,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    return (
      <div align="center" style={{}}>
        <Slider ref={this.dickref} {...settings}>
          {this.slider.map((data, index) => {
            return (
              <div style={{ width: "100%", height: "20rem" }} key={index}>
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        data,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "500px",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                >
                  <div
                    style={{
                      background: this.props.bgtextcolor,
                      color: "black",
                      width: "100%",
                      padding: 10,
                      textAlign: this.props.centered
                    }}
                  >
                    <p
                      style={{
                        color: this.props.desccolor,
                        fontSize: "1rem",
                        padding: "1rem"
                      }}
                    >
                      {GetTextFromContent(
                        data,
                        "title",
                        this.props.currentlanguage
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SliderComponent)
);
