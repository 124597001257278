import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Container, Responsive } from "semantic-ui-react";
import API from "../API/API";
import { headertitle } from "../assets/Language";
import HeaderComp from "../components/HeaderComp";
import { GetContent, GetPage, GetRichTextFromContent } from "../scripts";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "legal").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "privacy", "privacy");

        this.forceUpdate();
      });
    }
    this.forceUpdate();
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div>
        <HeaderComp bgimages="privacy" />
        <Container style={{ padding: "5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {headertitle.legal[this.props.currentlanguage]}
          </h1>
          <div style={{ paddingTop: "2rem" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: GetRichTextFromContent(
                  this.sectionContent,
                  "desc",
                  this.props.currentlanguage
                )
              }}
            />
          </div>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div>
        <HeaderComp bgimages="privacy" />
        <Container style={{ padding: "5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {headertitle.legal[this.props.currentlanguage]}
          </h1>
          <div style={{ paddingTop: "2rem" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: GetRichTextFromContent(
                  this.sectionContent,
                  "desc",
                  this.props.currentlanguage
                )
              }}
            />
          </div>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div>
        <HeaderComp bgimages="privacy" />
        <Container style={{ padding: "2.5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {headertitle.legal[this.props.currentlanguage]}
          </h1>
          <div style={{}}>
            <div
              dangerouslySetInnerHTML={{
                __html: GetRichTextFromContent(
                  this.sectionContent,
                  "desc",
                  this.props.currentlanguage
                )
              }}
            />
          </div>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Privacy)
);
