import RestClient from "./RestClient";
import { finalIP } from "../scripts";

export default class API extends RestClient {
  constructor(authToken) {
    super(finalIP + "/api", {
      headers: {
        // Include as many custom headers as you need
        Authorization: authToken
      }
    });
    this.authToken = authToken;
    this.idOrganizationGod = "";
  }
  getToken() {
    return this.authToken;
  }
  getPages(nameOrg) {
    const url = "/Organizations/getPages";
    return this.GETPASSVALUE(url, { nameOrg: nameOrg });
  }

  getContentOf(idPage) {
    const url = "/Organizations/getContentOf";
    return this.GETPASSVALUE(url, { idPage: idPage });
  }

  getFilesOf(idPage) {
    const url = "/Organizations/getFilesOf";
    return this.GETPASSVALUE(url, { idPage: idPage });
  }

  getImageOf(idPage) {
    const url = "/Organizations/getImageOf";
    return this.GETPASSVALUE(url, { idPage: idPage });
  }

  sendMail(email, body, subject) {
    const url = "/Organizations/sendEmail";
    return this.POST(url, { email, body, subject });
  }
}
