import React, { Component } from "react";
import { Provider } from "react-redux";
import {
  ConnectedRouter,
  routerMiddleware,
  routerReducer
} from "react-router-redux";
import { applyMiddleware, createStore, combineReducers } from "redux";
import Navigator from "./Navigator";
import Reducers from "./redux/reducers";
import createHistory from "history/createBrowserHistory";
import GoogleTagManager from "./components/GoogleTagManager";
import GoogleGClid from "./components/GoogleGClid";

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.history = createHistory();
    const middleware = routerMiddleware(this.history);

    this.store = createStore(
      combineReducers({
        ...Reducers,
        router: routerReducer
      }),
      applyMiddleware(middleware)
    );

    return (
      <GoogleGClid>
        <Provider store={this.store}>
          <ConnectedRouter history={this.history}>
            <Navigator store={this.store} />
          </ConnectedRouter>
        </Provider>
      </GoogleGClid>
    );
  }
}

export default App;
