import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  Button,
  Container,
  Grid,
  Image,
  Responsive,
  Segment
} from "semantic-ui-react";
import API from "../../API/API";
import {
  arabg,
  icongrid1,
  icongrid2,
  icongrid3,
  icongrid4
} from "../../assets/Assets";
import { hometxt } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import {
  GetContent,
  GetLinkFromContent,
  GetTextFromContent
} from "../../scripts";

class Home2 extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = pages[0].id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "principal", "info");

        

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{}}>
        <div
          style={{
            backgroundImage: "url(" + arabg + ")",
            backgroundPosition: "left top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            zIndex: "0",
            height: "24rem",
            width: "70%",
            top: "85rem",
            left: 0,
            position: "absolute"
          }}
        />
        <div
          style={{
            backgroundColor: "#EDF4FA",
            height: "42rem",
            width: "100%",
            position: "absolute",
            zIndex: -2
          }}
          id="absolutebg"
        />
        <Container style={{ padding: "7rem 0rem", zIndex: 2 }}>
          <Grid columns={2}>
            <Grid.Column width={10}>
              <Comptitle
                simple
                title={GetTextFromContent(
                  this.sectionContent,
                  "title",
                  this.props.currentlanguage
                )}
                body={GetTextFromContent(
                  this.sectionContent,
                  "body",
                  this.props.currentlanguage
                )}
                titlecolor="#003F75"
                titlebold
                titlesize="2.3rem"
                bodysize="1.6rem"
                bodyWidth="90%"
                style={{ paddingBottom: "2rem" }}
              />
              <Button
                style={{
                  backgroundColor: "#013F75",
                  color: "white",
                  borderRadius: "20px",
                  fontSize: "1rem",
                  width: "14rem",
                  height: "3rem"
                }}
                className="DButton"
                content={hometxt.home2button[this.props.currentlanguage]}
                href={GetLinkFromContent(
                  this.sectionContent,
                  "goTo",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column width={6} style={{ padding: "0rem 1rem" }}>
              <Segment>
                <Grid columns={2} centered>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid1}
                    />
                    <br />
                    <label>
                      {hometxt.segment1[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/bursatil/cotizacion");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid2}
                    />

                    <br />
                    <label>
                      {hometxt.segment2[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid3}
                    />

                    <br />
                    <label>
                      {hometxt.segment3[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/corporativo/consejo-comites");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid4}
                    />

                    <br />
                    <label>
                      {hometxt.segment4[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{}}>
        <div
          style={{
            backgroundImage: "url(" + arabg + ")",
            backgroundPosition: "left top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            zIndex: "0",
            height: "24rem",
            width: "70%",
            top: "85rem",
            left: 0,
            position: "absolute"
          }}
        />
        <div
          style={{
            backgroundColor: "#EDF4FA",
            height: "42rem",
            width: "100%",
            position: "absolute",
            zIndex: -2
          }}
          id="absolutebg"
        />
        <Container style={{ padding: "5rem 0rem 4rem", zIndex: 2 }}>
          <Grid columns={2}>
            <Grid.Column width={8} style={{ padding: "0rem 0rem 0rem 1rem" }}>
              <Comptitle
                simple
                title={GetTextFromContent(
                  this.sectionContent,
                  "title",
                  this.props.currentlanguage
                )}
                body={GetTextFromContent(
                  this.sectionContent,
                  "body",
                  this.props.currentlanguage
                )}
                titlecolor="#003F75"
                titlebold
                titlesize="2.3rem"
                bodysize="1.4rem"
                bodyWidth="90%"
                style={{ paddingBottom: "2rem" }}
              />
              <Button
                style={{
                  backgroundColor: "#013F75",
                  color: "white",
                  borderRadius: "20px",
                  fontSize: "1rem",
                  width: "14rem",
                  height: "3rem"
                }}
                content={hometxt.home2button[this.props.currentlanguage]}
                href={GetLinkFromContent(
                  this.sectionContent,
                  "goTo",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ padding: "0rem" }}>
              <Segment>
                <Grid columns={2} centered>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid1}
                    />
                    <br />
                    <label>
                      {hometxt.segment1[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/bursatil/cotizacion");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid2}
                    />

                    <br />
                    <label>
                      {hometxt.segment2[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid3}
                    />

                    <br />
                    <label>
                      {hometxt.segment3[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/corporativo/consejo-comites");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid4}
                    />

                    <br />
                    <label>
                      {hometxt.segment4[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{}}>
        <div
          style={{
            backgroundColor: "#EDF4FA",
            height: "100%",
            width: "100%",
            position: "absolute",
            zIndex: -2
          }}
        />
        <Container style={{ padding: "2.5rem 0rem" }}>
          <Grid stackable columns={2}>
            <Grid.Column width={10}>
              <Comptitle
                simple
                title={GetTextFromContent(
                  this.sectionContent,
                  "title",
                  this.props.currentlanguage
                )}
                body={GetTextFromContent(
                  this.sectionContent,
                  "body",
                  this.props.currentlanguage
                )}
                titlecolor="#003F75"
                titlebold
                titlesize="2.5rem"
                bodysize="1.3rem"
                bodyWidth="90%"
                style={{ paddingBottom: "1rem" }}
              />
              <Button
                style={{
                  backgroundColor: "#013F75",
                  color: "white",
                  borderRadius: "20px",
                  fontSize: "1rem",
                  width: "14rem",
                  height: "3rem"
                }}
                content={hometxt.home2button[this.props.currentlanguage]}
                href={GetLinkFromContent(
                  this.sectionContent,
                  "goTo",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column width={6} style={{ padding: "0rem 1rem" }}>
              <Segment>
                <Grid columns={2} centered>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid1}
                    />
                    <br />
                    <label>
                      {hometxt.segment1[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/bursatil/cotizacion");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid2}
                    />

                    <br />
                    <label>
                      {hometxt.segment2[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderRight: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/financieros/reportes-trimestrales");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid3}
                    />

                    <br />
                    <label>
                      {hometxt.segment3[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{
                      display: "flex",
                      borderTop: "2px solid #F0F5FB",
                      borderLeft: "1px solid #F0F5FB",
                      padding: "3rem",
                      height: "13rem",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      this.props.push("/corporativo/consejo-comites");
                      window.scroll({
                        top: document.getElementById("nav").offsetTop,
                        behavior: "auto"
                      });
                    }}
                  >
                    <Image
                      size="mini"
                      style={{ width: "3.5rem" }}
                      centered
                      src={icongrid4}
                    />

                    <br />
                    <label>
                      {hometxt.segment4[this.props.currentlanguage]}
                    </label>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home2)
);
