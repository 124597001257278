import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { hometxt } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import { GetContent, GetImageFromContent } from "../../scripts";

class Home5 extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = pages[0].id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "figures", "main");

        

        this.forceUpdate();
      });

      api.getImageOf(pageId).then(responseImage => {
        this.sectionImages = GetContent(responseImage[0], "figures", "main");
        // 
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container centered textAlign="center" style={{}}>
          <Comptitle
            simple
            title={hometxt.home3title[this.props.currentlanguage]}
            body={hometxt.home3subtitle[this.props.currentlanguage]}
            bodycolor="#003e75"
            bodysize="1rem"
            style={{ paddingBottom: "4rem" }}
            titlebold
            titlecolor="#003D7A"
          />
          <Grid columns={3}>
            <Grid.Column style={{ padding: "3rem" }}>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img0",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column style={{ padding: "3rem" }}>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img1",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column style={{ padding: "3rem" }}>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img2",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container centered textAlign="center" style={{}}>
          <Comptitle
            simple
            title={hometxt.home3title[this.props.currentlanguage]}
            body={hometxt.home3subtitle[this.props.currentlanguage]}
            style={{ paddingBottom: "4rem" }}
            titlebold
            titlecolor="#003D7A"
          />
          <Grid columns={3}>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img0",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img1",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img2",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container centered textAlign="center" style={{}}>
          <Comptitle
            simple
            title={hometxt.home3title[this.props.currentlanguage]}
            body={hometxt.home3subtitle[this.props.currentlanguage]}
            style={{ paddingBottom: "4rem" }}
            titlebold
            titlecolor="#003D7A"
          />
          <Grid stackable columns={3}>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img0",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img1",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <Image
                centered
                src={GetImageFromContent(
                  this.sectionImages,
                  "img2",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Home5)
);
