import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Menu, Responsive } from "semantic-ui-react";

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.currentpath = this.props.location.pathname;
  }
  state = {
    activeItem: ""
  };

  componentDidMount() {
    this.setState({ activeItem: this.props.location.pathname });
  }

  componentWillReceiveProps(nextProps) {
    
    this.currentpath = nextProps.location.pathname;
    this.setState({ activeItem: nextProps.location.pathname });
  }

  renderInDesktop() {
    return (
      <div style={{ backgroundColor: "#00457C" }}>
        <Menu
          className="submenu"
          pointing
          secondary
          fluid
          style={{
            padding: "1rem 0rem",
            justifyContent: "center"
          }}
        >
          {this.props.submenu.map(item => {
            return (
              <Menu.Item
                fitted
                onClick={() => {
                  this.props.push(item.pushto);
                }}
                name={item.name}
                active={this.state.activeItem === item.pushto}
                style={{
                  color: "white",
                  textTransform: "uppercase"
                }}
              >
                {item.name}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    );
  }
  renderInTablet() {
    return <div style={{ backgroundColor: "transparent" }} />;
  }
  renderInMobile() {
    return <div style={{ backgroundColor: "#00457C" }} />;
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage, categories } = data;
  return {
    pages,
    currentlanguage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SubMenu)
);
