import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import {
  Container,
  Dropdown,
  Grid,
  Icon,
  Image,
  Menu,
  Modal,
  Responsive,
} from 'semantic-ui-react';
import API from '../API/API';
import { certification, email, logo, search } from '../assets/Assets';
import { contactmodal } from '../assets/Language';
import { setLanguage } from '../redux/actions';
import { GetContent, GetPage, GetTextFromContent } from '../scripts';
import Comptitle from './Comptitle';
import FormComp from './FormComp';

const language = [
  { key: 'esp', text: 'Esp', value: '_es' },
  { key: 'eng', text: 'Eng', value: '_en' },
];

class MenuComp extends React.Component {
  state = {
    mobileMenuVisible: false,
    activeItem: 'name',
    language: this.props.currentlanguage,
    open: true,
    openModal: false,
  };
  handleItemClick = (e, { name }) =>
    this.setState({ activeItem: name, open: false });

  externalLink = () => {
    window.location.href =
      'https://tulineaetica.kpmg.com.mx/LineaEtica/consorcioAra';
  };

  constructor(props) {
    super(props);
    this.options = [];
    this.modal = {};
  }

  handleItemClick = (name, navigateto) => {
    this.setState({ activeItem: name });
    this.props.push(navigateto);
  };

  handleOpen = () => this.setState({ openModal: true });

  handleClose = () =>
    this.setState({
      openModal: false,
    });

  toggleLanguage = (language) => {
    if (language === '_es') {
      const spanish = '_es';
      sessionStorage.setItem('currentlanguage', spanish);
      this.props.setLanguage(sessionStorage.getItem('currentlanguage'));
    } else {
      const english = '_en';
      sessionStorage.setItem('currentlanguage', english);
      this.props.setLanguage(sessionStorage.getItem('currentlanguage'));
    }
  };

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'home').id;

      api.getContentOf(pageId).then((response) => {
        this.modal = GetContent(response[0], 'modalcontent', 'info');

        this.forceUpdate();
      });

      // this.props.api.getImageOf(pageId).then(responseImages => {
      //   this.sectionImages = GetContent(responseImages[0], "info", "modal");
      //
      // });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  contactModal() {
    return (
      <Modal open={this.state.openModal} onClose={this.handleClose}>
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            left: '62rem',
            top: '1rem',
            cursor: 'pointer',
            zIndex: 11,
          }}
        >
          <Icon
            name="close"
            size="large"
            onClick={() => {
              this.handleClose();
            }}
          />
        </div>
        <Modal.Content style={{ padding: 0 }}>
          <Grid columns={2}>
            <Grid.Column width={7} style={{ padding: '6rem 4rem 2rem' }}>
              <Grid.Row>
                <Comptitle
                  simple
                  title={contactmodal.contacto[this.props.currentlanguage]}
                  body={GetTextFromContent(
                    this.modal,
                    'msg',
                    this.props.currentlanguage
                  )}
                  titlecolor="#003e75"
                  titlesize="3rem"
                  bodysize="1.5rem"
                  marginSubtitle="2rem 0rem 3rem"
                />
              </Grid.Row>
              <Grid.Row>
                <p>
                  {GetTextFromContent(
                    this.modal,
                    'footermsg',
                    this.props.currentlanguage
                  )}
                </p>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              width={9}
              style={{ backgroundColor: '#00437C', padding: '6rem' }}
            >
              <FormComp />
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }

  renderInDesktop() {
    const { activeItem } = this.state;

    return (
      <div
        id="nav"
        style={{
          padding: 20,
          width: '100%',
          zIndex: 100,
          position: 'fixed',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }}
      >
        <Grid>
          <Grid.Column
            width={2}
            verticalAlign="middle"
            style={{ padding: '2rem 1rem' }}
          >
            <Image src={logo} as="a" size="Large" href="/" style={{}} />
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            width={11}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alingItems: 'center',
              alignSelf: 'center',
              marginTop: '0.8rem',
            }}
          >
            <Menu secondary compact>
              {this.props.listitem.map((item) => {
                if (item.suboptions.length > 0) {
                  return (
                    <Menu.Item
                      style={{ marginLeft: 0 }}
                      position="right"
                      color="orange"
                    >
                      <Dropdown
                        key={item.id}
                        text={item.name}
                        icon={false}
                        simple
                        open={false}
                        openOnFocus
                        pointing
                        style={{
                          fontSize: 12,
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          height: '1.5rem',
                        }}
                      >
                        <Dropdown.Menu
                          style={{
                            marginTop: 0,
                            justifyContent: 'center',
                            left:
                              item.id === 1
                                ? '-23%'
                                : '' || item.id === 2
                                ? '-62%'
                                : '' || item.id === 3
                                ? '-94%'
                                : '' || item.id === 5
                                ? '-40%'
                                : '' || item.id === 6
                                ? '-30%'
                                : '',
                          }}
                        >
                          {item.suboptions.map((subitem, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                style={{}}
                                onClick={() => {
                                  subitem.externalLink
                                    ? this.externalLink()
                                    : this.props.push(subitem.navigateto);
                                }}
                              >
                                {subitem.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>
                  );
                } else {
                  return (
                    <Menu.Item
                      position="right"
                      key={item.id}
                      name={item.name}
                      active={activeItem === item.name}
                      onClick={(e, { name }) => {
                        //AKITA
                        this.handleItemClick(name, item.navigateto);
                      }}
                      style={{
                        fontSize: 12,
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        height: '2.5rem',
                        marginBottom: '0.5rem',
                      }}
                    />
                  );
                }
              })}
            </Menu>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle">
            <Grid>
              <Grid.Column
                verticalAlign="middle"
                style={{ padding: '1rem 0rem' }}
                align="center"
                width={5}
              >
                <Dropdown
                  placeholder="Lan"
                  selection
                  options={language}
                  fluid
                  style={{
                    borderRadius: '20px',
                    width: '5rem',
                    height: '2rem',
                  }}
                  value={this.props.currentlanguage}
                  onChange={(event, data) => {
                    this.toggleLanguage(data.value);
                  }}
                />
              </Grid.Column>
              <Grid.Column
                align="center"
                verticalAlign="middle"
                style={{ padding: '1rem 0rem' }}
                width={4}
              >
                <Image
                  style={{ cursor: 'pointer' }}
                  src={email}
                  onClick={() => {
                    this.handleOpen();
                  }}
                />
              </Grid.Column>
              <Grid.Column
                align="center"
                verticalAlign="middle"
                style={{ padding: '1rem 0rem' }}
                width={4}
                onClick={() => {
                  this.props.push('/busqueda');
                }}
              >
                <Image style={{ cursor: 'pointer' }} src={search} />
              </Grid.Column>
              <Grid.Column
                verticalAlign="middle"
                style={{ padding: 0 }}
                width={3}
              >
                <Image
                  src={certification}
                  style={{ position: 'absolute', top: '-3rem', width: '4rem' }}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        {this.contactModal()}
      </div>
    );
  }

  renderInLargeScreen() {
    const { activeItem } = this.state;

    return (
      <div
        id="nav"
        style={{
          padding: 20,
          width: '100%',
          zIndex: 100,
        }}
      >
        <Grid>
          <Grid.Column
            verticalAlign="middle"
            width={14}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alingItems: 'center',
              alignSelf: 'center',
              marginTop: '0.8rem',
            }}
          >
            <Menu secondary compact>
              {this.props.listitem.map((item) => {
                if (item.suboptions.length > 0) {
                  return (
                    <Menu.Item
                      style={{ marginLeft: 0 }}
                      position="right"
                      color="orange"
                    >
                      <Dropdown
                        key={item.id}
                        text={item.name}
                        icon={false}
                        simple
                        open={false}
                        openOnFocus
                        pointing
                        style={{
                          fontSize: 12,
                          color: 'white',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          height: '1.5rem',
                        }}
                      >
                        <Dropdown.Menu
                          style={{
                            marginTop: 0,
                            justifyContent: 'center',
                            left:
                              item.id === 1
                                ? '-23%'
                                : '' || item.id === 2
                                ? '-62%'
                                : '' || item.id === 3
                                ? '-94%'
                                : '' || item.id === 5
                                ? '-40%'
                                : '' || item.id === 6
                                ? '-30%'
                                : '',
                          }}
                        >
                          {item.suboptions.map((subitem, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                style={{}}
                                onClick={() => {
                                  subitem.externalLink
                                    ? this.externalLink()
                                    : this.props.push(subitem.navigateto);
                                }}
                              >
                                {subitem.name}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu.Item>
                  );
                } else {
                  return (
                    <Menu.Item
                      position="right"
                      key={item.id}
                      name={item.name}
                      active={activeItem === item.name}
                      onClick={(e, { name }) => {
                        this.handleItemClick(name, item.navigateto);
                      }}
                      style={{
                        fontSize: 14,
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        height: '2.5rem',
                        marginBottom: '0.5rem',
                      }}
                    />
                  );
                }
              })}
            </Menu>
          </Grid.Column>
          <Grid.Column floated={'right'} width={2} verticalAlign="middle">
            <Grid
              columns={'equal'}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid.Column
                verticalAlign="middle"
                style={{ padding: '1rem 0rem' }}
                align="center"
                width={16}
              >
                <Dropdown
                  placeholder="Lan"
                  selection
                  options={language}
                  fluid
                  style={{
                    borderRadius: '20px',
                    width: '5rem',
                    height: '2rem',
                  }}
                  value={this.props.currentlanguage}
                  onChange={(event, data) => {
                    this.toggleLanguage(data.value);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        {this.contactModal()}
      </div>
    );
  }

  renderInTablet() {
    return (
      <div
        id="nav"
        style={{
          backgroundColor: 'transparent',
          padding: 20,
        }}
      >
        <Container>
          <Grid columns={3}>
            <Grid.Column width={5}>
              <div
              // style={{
              //   height: "100%",
              //   width: "100%",
              //   backgroundColor: "#ff007e",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center"
              // }}
              >
                <Image src={logo} as="a" size="medium" href="/" />
              </div>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={9} align="right">
              <Dropdown
                placeholder="Lan"
                selection
                options={language}
                fluid
                style={{
                  borderRadius: '20px',
                  width: '5rem',
                  height: '2rem',
                }}
                value={this.props.currentlanguage}
                onChange={(event, data) => {
                  this.toggleLanguage(data.value);
                }}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={2} align="right">
              <Icon
                name={this.props.mobileMenuVisible ? 'close' : 'sidebar'}
                onClick={() => {
                  this.props.onClickMobileMenu();
                }}
                size="big"
                style={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  zIndex: 9000,
                  position: 'relative',
                  paddingRight: '1rem',
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }

  renderInMobile() {
    return (
      <div
        id="nav"
        style={{
          backgroundColor: 'transparent',
          paddingTop: '2rem',
        }}
      >
        <Container>
          <Grid columns={3}>
            <Grid.Column
              width={8}
              verticalAlign="middle"
              style={{ padding: 0, paddingLeft: 20 }}
            >
              <div
              // style={{
              //   height: "100%",
              //   width: "40%",
              //   backgroundColor: "#ff007e",
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center"
              // }}
              >
                <Image src={logo} as="a" size="small" href="/" />
                {/* <a
                  as="a"
                  align="center"
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "bold"
                  }}
                  href="/"
                >
                  VIVE SOLAR
                </a> */}
              </div>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={6} align="right">
              <Dropdown
                placeholder="Lan"
                selection
                options={language}
                fluid
                style={{
                  borderRadius: '20px',
                  width: '5rem',
                  height: '2rem',
                }}
                value={this.props.currentlanguage}
                onChange={(event, data) => {
                  this.toggleLanguage(data.value);
                }}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={2} align="right">
              <Icon
                name={this.props.mobileMenuVisible ? 'close' : 'sidebar'}
                onClick={() => {
                  this.props.onClickMobileMenu();
                }}
                size="large"
                style={{
                  backgroundColor: 'transparent',
                  color: 'white',
                  zIndex: 9000,
                  position: 'relative',
                  paddingRight: '1rem',
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive
          minWidth={Responsive.onlyComputer.minWidth}
          maxWidth={Responsive.onlyLargeScreen.minWidth}
        >
          {this.renderInLargeScreen()}
        </Responsive>
        <Responsive minWidth={Responsive.onlyLargeScreen.minWidth}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    push: (to) => {
      dispatch(push(to));
    },
    setLanguage: (currentlanguage) => {
      dispatch(setLanguage(currentlanguage));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuComp)
);
