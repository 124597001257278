import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import ModalComponent from "../layouts/ModalComponent";

class ModalGeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
  }

  render() {
    return (
      <ModalComponent
        width={"90%"}
        onOpen={() => {}}
        closeModal={() => {
          this.props.closeModal();
        }}
        showModal={this.state.showModal}
      >
        <Modal.Header>
          <h1
            style={{
              marginBottom: "0.3rem",
              textAlign: "left",
              color: "#014078"
            }}
          >
            {this.props.title}
          </h1>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.body
              }}
            />
          </Modal.Description>
        </Modal.Content>
      </ModalComponent>
    );
  }
}

export default ModalGeneralInfo;
