import React, { Component } from "react";
import "../style/globalstyle.css";
import SubMenu from "../components/SubMenu";
import { Route, Switch } from "react-router-dom";
import HeaderComp from "../components/HeaderComp";
import Analistas from "../screens/bursatilscreens/Analistas";
import Dividendos from "../screens/bursatilscreens/Dividendos";
import CotizacionDeAccion from "../screens/bursatilscreens/CotizacionDeAccion";
import GraficaInteractiva from "../screens/bursatilscreens/GraficaInteractiva";
import PreciosHistoricos from "../screens/bursatilscreens/PreciosHistoricos";
import ProspectosDeColocacion from "../screens/bursatilscreens/ProspectosDeColocacion";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GetContent, GetImageFromContent, GetPage } from "../scripts";
import API from "../API/API";

const submenu = [
  {
    id: 1,
    name: "Cotización de la acción",
    pushto: "/bursatil/cotizacion",
  },
  {
    id: 2,
    name: "Gráfica interactiva",
    pushto: "/bursatil/grafica-interactiva",
  },
  { id: 3, name: "Precios históricos", pushto: "/bursatil/precios-historicos" },
  { id: 4, name: "Dividendos", pushto: "/bursatil/dividendos" },
  { id: 5, name: "Analistas", pushto: "/bursatil/analistas" },
  {
    id: 6,
    name: "Prospectos de colocación",
    pushto: "/bursatil/prospectos-colocacion",
  },
];

const submenueng = [
  {
    id: 1,
    name: "stock quote",
    pushto: "/bursatil/cotizacion",
  },
  {
    id: 2,
    name: "stock chart",
    pushto: "/bursatil/grafica-interactiva",
  },
  { id: 3, name: "historical prices", pushto: "/bursatil/precios-historicos" },
  { id: 4, name: "dividends", pushto: "/bursatil/dividendos" },
  { id: 5, name: "analyst coverage", pushto: "/bursatil/analistas" },
  {
    id: 6,
    name: "offering prospectuses",
    pushto: "/bursatil/prospectos-colocacion",
  },
];

class BursatilPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "stockInfo").id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], "header", "main");
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="bursatil"
          imageBackground={GetImageFromContent(
            this.headerImage,
            "img",
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === "_es" ? submenu : submenueng}
        />
        <div>
          <Switch>
            <Route
              path="/bursatil/cotizacion"
              exact
              component={CotizacionDeAccion}
            />
            <Route
              path="/bursatil/grafica-interactiva"
              exact
              component={GraficaInteractiva}
            />
            <Route
              path="/bursatil/precios-historicos"
              exact
              component={PreciosHistoricos}
            />
            <Route path="/bursatil/analistas" exact component={Analistas} />
            <Route path="/bursatil/dividendos" exact component={Dividendos} />
            <Route
              path="/bursatil/prospectos-colocacion"
              exact
              component={ProspectosDeColocacion}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(BursatilPage)
);
