import React, { Component } from 'react';
import '../style/globalstyle.css';
import SubMenu from '../components/SubMenu';
import { Route, Switch } from 'react-router-dom';
import HeaderComp from '../components/HeaderComp';
import PresentacionCorporativa from '../screens/financieroscreens/PresentacionCorportativa';
import ReportesAnuales from '../screens/financieroscreens/ReportesAnuales';
import ReportesTrimestrales from '../screens/financieroscreens/ReportesTrimestrales';
import Calificaciones from '../screens/financieroscreens/Calificaciones';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GetContent, GetImageFromContent, GetPage } from '../scripts';
import API from '../API/API';

const submenu = [
  {
    id: 1,
    name: 'Reportes Trimestrales',
    pushto: '/financieros/reportes-trimestrales',
  },
  { id: 2, name: 'Reportes Anuales', pushto: '/financieros/reportes-anuales' },
  {
    id: 3,
    name: 'Presentación Corporativa',
    pushto: '/financieros/presentacion-corporativa',
  },
  {
    id: 4,
    name: 'Calificaciones',
    pushto: '/financieros/calificaciones',
  },
];

const submenueng = [
  {
    id: 1,
    name: 'quarterly reports',
    pushto: '/financieros/reportes-trimestrales',
  },
  { id: 2, name: 'annual reports', pushto: '/financieros/reportes-anuales' },
  {
    id: 3,
    name: 'corporate presentation',
    pushto: '/financieros/presentacion-corporativa',
  },
  {
    id: 4,
    name: 'credit ratings',
    pushto: '/financieros/calificaciones',
  },
];

class FinancierosPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    console.log('Financieros', pages);
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'finacial').id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], 'header', 'main');
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="financieros"
          imageBackground={GetImageFromContent(
            this.headerImage,
            'img',
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === '_es' ? submenu : submenueng}
        />
        <div>
          <Switch>
            <Route
              path="/financieros/presentacion-corporativa"
              exact
              component={PresentacionCorporativa}
            />
            <Route
              path="/financieros/reportes-anuales"
              exact
              component={ReportesAnuales}
            />
            <Route
              path="/financieros/reportes-trimestrales"
              exact
              component={ReportesTrimestrales}
            />
            <Route
              path="/financieros/calificaciones"
              exact
              component={Calificaciones}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(FinancierosPage)
);
