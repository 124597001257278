import React, { Component } from 'react';
// import  from '../screens/screens/';
// import  from '../screens/screens/';
// import  from '../screens/screens/';
import '../style/globalstyle.css';
import SubMenu from '../components/SubMenu';
import { Route, Switch } from 'react-router-dom';
import HeaderComp from '../components/HeaderComp';
import PerfilCorporativo from '../screens/consorcioarascreens/PerfilCorporativo';
import Filosofia from '../screens/consorcioarascreens/Filosofia';
import Historia from '../screens/consorcioarascreens/Historia';
import Fortalezasara from '../screens/consorcioarascreens/Fortalezasara';
import Reconocimientos from '../screens/consorcioarascreens/Reconocimientos';
import PortafolioDeProductos from '../screens/consorcioarascreens/PortafolioDeProductos';
import PresenciaGeografica from '../screens/consorcioarascreens/PresenciaGeografica';
import EstructuraAccionaria from '../screens/consorcioarascreens/EstructuraAccionaria';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { GetContent, GetImageFromContent, GetPage } from '../scripts';
import API from '../API/API';

const submenu = [
  {
    id: 1,
    name: 'Perfil corporativo',
    pushto: '/consorcio/perfil-corporativo',
  },
  { id: 2, name: 'Filosofía', pushto: '/consorcio/filosofia' },
  { id: 3, name: 'Historia', pushto: '/consorcio/historia' },
  { id: 4, name: 'Fortalezas ara', pushto: '/consorcio/fortalezas-ara' },
  { id: 5, name: 'Portafolio de productos', pushto: '/consorcio/portafolio' },
  {
    id: 6,
    name: 'Presencia geográfica',
    pushto: '/consorcio/presencia-geografica',
  },
  {
    id: 7,
    name: 'Estructura accionaria',
    pushto: '/consorcio/estructura-accionaria',
  },
  { id: 8, name: 'Reconocimientos', pushto: '/consorcio/reconocimientos' },
];

const submenueng = [
  {
    id: 1,
    name: 'corporate profile',
    pushto: '/consorcio/perfil-corporativo',
  },
  { id: 2, name: 'philosphy', pushto: '/consorcio/filosofia' },
  { id: 3, name: 'history', pushto: '/consorcio/historia' },
  { id: 4, name: 'ara strengths', pushto: '/consorcio/fortalezas-ara' },
  { id: 5, name: 'product portfolio', pushto: '/consorcio/portafolio' },
  {
    id: 6,
    name: 'geographic footprint',
    pushto: '/consorcio/presencia-geografica',
  },
  {
    id: 7,
    name: 'ownership structure',
    pushto: '/consorcio/estructura-accionaria',
  },
  {
    id: 8,
    name: 'awards and recognitions',
    pushto: '/consorcio/reconocimientos',
  },
];

class ConsorcioAraPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'ara').id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], 'header', 'main');
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="consorcio"
          imageBackground={GetImageFromContent(
            this.headerImage,
            'img',
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === '_es' ? submenu : submenueng}
        />
        <div>
          <Switch>
            <Route
              path="/consorcio/perfil-corporativo"
              exact
              component={PerfilCorporativo}
            />
            <Route path="/consorcio/filosofia" exact component={Filosofia} />
            <Route path="/consorcio/historia" exact component={Historia} />
            <Route
              path="/consorcio/fortalezas-ara"
              exact
              component={Fortalezasara}
            />
            <Route
              path="/consorcio/reconocimientos"
              exact
              component={Reconocimientos}
            />
            <Route
              path="/consorcio/portafolio"
              exact
              component={PortafolioDeProductos}
            />
            <Route
              path="/consorcio/presencia-geografica"
              exact
              component={PresenciaGeografica}
            />
            <Route
              path="/consorcio/estructura-accionaria"
              exact
              component={EstructuraAccionaria}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ConsorcioAraPage)
);
