import React, { Component } from "react";
import "../style/globalstyle.css";
import SubMenu from "../components/SubMenu";
import { Route, Switch } from "react-router-dom";
import HeaderComp from "../components/HeaderComp";
import ConsejoComites from "../screens/corporativoscreens/ConsejoComites";
import EquipoDirectivo from "../screens/corporativoscreens/EquipoDirectivo";
import Estatutos from "../screens/corporativoscreens/Estatutos";
import MejoresPracticas from "../screens/corporativoscreens/MejoresPracticas";
import CodigoEtica from "../screens/corporativoscreens/CodigoEtica";
import AsambleaAccionistas from "../screens/corporativoscreens/AsambleaAccionistas";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GetContent, GetImageFromContent, GetPage } from "../scripts";
import API from "../API/API";

const submenu = [
  {
    id: 1,
    name: "Consejo y comités",
    pushto: "/corporativo/consejo-comites",
  },
  { id: 2, name: "Equipo Directivo", pushto: "/corporativo/equipo-directivo" },
  {
    id: 3,
    name: "Estatutos",
    pushto: "/corporativo/estatutos",
  },
  {
    id: 4,
    name: "Mejores Prácticas",
    pushto: "/corporativo/mejores-practicas",
  },
  {
    id: 5,
    name: "Código de Ética",
    pushto: "/corporativo/codigo-etica",
  },
  {
    id: 6,
    name: "Asamblea de Accionistas",
    pushto: "/corporativo/asamblea-accionistas",
  },
];

const submenueng = [
  {
    id: 1,
    name: "board and committees",
    pushto: "/corporativo/consejo-comites",
  },
  {
    id: 2,
    name: "corporate directors",
    pushto: "/corporativo/equipo-directivo",
  },
  {
    id: 3,
    name: "bylaws",
    pushto: "/corporativo/estatutos",
  },
  {
    id: 4,
    name: "best practices",
    pushto: "/corporativo/mejores-practicas",
  },
  {
    id: 5,
    name: "code of ethics",
    pushto: "/corporativo/codigo-etica",
  },
  {
    id: 6,
    name: "shareholders' meeting",
    pushto: "/corporativo/asamblea-accionistas",
  },
];

class CorporativoPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], "header", "main");
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="corporativo"
          imageBackground={GetImageFromContent(
            this.headerImage,
            "img",
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === "_es" ? submenu : submenueng}
        />
        <div>
          <Switch>
            <Route
              path="/corporativo/consejo-comites"
              exact
              component={ConsejoComites}
            />
            <Route
              path="/corporativo/equipo-directivo"
              exact
              component={EquipoDirectivo}
            />
            <Route path="/corporativo/estatutos" exact component={Estatutos} />
            <Route
              path="/corporativo/mejores-practicas"
              exact
              component={MejoresPracticas}
            />
            <Route
              path="/corporativo/codigo-etica"
              exact
              component={CodigoEtica}
            />
            <Route
              path="/corporativo/asamblea-accionistas"
              exact
              component={AsambleaAccionistas}
            />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(CorporativoPage)
);
