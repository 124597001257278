import React, { Component } from "react";
import API from "../API/API";
import HeaderComp from "../components/HeaderComp";
import Eventos from "../screens/eventoscreens/Eventos";
import { GetContent, GetImageFromContent, GetPage } from "../scripts";
import "../style/globalstyle.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
class EventosPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "events").id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], "header", "main");
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="eventos"
          imageBackground={GetImageFromContent(
            this.headerImage,
            "img",
            this.props.currentlanguage
          )}
        />
        <Eventos />
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(EventosPage)
);
