import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container, Responsive } from 'semantic-ui-react';
import API from '../API/API';
import {
  bannerbursatil,
  bannerbuzon,
  bannerconsorcio,
  bannercorporativo,
  bannereventos,
  bannerfinanciero,
  bannersustentabilidad,
} from '../assets/Assets';
import { headertitle } from '../assets/Language';
import { GetContent } from '../scripts';
import Comptitle from './Comptitle';

class HeaderComp extends React.Component {
  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageIdIndustrial = pages[2].id;
      const pageIdPyme = pages[3].id;
      const pageIdResidential = pages[4].id;
      const pageIdBlog = pages[5].id;
      const pageIdContact = pages[6].id;

      api.getContentOf(pageIdIndustrial).then((response) => {
        this.industrialContent = GetContent(response[0], 'header', 'header');

        api.getImageOf(pageIdIndustrial).then((responseImages) => {
          this.industrialImages = GetContent(
            responseImages[0],
            'header',
            'header'
          );

          this.forceUpdate();
        });
      });
      api.getContentOf(pageIdPyme).then((response) => {
        this.pymeContent = GetContent(response[0], 'header', 'header');

        api.getImageOf(pageIdPyme).then((responseImages) => {
          this.pymeImages = GetContent(responseImages[0], 'header', 'header');

          this.forceUpdate();
        });
      });
      api.getContentOf(pageIdResidential).then((response) => {
        this.residentialContent = GetContent(response[0], 'header', 'header');

        api.getImageOf(pageIdResidential).then((responseImages) => {
          this.residentialImages = GetContent(
            responseImages[0],
            'header',
            'header'
          );

          this.forceUpdate();
        });
      });
      api.getContentOf(pageIdBlog).then((response) => {
        this.blogContent = GetContent(response[0], 'header', 'header');

        api.getImageOf(pageIdBlog).then((responseImages) => {
          this.blogImages = GetContent(responseImages[0], 'header', 'header');

          this.forceUpdate();
        });
      });

      api.getContentOf(pageIdContact).then((response) => {
        this.contactContent = GetContent(response[0], 'header', 'header');

        api.getImageOf(pageIdContact).then((responseImages) => {
          this.contactImages = GetContent(
            responseImages[0],
            'header',
            'header'
          );

          this.forceUpdate();
        });
      });

      this.forceUpdate();
    }
  }

  bgimage() {
    switch (this.props.bgimages) {
      case 'consorcio':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerconsorcio,
          title: headertitle.consorcioheader[this.props.currentlanguage],
        };

      case 'financieros':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerfinanciero,
          title: headertitle.financierosheader[this.props.currentlanguage],
        };

      case 'bursatil':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerbursatil,
          title: headertitle.bursatilheader[this.props.currentlanguage],
        };

      case 'corporativo':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannercorporativo,
          title: headertitle.corporativoheader[this.props.currentlanguage],
        };

      case 'sustentabilidad':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannersustentabilidad,
          title: headertitle.sustentabilidadheader[this.props.currentlanguage],
        };

      case 'eventos':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannereventos,
          title: headertitle.eventosheader[this.props.currentlanguage],
        };

      case 'etica':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannereventos,
          title: headertitle.lineaEtica[this.props.currentlanguage],
        };

      case 'buzon':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerbuzon,
          title: headertitle.buzon[this.props.currentlanguage],
        };

      case 'privacy':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerbuzon,
          title: headertitle.legal[this.props.currentlanguage],
        };

      case 'responsible':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerbuzon,
          title: headertitle.responsable[this.props.currentlanguage],
        };

      case 'search':
        return {
          img:
            this.props.imageBackground !== undefined
              ? this.props.imageBackground
              : bannerbuzon,
          title: headertitle.search[this.props.currentlanguage],
        };

      default:
        return '';
    }
  }

  renderInDesktop() {
    return (
      <Container>
        <div
          style={{
            backgroundImage: 'url(' + this.bgimage().img + ')',
            backgroundColor: '#868686',
            height: '31rem',
          }}
          className="workimage"
        />
        <Container
          id="asdhasd"
          style={{
            height: '30rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                marginTop: '20vh',
                width: '60%',
                padding: '3rem',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <Comptitle
                simple
                title={this.bgimage().title}
                bold
                titlesize="3rem"
                titlebold
                bodysize="2rem"
                marginSubtitle="0px"
                marginTitle="5px"
              />
            </div>
          </div>
        </Container>
      </Container>
    );
  }

  renderInTablet() {
    return (
      <Container>
        <div
          style={{
            backgroundImage: 'url(' + this.bgimage().img + ')',
            backgroundColor: '#868686',
            height: '30rem',
          }}
          className="workimage"
        />
        <Container
          id="asdhasd"
          style={{
            height: '24.4rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                width: '60%',
                padding: '3rem',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <Comptitle
                simple
                title={this.bgimage().title}
                bold
                titlesize="3rem"
                titlebold
                bodysize="2rem"
                marginSubtitle="0px"
                marginTitle="5px"
              />
            </div>
          </div>
        </Container>
      </Container>
    );
  }

  renderInMobile() {
    return (
      <Container>
        <div
          style={{
            backgroundImage: 'url(' + this.bgimage().img + ')',
            backgroundColor: '#868686',
            height: '30rem',
          }}
          className="workimage"
        />
        <div
          id="asdhasd"
          className="header"
          style={{
            height: '25.3rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                width: '80%',
                textAlign: 'center',
                color: 'white',
              }}
            >
              <Comptitle
                simple
                title={this.bgimage().title}
                bold
                titlesize="3rem"
                titlebold
                bodysize="2rem"
                marginSubtitle="0px"
              />
            </div>
          </div>
        </div>
      </Container>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(HeaderComp)
);
