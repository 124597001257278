import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import {
  Button,
  Container,
  Form,
  Responsive,
  TextArea
} from "semantic-ui-react";
import API from "../API/API";
import { contactmodal, headertitle } from "../assets/Language";
import HeaderComp from "../components/HeaderComp";
import {
  GetContent,
  GetPage,
  GetRichTextFromContent,
  GetTextFromContent
} from "../scripts";

class BuzonTransparencia extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.footerContent = {};
  }

  state = {
    name: "",
    email: "",
    submittedEmail: "",
    comment: "",
    Tel: ""
  };

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "legal").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "helpline", "info");

        this.forceUpdate();
      });
    }
    this.forceUpdate();
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleSubmit = () => {
    const { name, email, comment, Tel } = this.state;
    const mensaje =
      "<p>-------------------------Correo del buzón de transparencia-----------------------------</p>" +
      "<p>Nombre del contacto:</p>" +
      name +
      "<p></p>" +
      "<p>Correo del contacto:</p>" +
      email +
      "<p></p>" +
      "<p>Teléfono: </p>" +
      Tel +
      "<p>Mensaje: </p>" +
      comment +
      "<p></p>";

    const correo =
      GetTextFromContent(this.sectionContent, "mail") ||
      "dzamarripa@guaostudio.com";

    this.dickapi = new API();
    this.dickapi
      .sendMail(correo, mensaje, "Buzon de transparencia")
      .then(response => {});
    this.setState({ submittedEmail: email });
    this.setState({ email: "", name: "", comment: "", Tel: "", subject: [] });
    setTimeout(() => {
      this.setState({ submittedEmail: "" });
    }, 3000);
  };

  renderInDesktop() {
    const { name, email, comment, Tel } = this.state;
    return (
      <div>
        <HeaderComp bgimages="buzon" />
        <Container style={{ padding: "5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {headertitle.buzon[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                "info",
                this.props.currentlanguage
              )
            }}
          />
          <div style={{ padding: "2rem 0rem" }}>
            <Form onSubmit={this.handleSubmit} style={{ padding: 0 }}>
              <Form.Input
                fluid
                label={contactmodal.nombre[this.props.currentlanguage]}
                placeholder={contactmodal.nombre[this.props.currentlanguage]}
                required
                name="name"
                value={name}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",

                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.email[this.props.currentlanguage]}
                placeholder={contactmodal.email[this.props.currentlanguage]}
                required
                name="email"
                value={email}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.telefono[this.props.currentlanguage]}
                placeholder={contactmodal.telefono[this.props.currentlanguage]}
                name="Tel"
                value={Tel}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                style={{
                  height: "8rem",
                  paddingBottom: "1rem",
                  boxShadow: "0px 0px 0px"
                }}
                fluid
                name="comment"
                value={comment}
                control={TextArea}
                onChange={this.handleChange}
                size="medium"
                label={contactmodal.mensaje[this.props.currentlanguage]}
                placeholder={contactmodal.mensaje[this.props.currentlanguage]}
                required
              />

              <Button
                color="pink"
                floated="left"
                style={{
                  height: "3rem",
                  width: "12rem",
                  backgroundColor: "#79bee9",
                  color: "white",
                  borderRadius: "25px"
                }}
                onClick={this.handleOpen}
              >
                {contactmodal.boton[this.props.currentlanguage]}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    const { name, email, comment, Tel } = this.state;
    return (
      <div>
        <HeaderComp bgimages="buzon" />
        <Container style={{ padding: "5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {headertitle.buzon[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                "info",
                this.props.currentlanguage
              )
            }}
          />
          <div style={{ padding: "2rem 0rem" }}>
            <Form onSubmit={this.handleSubmit} style={{ padding: 0 }}>
              <Form.Input
                fluid
                label={contactmodal.nombre[this.props.currentlanguage]}
                placeholder={contactmodal.nombre[this.props.currentlanguage]}
                required
                name="name"
                value={name}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",

                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.email[this.props.currentlanguage]}
                placeholder={contactmodal.email[this.props.currentlanguage]}
                required
                name="email"
                value={email}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.telefono[this.props.currentlanguage]}
                placeholder={contactmodal.telefono[this.props.currentlanguage]}
                name="Tel"
                value={Tel}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                style={{
                  height: "8rem",
                  paddingBottom: "1rem",
                  boxShadow: "0px 0px 0px"
                }}
                fluid
                name="comment"
                value={comment}
                control={TextArea}
                onChange={this.handleChange}
                size="medium"
                label={contactmodal.mensaje[this.props.currentlanguage]}
                placeholder={contactmodal.mensaje[this.props.currentlanguage]}
                required
              />

              <Button
                color="pink"
                floated="left"
                style={{
                  height: "3rem",
                  width: "12rem",
                  backgroundColor: "#79bee9",
                  color: "white",
                  borderRadius: "25px"
                }}
                onClick={this.handleOpen}
              >
                {contactmodal.boton[this.props.currentlanguage]}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    const { name, email, comment, Tel } = this.state;
    return (
      <div>
        <HeaderComp bgimages="buzon" />
        <Container style={{ padding: "2.5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {headertitle.buzon[this.props.currentlanguage]}
          </h1>
          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.sectionContent,
                "info",
                this.props.currentlanguage
              )
            }}
          />
          <div style={{ padding: "2rem 0rem" }}>
            <Form onSubmit={this.handleSubmit} style={{ padding: 0 }}>
              <Form.Input
                fluid
                label={contactmodal.nombre[this.props.currentlanguage]}
                placeholder={contactmodal.nombre[this.props.currentlanguage]}
                required
                name="name"
                value={name}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",

                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.email[this.props.currentlanguage]}
                placeholder={contactmodal.email[this.props.currentlanguage]}
                required
                name="email"
                value={email}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                fluid
                label={contactmodal.telefono[this.props.currentlanguage]}
                placeholder={contactmodal.telefono[this.props.currentlanguage]}
                name="Tel"
                value={Tel}
                onChange={this.handleChange}
                style={{
                  height: "3.5rem",
                  borderWidth: "50"
                }}
              />

              <Form.Input
                style={{
                  height: "8rem",
                  paddingBottom: "1rem",
                  boxShadow: "0px 0px 0px"
                }}
                fluid
                name="comment"
                value={comment}
                control={TextArea}
                onChange={this.handleChange}
                size="medium"
                label={contactmodal.mensaje[this.props.currentlanguage]}
                placeholder={contactmodal.mensaje[this.props.currentlanguage]}
                required
              />

              <Button
                color="pink"
                floated="left"
                style={{
                  height: "3rem",
                  width: "12rem",
                  backgroundColor: "#79bee9",
                  color: "white",
                  borderRadius: "25px"
                }}
                onClick={this.handleOpen}
              >
                {contactmodal.boton[this.props.currentlanguage]}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BuzonTransparencia)
);
