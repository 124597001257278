import React, { Component } from "react";
import { Responsive } from "semantic-ui-react";

const labels = [
  { id: 1, name: "ARA" },
  { id: 2, name: "Variacion:" },
  { id: 3, name: "Volumen:" },
  { id: 4, name: "Máximo del día" },
  { id: 5, name: "Mínimo del día:" }
];

class CotizationComp extends Component {
  constructor(props) {
    super(props);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: "0rem 2rem" }}>
        {this.props.currentlanguage === "_es" ? (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes.php"
            width="100%"
          >
            Iframes
          </iframe>
        ) : (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes-en.php"
          >
            Iframes
          </iframe>
        )}
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "0rem" }}>
        {this.props.currentlanguage === "_es" ? (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes.php"
            width="100%"
          >
            Iframes
          </iframe>
        ) : (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes.php"
          >
            Iframes
          </iframe>
        )}
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "0rem 2rem" }}>
        {this.props.currentlanguage === "_es" ? (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes.php"
            width="100%"
          >
            Iframes
          </iframe>
        ) : (
          <iframe
            align="top"
            frameborder="0"
            height="320"
            id="blockrandom"
            name=""
            scrolling="no"
            src="https://pixelsiete.net/financialdata/ara/getQuotes.php"
          >
            Iframes
          </iframe>
        )}
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
export default CotizationComp;
