import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { corporativo } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import YearSelector from "../../components/YearSelector";
import {
  GetArrayALike,
  GetFileFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile
} from "../../scripts";

class MejoresPracticas extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = { arrayData: [] };
    this.currentValue = "";
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "practices", "betterPractices");

        

        api.getFilesOf(pageId).then(responseFiles => {
          
          this.array = UpdateArrayFile(
            responseFiles[0],
            "practices",
            this.array
          );
          this.currentYears = [];

          for (let i = 0; i < this.array.length; i++) {
            const currentYear = this.array[i];
            

            let selector = {};
            selector.key = currentYear.id;
            selector.value = currentYear.id;
            selector.text = GetTextFromContent(
              currentYear,
              "year",
              this.props.currentlanguage
            );
            const dataAdd = [];
            let dataAddSet = {};
            dataAddSet.name = corporativo.practicas[this.props.currentlanguage];
            dataAddSet.files = GetFileFromContent(
              currentYear,
              "file",
              this.props.currentlanguage
            );
            dataAddSet.bgcolor = "#74B1D4";
            dataAdd.push(dataAddSet);

            let dataSet = {};
            dataSet.id = currentYear.id;
            dataSet.arrayData = dataAdd;
            selector.data = dataSet;
            
            if(selector.text){
              this.currentYears.unshift(selector);
            }
            
            
          }
          this.currentValue = this.currentYears[0].value;
          this.currentInfo = this.currentYears[0].data;
          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
              {corporativo.practicas[this.props.currentlanguage]}
            </h1>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={returnData => {
                
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>

          <ColoredSegments
            list={this.currentInfo.arrayData}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
              {corporativo.practicas[this.props.currentlanguage]}
            </h1>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={returnData => {
                
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>

          <ColoredSegments
            list={this.currentInfo.arrayData}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {corporativo.practicas[this.props.currentlanguage]}
          </h1>
          <div style={{ paddingTop: "1rem" }}>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={returnData => {
                
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>

          <ColoredSegments
            list={this.currentInfo.arrayData}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(MejoresPracticas)
);
