import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container, Image, List, Responsive } from 'semantic-ui-react';
import API from '../../API/API';
import { emailicon, phoneicon } from '../../assets/Assets';
import { corporativo } from '../../assets/Language';
import ColoredSegments from '../../components/ColoredSegments';
import {
  GetContent,
  GetFileFromContent,
  GetPage,
  GetRichTextFromContent,
  GetTextFromContent,
} from '../../scripts';
/* 
const descargas = [
  {
    id: 1,
    name: "Mejores Prácticas",
    bgcolor: "#74B1D4",
    disabled: false
  }
]; */

class CodigoEtica extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.state = {
      currentArray: [],
    };
    this.mainInfo = {};
    this.arrayInfo = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'corporate').id;
      api.getContentOf(pageId).then((response) => {
        this.mainInfo = GetContent(response[0], 'ethics', 'main');

        this.arrayInfo = [
          {
            id: 1,
            icon: phoneicon,
            text: corporativo.area[this.props.currentlanguage],
            type: 'tel',
            link: GetTextFromContent(
              this.mainInfo,
              'phoneMetro',
              this.props.currentlanguage
            ),
          },
          {
            id: 2,
            icon: phoneicon,
            text: corporativo.interior[this.props.currentlanguage],
            type: 'tel',
            link: GetTextFromContent(
              this.mainInfo,
              'phoneRep',
              this.props.currentlanguage
            ),
          },
          {
            id: 3,
            icon: emailicon,
            text: corporativo.mail[this.props.currentlanguage],
            type: 'mailto',
            link: GetTextFromContent(
              this.mainInfo,
              'email',
              this.props.currentlanguage
            ),
          },
        ];
        api.getFilesOf(pageId).then((responseFiles) => {
          const filesCheck = GetContent(responseFiles[0], 'ethics', 'main');
          const arrayFiles = [];
          let data = {};
          data.name = corporativo.codigo[this.props.currentlanguage];
          data.files = GetFileFromContent(
            filesCheck,
            'file',
            this.props.currentlanguage
          );
          data.bgcolor = '#74B1D4';
          arrayFiles.push(data);
          this.setState({ currentArray: arrayFiles });
        });
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '2rem',
            }}
          >
            {corporativo.codigo[this.props.currentlanguage]}
          </h1>

          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.mainInfo,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <List style={{ padding: '2rem 0rem' }}>
            {this.arrayInfo.map((item) => {
              return (
                <List.Item style={{ paddingBottom: '1rem' }}>
                  <p>
                    <Image
                      src={item.icon}
                      size="mini"
                      style={{ display: 'inline' }}
                    />{' '}
                    {item.text}{' '}
                    <a href={item.type + ':' + item.link}>{item.link}</a>{' '}
                  </p>
                </List.Item>
              );
            })}
          </List>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.state.currentArray}
            singleFile={true}
            fileName={'file'}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '2rem',
            }}
          >
            {corporativo.codigo[this.props.currentlanguage]}
          </h1>

          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.mainInfo,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <List style={{ padding: '2rem 0rem' }}>
            {this.arrayInfo.map((item) => {
              return (
                <List.Item style={{ paddingBottom: '1rem' }}>
                  <p>
                    <Image
                      src={item.icon}
                      size="mini"
                      style={{ display: 'inline' }}
                    />{' '}
                    {item.text}{' '}
                    <a href={item.type + ':' + item.link}>{item.link}</a>{' '}
                  </p>
                </List.Item>
              );
            })}
          </List>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.state.currentArray}
            singleFile={true}
            fileName={'file'}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: '2.5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '1rem',
            }}
          >
            {corporativo.codigo[this.props.currentlanguage]}
          </h1>

          <div
            dangerouslySetInnerHTML={{
              __html: GetRichTextFromContent(
                this.mainInfo,
                'body',
                this.props.currentlanguage
              ),
            }}
          />

          <List style={{ padding: '2rem 0rem' }}>
            {this.arrayInfo.map((item) => {
              return (
                <List.Item style={{ paddingBottom: '1rem' }}>
                  <p>
                    <Image
                      src={item.icon}
                      size="mini"
                      style={{ display: 'inline' }}
                    />{' '}
                    {item.text}{' '}
                    <a href={item.type + ':' + item.link}>{item.link}</a>{' '}
                  </p>
                </List.Item>
              );
            })}
          </List>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.state.currentArray}
            singleFile={true}
            fileName={'file'}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(CodigoEtica)
);
