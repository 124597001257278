import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { consorcioara } from "../../assets/Language";
import YearSelector from "../../components/YearSelector";
import {
  GetArrayALike,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent,
  GetTextFromContent,
  UpdateArrayImage
} from "../../scripts";

class Historia extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = {};
    this.currentValue = "";
    this.array = [];
    this.currentYears = [];

  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "history", "history");

        api.getImageOf(pageId).then(responseImages => {
          
          this.array = UpdateArrayImage(
            responseImages[0],
            "history",
            this.array
          );
          this.currentYears = [];

          for (let i = 0; i < this.array.length; i++) {
            const currentYear = this.array[i];
            

            let selector = {};
            selector.key = currentYear.id;
            selector.value = currentYear.id;
            selector.text = GetTextFromContent(
              currentYear,
              "year",
              this.props.currentlanguage
            );
            const dataset = {};
            dataset.id = currentYear.id;
            dataset.richtext = GetRichTextFromContent(
              currentYear,
              "body",
              this.props.currentlanguage
            );
            dataset.year = GetTextFromContent(
              currentYear,
              "year",
              this.props.currentlanguage
            );
            dataset.img = GetImageFromContent(
              currentYear,
              "img",
              this.props.currentlanguage
            );
            selector.data = dataset;
            if(selector.data.year && selector.text){
              this.currentYears.unshift(selector);
            }
            
          }
          this.currentValue = this.currentYears[0].value;
          this.currentInfo = this.currentYears[0].data;
          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ margin: "1rem 0rem" }}>
        <Grid columns={2}>
          <Grid.Column style={{ padding: "5rem 0rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "60%" }}>
                <h1
                  style={{
                    color: "#014078",
                    fontSize: "2.5rem"
                  }}
                >
                  {consorcioara.historia[this.props.currentlanguage]}
                </h1>
                <div style={{ paddingTop: "3rem" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.currentInfo.richtext
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{
              padding: "5rem 0rem",
              backgroundColor: "rgba(239,244,247,0.7)"
            }}
          >
            <div
              style={{
                width: "85%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <YearSelector
                options={this.currentYears}
                value={this.currentValue}
                returnYearSelected={returnData => {
                  this.currentInfo = returnData.data;
                  this.currentValue = returnData.value;
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "60%", paddingTop: "2rem" }}>
                <div
                  style={{
                    backgroundImage: "url(" + this.currentInfo.img + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "27rem",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ margin: "0rem" }}>
        <Grid columns={2} style={{ width: "100%", margin: 0 }}>
          <Grid.Column style={{ padding: "5rem 0rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "85%" }}>
                <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
                  {consorcioara.historia[this.props.currentlanguage]}
                </h1>
                <div style={{ paddingTop: "3rem" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.currentInfo.richtext
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{
              padding: "5rem 0rem",
              backgroundColor: "rgba(239,244,247,0.7)"
            }}
          >
            <div
              style={{
                width: "85%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <YearSelector
                options={this.currentYears}
                value={this.currentValue}
                returnYearSelected={returnData => {
                  this.currentInfo = returnData.data;
                  this.currentValue = returnData.value;
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "95%", paddingTop: "3rem" }}>
                <div
                  style={{
                    backgroundImage: "url(" + this.currentInfo.img + ")",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "23rem",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{}}>
        <Grid stackable columns={2}>
          <Grid.Column>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "2.5rem 0rem 3rem"
              }}
            >
              <div style={{}}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
                    {consorcioara.historia[this.props.currentlanguage]}
                  </h1>
                </div>
                <div style={{ paddingTop: "1rem" }}>
                  <YearSelector
                    options={this.currentYears}
                    value={this.currentValue}
                    returnYearSelected={returnData => {
                      this.currentInfo = returnData.data;
                      this.currentValue = returnData.value;
                      this.forceUpdate();
                    }}
                  />
                </div>
                <div style={{ paddingTop: "3rem" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.currentInfo.richtext
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{
              backgroundColor: "rgba(239,244,247,0.7)",
              padding: 0
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ margin: "3rem 0rem", width: "100%" }}>
                <div
                  style={{
                    backgroundImage: "url(" + this.currentInfo.img + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    width: "100%",
                    height: "27rem",
                    display: "flex",
                    alignItems: "flex-end"
                  }}
                />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Historia)
);
