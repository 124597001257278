import React, { Component } from "react";
import { Button, Header, Icon, Responsive } from "semantic-ui-react";

class MaskedImg extends Component {
  constructor(props) {
    super(props);
  }
  renderInDesktop() {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + this.props.img + ")",
            backgroundSize: "auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "31rem",
            width: "100%"
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              width: "100%",
              height: "100%"
            }}
          >
            <h1
              style={{
                color: "white",
                padding: "2rem 0rem 0rem 3rem",
                fontSize: "2.5rem"
              }}
            >
              {this.props.title}
            </h1>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                padding: "3rem 3rem",
                width: "96%"
              }}
            >
              {this.props.singleButton && this.props.file !== "" ? (
                <Button.Group style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal",
                      width: "20rem"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              ) : (
                <Button.Group fluid widths={2} style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRight: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>
                  <Button
                    href={this.props.file2}
                    target="_blank"
                    style={{
                      borderLeft: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName2}
                      </label>
                      <br />
                      {this.props.date2}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + this.props.img + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "30rem",
            width: this.props.width ? this.props.width : "94vw"
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              width: "100%",
              height: "100%"
            }}
          >
            <h1
              style={{
                color: "white",
                padding: this.props.titlePadding
                  ? this.props.titlePadding
                  : "2rem 0rem 0rem 3rem",
                fontSize: this.props.titleSize ? this.props.titleSize : "2.5rem"
              }}
            >
              {this.props.title}
            </h1>
            <div
              style={{
                position: "absolute",
                bottom: 0,
                padding: "2rem 1rem",
                width: "94%"
              }}
            >
              {this.props.singleButton ? (
                <Button.Group fluid style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              ) : (
                <Button.Group fluid style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRight: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>
                  <Button
                    href={this.props.file2}
                    target="_blank"
                    style={{
                      borderLeft: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName2}
                      </label>
                      <br />
                      {this.props.date2}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div>
        <div
          style={{
            backgroundImage: "url(" + this.props.img + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "25rem",
            width: "100%"
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
              width: "100%",
              height: "100%"
            }}
          >
            <h1
              style={{
                color: "white",
                padding: "2rem 0rem 0rem 1rem",
                fontSize: "2rem"
              }}
            >
              {this.props.title}
            </h1>
            <div
              style={{
                position: "absolute",
                bottom: "1rem",
                width: "100%",
                padding: "1rem"
              }}
            >
              {this.props.singleButton ? (
                <Button.Group fluid style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.5rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              ) : (
                <Button.Group compact widths={2} style={{}}>
                  {" "}
                  <Button
                    href={this.props.file}
                    target="_blank"
                    style={{
                      borderRight: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.5em" }}
                        color="blue"
                        name="download"
                      />{" "}
                      <label style={{ fontSize: "1.2rem" }}>
                        {this.props.fileName}
                      </label>
                      <br />
                      {this.props.date}
                    </Header>
                  </Button>
                  <Button
                    href={this.props.file2}
                    target="_blank"
                    style={{
                      borderLeft: "1px solid black",
                      borderRadius: 0,
                      backgroundColor: "white",
                      color: "black",
                      fontWeight: "normal"
                    }}
                  >
                    <Header icon>
                      <Icon
                        size="large"
                        style={{ fontSize: "1.2em" }}
                        color="blue"
                        name="download"
                      />
                      <label style={{ fontSize: "1.2rem" }}>
                        {this.props.fileName2}
                      </label>
                      <br />
                      {this.props.date2}
                    </Header>
                  </Button>{" "}
                </Button.Group>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
export default MaskedImg;
