import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import CotizationComp from "../../components/CotizationComp";
import MaskedImg from "../../components/MaskedImg";
import {
  GetContent,
  GetFileFromContent,
  GetImageFromContent,
  GetLinkFromContent,
  GetTextFromContent
} from "../../scripts";
import { hometxt } from "../../assets/Language";

class Home4 extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.developContent = {};
    this.sectionFiles = {};
    this.sectionImages = {};
    this.developImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = pages[0].id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "reports", "main");
        this.developContent = GetContent(
          response[0],
          "reports",
          "developments"
        );

        api.getFilesOf(pageId).then(responseFiles => {
          this.sectionFiles = GetContent(responseFiles[0], "reports", "main");

          this.forceUpdate();
        });

        api.getImageOf(pageId).then(responseImages => {
          this.sectionImages = GetContent(responseImages[0], "reports", "main");
          this.developImages = GetContent(
            responseImages[0],
            "reports",
            "developments"
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    const images = [
      {
        id: 1,
        image: GetImageFromContent(
          this.developImages,
          "imgDevelopment1",
          this.props.currentlanguage
        ),
        href: GetLinkFromContent(
          this.developContent,
          "linkDevelopment1",
          this.props.currentlanguage
        )
      },
      {
        id: 2,
        image: GetImageFromContent(
          this.developImages,
          "imgDevelopment2",
          this.props.currentlanguage
        ),
        href: GetLinkFromContent(
          this.developContent,
          "linkDevelopment2",
          this.props.currentlanguage
        )
      },
      {
        id: 3,
        image: GetImageFromContent(
          this.developImages,
          "imgDevelopment3",
          this.props.currentlanguage
        ),
        href: GetLinkFromContent(
          this.developContent,
          "linkDevelopment3",
          this.props.currentlanguage
        )
      },
      {
        id: 4,
        image: GetImageFromContent(
          this.developImages,
          "imgDevelopment4",
          this.props.currentlanguage
        ),
        href: GetLinkFromContent(
          this.developContent,
          "linkDevelopment4",
          this.props.currentlanguage
        )
      },
      {
        id: 5,
        image: GetImageFromContent(
          this.developImages,
          "imgDevelopment5",
          this.props.currentlanguage
        ),
        href: GetLinkFromContent(
          this.developContent,
          "linkDevelopment5",
          this.props.currentlanguage
        )
      }
    ];
    return (
      <div style={{ backgroundColor: "#00457C", paddingBottom: "5rem" }}>
        <Container>
          <Grid columns={2}>
            <Grid.Column width={10}>
              <MaskedImg
                img={GetImageFromContent(
                  this.sectionImages,
                  "img",
                  this.props.currentlanguage
                )}
                file={GetFileFromContent(
                  this.sectionFiles,
                  "quarterly",
                  this.props.currentlanguage
                )}
                file2={GetFileFromContent(
                  this.sectionFiles,
                  "annual",
                  this.props.currentlanguage
                )}
                title={hometxt.reportes[this.props.currentlanguage]}
                fileName={hometxt.trimestral[this.props.currentlanguage]}
                fileName2={hometxt.anual[this.props.currentlanguage]}
                date={GetTextFromContent(
                  this.sectionContent,
                  "quarterlyTitle",
                  this.props.currentlanguage
                )}
                date2={GetTextFromContent(
                  this.sectionContent,
                  "annualTitle",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={6}>
              <CotizationComp currentlanguage={this.props.currentlanguage} />
            </Grid.Column>
            <Grid.Row columns={5} style={{ paddingTop: "4rem" }}>
              {images.map((item, index) => {
                return (
                  <Grid.Column verticalAlign="bottom">
                    <Image
                      centered
                      size="small"
                      href={item.href}
                      src={item.image}
                    />
                  </Grid.Column>
                );
              })}
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }

  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Home4)
);
