import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container, Grid, Responsive } from 'semantic-ui-react';
import API from '../../API/API';
import { eventostxt } from '../../assets/Language';
import YearSelector from '../../components/YearSelector';
import {
  GetFileFromContent,
  GetPage,
  GetSlider,
  GetTextFromContent,
  UpdateArrayFile,
} from '../../scripts';

const years = [
  { key: 1, text: '2017', value: '2017' },
  { key: 2, text: '2016', value: '2016' },
  { key: 3, text: '2015', value: '2015' },
  { key: 4, text: '2014', value: '2014' },
  { key: 5, text: '2013', value: '2013' },
];

const eventos = [
  {
    id: 1,
    date: '08/11/2018',
    event:
      'Ratificación del despacho que prestará los servicios de auditoría externa',
  },
  {
    id: 2,
    date: '15/06/2018',
    event:
      'CONSORCIO ARA extiende la vigencia del contrato con UBS Casa de Bolsa, S.A. de C.V. como formador de mercado',
  },
  {
    id: 3,
    date: '04/05/2018',
    event:
      "CONSORCIO ARA anuncia el incremento en su calificación crediticia a 'mxA+' por parte de S&P Global Ratings",
  },
  {
    id: 4,
    date: '02/02/2018',
    event: 'Consorcio ARA anuncia estimado de ingresos para 2018',
  },
  {
    id: 5,
    date: '31/01/2018',
    event:
      'Consorcio ARA informa sobre el cumplimiento de su guía de resultados de 2017',
  },
];

const compareDate = (up, a, b) => {
  let aValue = moment(a.date);
  let bValue = moment(b.date);
  if (aValue.isBefore(bValue)) {
    let valueReturn = -1;
    if (up === false) {
      valueReturn = 1;
    }
    return valueReturn;
  }
  if (bValue.isBefore(aValue)) {
    let valueReturn = 1;
    if (up === false) {
      valueReturn = -1;
    }
    return valueReturn;
  }
  return 0;
};

const compareYear = (up, a, b) => {
  let aValue = parseInt(a.value);
  let bValue = parseInt(b.value);
  if (aValue < bValue) {
    let valueReturn = -1;
    if (up === false) {
      valueReturn = 1;
    }
    return valueReturn;
  }
  if (aValue > bValue) {
    let valueReturn = 1;
    if (up === false) {
      valueReturn = -1;
    }
    return valueReturn;
  }
  return 0;
};

class Eventos extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = [];
    this.currentValue = '';
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'events').id;

      api.getContentOf(pageId).then((response) => {
        this.array = GetSlider(response[0], 'events', 'event');

        api.getFilesOf(pageId).then((responseFiles) => {
          this.array = UpdateArrayFile(responseFiles[0], 'events', this.array);
          this.currentYears = [];
          let allYears = {};
          allYears.key = 'Todos';
          allYears.value = 'Todos';
          allYears.text = 'Todos';
          allYears.data = [];

          for (let i = 0; i < this.array.length; i++) {
            const currentYear = this.array[i];

            const dataset = {};
            dataset.id = currentYear.id;
            dataset.date = GetTextFromContent(currentYear, 'date');
            dataset.event = GetTextFromContent(currentYear, 'title');
            dataset.file = GetFileFromContent(currentYear, 'file');

            // allYears.data.push(dataset);
            // if (allYears.data.length > 1) {
            //   allYears.data.sort(function(a, b) {
            //     return compareDate(false, a, b);
            //   });
            // }

            const yearFromDate = moment(
              GetTextFromContent(currentYear, 'date'),
              'DD/MM/YYYY'
            )
              .year()
              .toString();
            let foundYear = this.currentYears.find((yearCheck) => {
              return yearCheck.value === yearFromDate;
            });

            if (foundYear === undefined) {
              let selector = {};
              selector.key = yearFromDate;
              selector.value = yearFromDate;
              selector.text = yearFromDate;
              const arraySet = [];
              arraySet.push(dataset);
              selector.data = arraySet;
              if (selector.text) {
                this.currentYears.unshift(selector);
              }
            } else {
              const indexOfYear = this.currentYears.indexOf(foundYear);
              this.currentYears[indexOfYear].data.push(dataset);
              if (this.currentYears[indexOfYear].data.length > 1) {
                this.currentYears[indexOfYear].data.sort(function(a, b) {
                  return compareDate(false, a, b);
                });
              }
            }
          }
          this.currentYears.sort(function(a, b) {
            return compareYear(false, a, b);
          });
          // this.currentYears.unshift(allYears);
          this.currentValue = this.currentYears[0].value;
          this.currentInfo = this.currentYears[0].data;
          this.forceUpdate();
        });
      });
      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '3rem',
            }}
          >
            {eventostxt.eventos[this.props.currentlanguage]}
          </h1>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <Grid divided="vertically" style={{ padding: '1rem 0rem' }}>
            <Grid.Row columns={3}>
              <Grid.Column width={3} style={{ paddingLeft: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.fecha[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={13}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.evento[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.currentInfo.map((item) => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={3} style={{ paddingLeft: '2rem' }}>
                    <label style={{}}>{item.date}</label>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <a
                      style={{ cursor: 'pointer' }}
                      href={item.file}
                      target="_blank"
                    >
                      {item.event}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '3rem',
            }}
          >
            {eventostxt.eventos[this.props.currentlanguage]}
          </h1>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <YearSelector 
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <Grid divided="vertically" style={{ padding: '1rem 0rem' }}>
            <Grid.Row columns={3}>
              <Grid.Column width={3} style={{ paddingLeft: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.fecha[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={13}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.evento[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.currentInfo.map((item) => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={3} style={{ paddingLeft: '2rem' }}>
                    <label style={{}}>{item.date}</label>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <a
                      style={{ cursor: 'pointer' }}
                      href={item.file}
                      target="_blank"
                    >
                      {item.event}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: '2.5rem 0rem' }}>
        <Container>
          <h1
            style={{
              color: '#014078',
              fontSize: '2.5rem',
              paddingBottom: '1rem',
            }}
          >
            {eventostxt.eventos[this.props.currentlanguage]}
          </h1>

          <div style={{ paddingBottom: '1rem' }}>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <Grid divided="vertically" style={{ padding: '1rem 0rem' }}>
            <Grid.Row columns={3}>
              <Grid.Column width={5} style={{ paddingLeft: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.fecha[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={11}>
                <h2 style={{ fontSize: '1.5rem' }}>
                  {eventostxt.evento[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.currentInfo.map((item) => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={5} style={{ paddingLeft: '2rem' }}>
                    <label style={{}}>{item.date}</label>
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <a
                      style={{ cursor: 'pointer' }}
                      href={item.file}
                      target="_blank"
                    >
                      {item.event}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Eventos)
);
