export const legaltitles = {
  terms: {
    _en: 'Terms and conditions',
    _es: 'Términos y condiciones',
  },
  privacy: {
    _en: 'Privacy policies',
    _es: 'Políticas de privacidad',
  },
};

export const Etica = {
  LineaEtica: {
    _es: 'Línea Ética',
    _en: 'Ethical line',
  },
};
export const consorcioara = {
  perfilcorp: {
    _es: 'Perfil Corporativo',
    _en: 'Corporate Profile',
  },
  filosofia: {
    _es: 'Filosofía',
    _en: 'Philosophy',
  },
  historia: {
    _es: 'Historia',
    _en: 'History',
  },
  fortalezas: {
    _es: 'Fortalezas ARA',
    _en: 'ARA Strengths',
  },
  portafolio: {
    _es: 'Portafolio de productos',
    _en: 'Product Portfolio',
  },
  presenciageo: {
    _es: 'Presencia Geográfica',
    _en: 'Geographic Footprint',
  },
  estructura: {
    _es: 'Estructura Accionaria',
    _en: 'Ownership Structure',
  },
  reconocimientos: {
    _es: 'Reconocimientos',
    _en: 'Awards and Recognitions',
  },
  presencia: {
    _es: 'Tenemos Presencia en: ',
    _en: 'We are currently present in: ',
  },
  states: {
    _es: 'ESTADOS',
    _en: 'STATES',
  },
  cities: {
    _es: 'CIUDADES',
    _en: 'CITIES',
  },
  municipalities: {
    _es: 'MUNICIPIOS',
    _en: 'MUNICIPALITIES',
  },
  developments: {
    _es: 'DESARROLLOS',
    _en: 'DEVELOPMENTS',
  },
  mision: {
    _es: 'MISIÓN',
    _en: 'MISSION',
  },
  vision: {
    _es: 'VISIÓN',
    _en: 'VISION',
  },
  valores: {
    _es: 'VALORES',
    _en: 'VALUES',
  },
  interes: {
    _es: 'Interés Social',
    _en: 'Affordable Entry-Level',
  },
  tipo: {
    _es: 'Tipo Medio',
    _en: 'Middle-Income',
  },
  residencial: {
    _es: 'Residencial',
    _en: 'Residential',
  },
  comerciales: {
    _es: 'Centros Comerciales',
    _en: 'Shopping Centers',
  },
  ingresos: {
    _es: 'Mezcla de Ingresos',
    _en: 'Revenue Mix',
  },
  rangoPrecios: {
    _es: 'Rango de Precio:',
    _en: 'Price range:',
  },
  financiamiento: {
    _es: 'Financiamiento Hipotecario:',
    _en: 'Mortgage financing:',
  },
  ingresoMensual: {
    _es: 'Ingreso Mensual:',
    _en: 'Monthly income eligibility:',
  },
  socioeconomico: {
    _es: 'Nivel Socioeconómico:',
    _en: 'Socioeconomic level:',
  },
  source: {
    _es: 'Fuente: ',
    _en: 'Source: ',
  },
};

export const financiera = {
  reportestri: {
    _es: 'Reportes Trimestrales',
    _en: 'Quarterly Reports',
  },
  reportesan: {
    _es: 'Reportes Anuales',
    _en: 'Annual Reports',
  },
  presentacion: {
    _es: 'Presentacion Corporativa',
    _en: 'Corporate Presentation',
  },
  calificaciones: {
    _es: 'Calificaciones',
    _en: 'Credit Ratings',
  },
  informe: {
    _es: 'Informe Anual',
    _en: 'Annual Report',
  },
  reporte: {
    _es: 'Reporte Anual',
    _en: 'Annual Report',
  },
  director: {
    _es: 'Informe del director',
    _en: 'Earnings releases',
  },
  bmv: {
    _es: 'Reporte BMV/CNBV',
    _en: 'BMV/CNBV Report',
  },
  conferencias: {
    _es: 'Invitacion Conferencias',
    _en: 'Conference Invitation',
  },
  audio: {
    _es: 'Conferencias Audio',
    _en: 'Conference Audio',
  },
};

export const bursatil = {
  cotizacion: {
    _es: 'Cotización de la Acción',
    _en: 'Stock Quote',
  },
  grafica: {
    _es: 'Gráfica Interactiva',
    _en: 'Stock Chart',
  },
  precios: {
    _es: 'Precios Historicos',
    _en: 'Historical Prices',
  },
  dividendos: {
    _es: 'Dividendos',
    _en: 'Dividends',
  },
  analistas: {
    _es: 'Analistas',
    _en: 'Analyst Coverage',
  },
  prospectos: {
    _es: 'Prospectos de Colocación',
    _en: 'Offering Prospectuses',
  },
  info: {
    _es: 'Desde el 2005 a la fecha, ha pagado un',
    _en: 'From 2005 to date, it has paid out',
  },
  info2: {
    _es: 'total de',
    _en: 'a total of',
  },
  info3: {
    _es: 'millones',
    _en: 'billion',
  },
  compañia: {
    _es: 'Institución',
    _en: 'Company',
  },
  analista: {
    _es: 'Analista',
    _en: 'Name',
  },
};
export const etica = {
  codigo: {
    _es: 'Código de Ética',
    _en: 'Code of Ethics',
  },
};
export const corporativo = {
  consejo: {
    _es: 'Consejo y Comités',
    _en: 'Board and Committees',
  },
  equipo: {
    _es: 'Equipo Directivo',
    _en: 'Corporate Directors',
  },
  estatutos: {
    _es: 'Estatutos',
    _en: 'Bylaws',
  },
  practicas: {
    _es: 'Mejores Prácticas',
    _en: 'Best Practices',
  },
  codigo: {
    _es: 'Código de Ética',
    _en: 'Code of Ethics',
  },
  asamblea: {
    _es: 'Asamblea de Accionistas',
    _en: "Shareholders' Meeting",
  },
  title1: {
    _es: 'Consejo de Administración',
    _en: "Shareholders' Meeting",
  },
  info1: {
    _es: 'Consejo de Administración',
    _en: "shareholders' meeting",
  },
  nombre: {
    _es: 'Nombre',
    _en: 'Name',
  },
  posicion: {
    _es: 'Cargo',
    _en: 'Position',
  },
  suplente: {
    _es: 'Suplente',
    _en: 'Alternate',
  },
  audnombre: {
    _es: 'Nombre',
    _en: 'Chairman',
  },
  audcargo: {
    _es: 'Cargo',
    _en: 'Fullmember',
  },
  desconsejo: {
    _es:
      'De conformidad con lo dispuesto por los estatutos sociales vigentes de la Compañía y por la Ley del Mercado de Valores, el Consejo de Administración de Consorcio ARA ha establecido un Comité de Auditoría y un Comité de Prácticas Societarias.',
    _en:
      'Based on the current bylaws of Consorcio ARA, and as required by the Mexican Securities Market Act, our Board of Directors has created an Audit Committee and a Corporate Practices Committee.',
  },
  audnota: {
    _es: 'Nota: Todos los miembros del Comité son independientes.',
    _en: 'Note: All committee members are independent.',
  },
  consejotitle: {
    _es: 'Consejo de Administración',
    _en: 'Board of Directors',
  },
  comitetitle: {
    _es: 'Comité de Auditoría',
    _en: 'Audit Committee',
  },
  practicastitle: {
    _es: 'Comité de Prácticas Societarias',
    _en: 'Corporate Practices Committee',
  },
  divisionlist: {
    _es: 'División Inmobiliaria',
    _en: 'Housing Division',
  },
  area: {
    _es: 'Área Metropolitana:',
    _en: 'Mexico City metropolitan area:',
  },
  interior: {
    _es: 'Interior de la República:',
    _en: 'From elsewhere in Mexico:',
  },
  mail: {
    _es: 'Correo Electrónico:',
    _en: 'E-mail:',
  },
  convocatorias: {
    _es: 'Convocatorias:',
    _en: 'Notice of Meetings:',
  },
  propuestas: {
    _es: 'Propuestas:',
    _en: 'Proposals:',
  },
  acuerdos: {
    _es: 'Acuerdos:',
    _en: 'Agreements:',
  },
  aviso: {
    _es: 'Aviso a los accionistas:',
    _en: 'Notices of shareholders:',
  },
  adicional: {
    _es: 'Servicios de auditoría externa:',
    _en: 'Additional external audit services:',
  },
};

export const sustentabilidad = {
  vivienda: {
    _es: 'Vivienda y Entorno Sustentable',
    _en: 'Housing and Sustainable Environment',
  },
  analisis: {
    _es: 'ISV Análisis',
    _en: 'HSI Analysis',
  },
  informe: {
    _es: 'Informe',
    _en: 'Report',
  },
  declaratoriaEdge: {
    _es: 'Declaratoria de compromiso de Edge',
    _en: 'EDGE Commitment Statement',
  },
  fundacion: {
    _es: 'Fundación ARA',
    _en: 'ARA Foundation',
  },
  label: {
    _es: 'Te invitamos a conocer la página de',
    _en: 'We invite you to visit the ARA Foundation website at',
  },
};

export const eventostxt = {
  eventos: {
    _es: 'Eventos Relevantes',
    _en: 'Events',
  },
  fecha: {
    _es: 'Fecha',
    _en: 'Date',
  },
  evento: {
    _es: 'Evento',
    _en: 'Event',
  },
};
export const footerlan = {
  contact: {
    _es: 'Contáctanos',
    _en: 'Contact us',
  },
  investors: {
    _es: 'Inversionistas',
    _en: 'Investors Relations',
  },
  clients: {
    _es: 'Clientes ARA',
    _en: 'Customer service',
  },
  copyright: {
    _es: 'Consorcio ARA © Copyright 2018, Todos los derechos reservados',
    _en: 'Consorcio ARA © Copyright 2018, All rights reserved',
  },
  jobBank: {
    _es: 'Bolsa de trabajo',
    _en: 'Jobs',
  },
  shopping: {
    _es: 'Compras',
    _en: 'Shopping',
  },
};

export const contactmodal = {
  contacto: {
    _es: 'Contacto',
    _en: 'Contact us',
  },
  nombre: {
    _es: 'Nombre',
    _en: 'Name',
  },
  email: {
    _es: 'Correo Electrónico',
    _en: 'E-mail',
  },
  telefono: {
    _es: 'Teléfono',
    _en: 'Phone number',
  },
  asunto: {
    _es: 'Asunto',
    _en: 'Subject',
  },
  mensaje: {
    _es: 'Mensaje',
    _en: 'Message',
  },
  boton: {
    _es: 'ENVIAR MENSAJE',
    _en: 'SEND MESSAGE',
  },
};

export const headertitle = {
  lineaEticaheader: {
    _es: 'Consorcio ARA',
    _en: 'Company',
  },
  consorcioheader: {
    _es: 'Consorcio ARA',
    _en: 'Company',
  },
  financierosheader: {
    _es: 'Información Financiera',
    _en: 'Financial Information',
  },
  bursatilheader: {
    _es: 'Bursátil',
    _en: 'Stock Information',
  },
  corporativoheader: {
    _es: 'Gobierno Corporativo',
    _en: 'Corporate Governance',
  },
  sustentabilidadheader: {
    _es: 'Sustentabilidad',
    _en: 'Sustainability',
  },
  eventosheader: {
    _es: 'Eventos',
    _en: 'Press Releases',
  },
  lineaEtica: {
    _es: 'Línea Ética',
    _en: 'Ethical Line',
  },

  buzon: {
    _es: 'Buzón de Transparencia',
    _en: 'Transparency Helpline',
  },
  privacy: {
    _es: 'Nota de protección',
    _en: 'Privacy Policy',
  },
  responsable: {
    _es: 'Personas Responsables',
    _en: 'Responsible Persons',
  },
  legal: {
    _es: 'Nota de Protección Legal',
    _en: 'Privacy Policy',
  },
  search: {
    _es: 'Busqueda',
    _en: 'Search',
  },
};

export const hometxt = {
  segment1: {
    _es: 'INFORMACIÓN FINANCIERA',
    _en: 'FINANCIAL INFORMATION',
  },
  segment2: {
    _es: 'INFORMACIÓN BURSÁTIL',
    _en: 'STOCK INFORMATION',
  },
  segment3: {
    _es: 'REPORTE TRIMESTRAL',
    _en: 'QUARTERLY REPORT',
  },
  segment4: {
    _es: 'GOBIERNO CORPORATIVO',
    _en: 'CORPORATE GOVERNANCE',
  },
  home2button: {
    _es: 'CONOCER MÁS',
    _en: 'SEE MORE',
  },
  home3title: {
    _es: 'Cifras Relevantes',
    _en: 'Financial Highlights',
  },
  home3subtitle: {
    _es: 'MILLONES DE PESOS',
    _en: 'MILLION PESOS',
  },
  trimestral: {
    _es: 'Informe Trimestral',
    _en: 'Quarterly Report',
  },
  anual: {
    _es: 'Informe Anual',
    _en: 'Annual Report',
  },
  reportes: {
    _es: 'Reportes',
    _en: 'Reports',
  },
  descargar: {
    _es: 'Descargar',
    _en: 'Download',
  },
};
