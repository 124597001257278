import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Button, Container, Icon, Input } from "semantic-ui-react";
import { headertitle } from "../assets/Language";
import HeaderComp from "../components/HeaderComp";
import { getLangOptions } from "../Navigator";
import { styleBasicCenteredObject } from "../style/inlineStyles";

const normalizeString = data => {
  return data.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const getQueryVariable = variable => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      let finalValue = decodeURIComponent(pair[1]);
      return decodeURIComponent(finalValue);
    }
  }
  return "";
};

class SearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = { searchParam: "", currentSearch: "", currentItems: [] };
  }

  componentDidMount() {
    this.checkSearch();
  }

  componentWillReceiveProps(nextProps) {
    const currentSearch = normalizeString(
      getQueryVariable("search")
    ).toUpperCase();
    if (currentSearch !== this.state.currentSearch) {
      this.checkSearch();
    }
  }

  checkSearch() {
    const currentSearch = normalizeString(
      getQueryVariable("search")
    ).toUpperCase();
    const allMenu = getLangOptions(this.props.currentlanguage);
    let sameNameSearch = [];
    if (currentSearch !== "") {
      for (let i = 0; i < allMenu.length; i++) {
        const currentMenu = allMenu[i];
        let addAll = false;
        if (
          normalizeString(currentMenu.name)
            .toUpperCase()
            .includes(currentSearch)
        ) {
          addAll = true;
        }
        for (let b = 0; b < currentMenu.suboptions.length; b++) {
          const currentSubMenu = currentMenu.suboptions[b];
          if (
            normalizeString(currentSubMenu.name)
              .toUpperCase()
              .includes(currentSearch) ||
            addAll === true
          ) {
            const menuToAdd = JSON.parse(JSON.stringify(currentSubMenu));
            menuToAdd.parent = currentMenu.name;
            sameNameSearch.push(menuToAdd);
          }
        }
      }
    }
    this.setState({
      currentSearch: getQueryVariable("search"),
      searchParam: currentSearch,
      currentItems: sameNameSearch
    });
  }

  renderInDesktop() {
    return (
      <div>
        <HeaderComp bgimages="search" />
        <Container style={{ padding: "5rem 0rem" }}>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {headertitle.search[this.props.currentlanguage]}
          </h1>
          <div style={{ paddingTop: "0rem" }}>
            <div
              style={{
                ...styleBasicCenteredObject,
                justifyContent: "flex-start",
                height: "2.5rem"
              }}
            >
              <Input
                value={this.state.currentSearch}
                onChange={e => {
                  this.setState({ currentSearch: e.target.value });
                }}
                transparent
                icon="search"
                iconPosition="left"
                placeholder={
                  headertitle.search[this.props.currentlanguage] + "..."
                }
                style={{
                  fontSize: "1.5rem",
                  maxWidth: "17rem",
                  height: "100%",
                  borderBottom: "1px #014078 solid"
                }}
              />
              <Button
                onClick={() => {
                  window.scroll({
                    top: document.getElementById("topblog").offsetTop - 40,
                    behavior: "smooth"
                  });
                  setTimeout(() => {
                    this.props.push(
                      "/busqueda?search=" + this.state.currentSearch
                    );
                  }, 50);
                }}
                style={{
                  padding: 0,
                  margin: 0,
                  backgroundColor: "#014078",
                  width: "3.5rem",
                  height: "100%"
                }}
              >
                <Icon
                  name={"chevron right"}
                  style={{
                    ...styleBasicCenteredObject,
                    width: "100%",
                    height: "100%",
                    textAlign: "center",
                    fontSize: "1rem",
                    color: "white"
                  }}
                />
              </Button>
            </div>
            <div id="topblog" style={{ height: "1.3rem" }} />
            <div
              style={{
                ...styleBasicCenteredObject,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column"
              }}
            >
              {this.state.searchParam !== "" &&
                this.state.currentItems.length === 0 && (
                  <div
                    style={{
                      ...styleBasicCenteredObject,
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignSelf: "flex-start",
                      borderBottom: "1px #014078 solid",
                      margin: "1rem 0rem",
                      padding: "1rem 0rem",
                      paddingRight: "1rem",
                      fontSize: "1.4rem",
                      color: "#014078"
                    }}
                  >
                    {"No se encontró ningun resultados"}
                  </div>
                )}
              {this.state.currentItems.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      this.props.push(item.navigateto);
                    }}
                    key={index}
                    style={{
                      ...styleBasicCenteredObject,
                      cursor: "pointer",
                      textAlign: "left",
                      justifyContent: "flex-start",
                      alignSelf: "flex-start",
                      borderBottom: "1px #014078 solid",
                      margin: "1rem 0rem",
                      padding: "1rem 0rem",
                      paddingRight: "1rem",
                      fontSize: "1.4rem",
                      color: "#014078"
                    }}
                  >
                    {item.parent + ": " + item.name}
                  </div>
                );
              })}
            </div>
          </div>
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapDispatchToProps = dispatch => {
  return {
    push: to => {
      dispatch(push(to));
    }
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchPage)
);
