import React, { Component } from "react";
import { Modal, Responsive, Button, Icon } from "semantic-ui-react";

class ModalComponent extends Component {
  constructor(props) {
    super(props);
    this.renderQuitButton = this.renderQuitButton.bind(this);
  }

  renderQuitButton() {
    if (this.props.dontShowQuit === true) {
      return <div />;
    } else {
      return (
        <div
          style={{
            zIndex: 100,
            width: "100%",
            height: "1.4rem",
            marginTop: "0.5rem",
            top: 0
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "flex-end",
              alignSelf: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
              style={{
                padding: 0,
                margin: 0,
                backgroundColor: "transparent",
                marginRight: "0.3rem",
                width: "5rem",
                height: "100%",
                display: "flex",
                textAlign: "right",
                alignItems: "flex-end",
                alignSelf: "flex-end",
                justifyContent: "flex-end"
              }}
            >
              <Icon
                name={"close"}
                style={{
                  textAlign: "right",
                  fontSize: "1rem",
                  color: "black"
                }}
              />
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    let addHeight = {};
    if (this.props.height !== undefined) {
      addHeight = { height: this.props.height };
    }
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <Modal
            onMount={() => {
              if (this.props.onOpen !== undefined) {
                this.props.onOpen();
              }
            }}
            onClose={(event, data) => {
              this.props.closeModal();
            }}
            open={this.props.showModal || false}
            style={{ width: "98%" }}
          >
            {this.renderQuitButton()}
            <div style={{ width: "100%", height: "0.7rem" }} />
            {this.props.children}
          </Modal>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Modal
            onMount={() => {
              if (this.props.onOpen !== undefined) {
                this.props.onOpen();
              }
            }}
            onClose={(event, data) => {
              this.props.closeModal();
            }}
            open={this.props.showModal || false}
            style={{ width: this.props.width || "50%", ...addHeight }}
          >
            {this.renderQuitButton()}
            {this.props.children}
          </Modal>
        </Responsive>
      </div>
    );
  }
}

export default ModalComponent;
