import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { push } from "react-router-redux";
import { Container, Divider, Grid, Image, Responsive } from "semantic-ui-react";
import API from "../API/API";
import { logo, esrnew } from "../assets/Assets";
import { footerlan, headertitle } from "../assets/Language";
import { GetContent, GetPage, GetRichTextFromContent } from "../scripts";

const empresalist = [
  { id: 1, name: "GUADALAJARA" },
  { id: 2, name: "MEXICO" },
  { id: 3, name: "CANCÚN" },
  { id: 4, name: "PUERTO VALLARTA" },
  { id: 5, name: "NORTE" },
];

const contacto = [
  { id: 1, name: "Arcos Bosques Marco II" },
  { id: 2, name: "Paseo de Tamarindos No. 90, Torre1" },
  { id: 3, name: "Piso 25, Bosques de las Lomas" },
  { id: 4, name: "C.P. 05120, Ciudad de México" },
  { id: 5, name: "Tel (52 55) 5246 3100" },
];
const inversionistas = [
  { id: 1, name: "Alicia Enriquez Pimentel" },
  { id: 2, name: "aenriquez@ara.com.mx" },
  { id: 3, name: "" },
  { id: 4, name: "Tel (52 55) 5596 8803" },
  { id: 5, name: "Tel (52 55) 5246 3100" },
];

const clientes = [
  { id: 1, name: "Servicio postventa:" },
  { id: 2, name: "01 800 546 3272" },
  { id: 3, name: "" },
  { id: 4, name: "Quiero comprar:" },
  { id: 5, name: "01 800 022 0581" },
];

class Footer extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.footerContent = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "home").id;

      api.getContentOf(pageId).then((response) => {
        this.sectionContent = GetContent(response[0], "footer", "info");

        this.forceUpdate();
      });
    }
    this.forceUpdate();
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div
        style={{
          backgroundColor: "#030B22",
          height: "100%",
          padding: "5rem 0rem 3rem",
        }}
      >
        <Container style={{}}>
          <Grid>
            <Grid.Row columns={16} only="computer">
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <Image
                  style={{
                    width: "230px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={5} only="computer">
              <Grid.Column width={4} verticalAlign="middle">
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {footerlan.contact[this.props.currentlanguage]}
                </label>
              </Grid.Column>
              <Grid.Column width={3} verticalAlign="middle">
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {footerlan.investors[this.props.currentlanguage]}
                </label>
              </Grid.Column>
              <Grid.Column width={2} verticalAlign="middle">
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {footerlan.clients[this.props.currentlanguage]}
                </label>
              </Grid.Column>
              <Grid.Column width={3} verticalAlign="middle">
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {footerlan.jobBank[this.props.currentlanguage]}
                </label>
              </Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <label
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  {footerlan.shopping[this.props.currentlanguage]}
                </label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={16} only="tablet mobile">
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                style={{
                  paddingBottom: "2rem",
                }}
              >
                <Responsive maxWidth={769}>
                  <Image
                    style={{
                      width: "230px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={logo}
                  />
                </Responsive>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={5}
              style={{ padding: "0rem 0rem 5rem" }}
              className="text-list-footer"
            >
              <Grid.Column
                computer={4}
                tablet={6}
                mobile={8}
                style={{ paddingBottom: "2rem" }}
              >
                <Responsive maxWidth={769}>
                  <div style={{ paddingBottom: "1rem" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {footerlan.contact[this.props.currentlanguage]}
                    </label>
                  </div>
                </Responsive>
                <div
                  dangerouslySetInnerHTML={{
                    __html: GetRichTextFromContent(
                      this.sectionContent,
                      "contact",
                      this.props.currentlanguage
                    ),
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={5} mobile={8}>
                <Responsive maxWidth={769}>
                  <div style={{ paddingBottom: "1rem" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {footerlan.investors[this.props.currentlanguage]}
                    </label>
                  </div>
                </Responsive>
                <div
                  dangerouslySetInnerHTML={{
                    __html: GetRichTextFromContent(
                      this.sectionContent,
                      "investors",
                      this.props.currentlanguage
                    ),
                  }}
                />
              </Grid.Column>
              <Grid.Column
                computer={2}
                tablet={5}
                mobile={8}
                style={{ paddingBottom: "2rem" }}
              >
                <Responsive maxWidth={769}>
                  <div style={{ paddingBottom: "1rem" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {footerlan.clients[this.props.currentlanguage]}
                    </label>
                  </div>
                </Responsive>
                <div
                  dangerouslySetInnerHTML={{
                    __html: GetRichTextFromContent(
                      this.sectionContent,
                      "clients",
                      this.props.currentlanguage
                    ),
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={3} tablet={6} mobile={8}>
                <Responsive maxWidth={769}>
                  <div style={{ paddingBottom: "1rem" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {footerlan.jobBank[this.props.currentlanguage]}
                    </label>
                  </div>
                </Responsive>
                <div
                  dangerouslySetInnerHTML={{
                    __html: GetRichTextFromContent(
                      this.sectionContent,
                      "jobs",
                      this.props.currentlanguage
                    ),
                  }}
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={8}>
                <Responsive maxWidth={769}>
                  <div style={{ paddingBottom: "1rem" }}>
                    <label
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {footerlan.shopping[this.props.currentlanguage]}
                    </label>
                  </div>
                </Responsive>
                <div
                  dangerouslySetInnerHTML={{
                    __html: GetRichTextFromContent(
                      this.sectionContent,
                      "shopping",
                      this.props.currentlanguage
                    ),
                  }}
                />
                <Responsive maxWidth={2560} minWidth={769}>
                  <div style={{ paddingTop: "2rem" }}>
                    <Image
                      style={{
                        width: "152px",
                      }}
                      src={esrnew}
                    />
                  </div>
                </Responsive>
              </Grid.Column>
              <Grid.Column computer={0} tablet={5} mobile={8}>
                <Responsive maxWidth={768}>
                  <div style={{ paddingTop: "2rem" }}>
                    <Image
                      style={{
                        width: "152px",
                      }}
                      src={esrnew}
                    />
                  </div>
                </Responsive>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Divider />
        <Container>
          <Grid stackable columns={4} style={{ padding: "2rem 0rem 0rem" }}>
            <Grid.Column width={6} verticalAlign="middle">
              <label style={{ color: "grey" }}>
                {footerlan.copyright[this.props.currentlanguage]}
              </label>
            </Grid.Column>
            <Grid.Column width={1} verticalAlign="middle" style={{}} />
            <Grid.Column
              width={7}
              textAlign="right"
              verticalAlign="middle"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <p
                style={{
                  color: "#5886A6",
                  cursor: "pointer",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  this.props.push("/buzon");
                  window.scroll({
                    top: document.getElementById("nav").offsetTop,
                    behavior: "auto",
                  });
                }}
              >
                {headertitle.buzon[this.props.currentlanguage]} I
              </p>
              <p
                style={{
                  color: "#5886A6",
                  cursor: "pointer",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  this.props.push("/privacidad");
                  window.scroll({
                    top: document.getElementById("nav").offsetTop,
                    behavior: "auto",
                  });
                }}
              >
                {headertitle.privacy[this.props.currentlanguage]} I
              </p>
              <p
                style={{ color: "#5886A6", cursor: "pointer" }}
                onClick={() => {
                  this.props.push("/responsable");
                  window.scroll({
                    top: document.getElementById("nav").offsetTop,
                    behavior: "auto",
                  });
                }}
              >
                {headertitle.responsable[this.props.currentlanguage]}
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    push: (to) => {
      dispatch(push(to));
    },
  };
};

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer)
);
