import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { sustentabilidad } from "../../assets/Language";
import {
  GetContent,
  GetImageFromContent,
  GetLinkFromContent,
  GetPage
} from "../../scripts";

class FundacionAra extends Component {
  constructor(props) {
    super(props);
    this.mainInfo = {};
    this.mainImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "sustainability").id;
      api.getContentOf(pageId).then(response => {
        this.mainInfo = GetContent(response[0], "foundation", "main");
        api.getImageOf(pageId).then(responseImage => {
          this.mainImage = GetContent(responseImage[0], "foundation", "main");
          this.forceUpdate();
        });
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {sustentabilidad.fundacion[this.props.currentlanguage]}
          </h1>

          <h3>{sustentabilidad.label[this.props.currentlanguage]}</h3>
          <Image
            target={"_blank"}
            href={GetLinkFromContent(
              this.mainInfo,
              "link",
              this.props.currentlanguage
            )}
            src={GetImageFromContent(
              this.mainImage,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {sustentabilidad.fundacion[this.props.currentlanguage]}
          </h1>

          <h3>{sustentabilidad.label[this.props.currentlanguage]}</h3>
          <Image
            target={"_blank"}
            href={GetLinkFromContent(
              this.mainInfo,
              "link",
              this.props.currentlanguage
            )}
            src={GetImageFromContent(
              this.mainImage,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {sustentabilidad.fundacion[this.props.currentlanguage]}
          </h1>

          <h3>{sustentabilidad.label[this.props.currentlanguage]}</h3>

          <Image
            target={"_blank"}
            href={GetLinkFromContent(
              this.mainInfo,
              "link",
              this.props.currentlanguage
            )}
            src={GetImageFromContent(
              this.mainImage,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(FundacionAra)
);
