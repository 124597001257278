import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { bursatil } from "../../assets/Language";
import { GetArrayALike, GetPage, GetTextFromContent } from "../../scripts";

const analyst = [
  {
    id: 1,
    institute: "Actinver",
    analyst: "Ramón Ortiz",
    email: "rortiz@actinver.com"
  },
  {
    id: 2,
    institute: "Barclays",
    analyst: "Pablo Monsivais",
    email: "pablo.monsivais@barclays.com"
  },
  {
    id: 3,
    institute: "Actinver",
    analyst: "Ramón Ortiz",
    email: "rortiz@activner.com"
  },
  {
    id: 4,
    institute: "BTG Pactual ",
    analyst: "Gordon Lee",
    email: "gordon.lee@btgpactual.com"
  },
  {
    id: 5,
    institute: "GBM",
    analyst: "Javier Gayo",
    email: "jgayol@gbm.com.mx"
  }
];

class Analistas extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.array = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "stockInfo").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "analysts", "analist");
        this.array.sort(function(a, b){
          if(a.publicName < b.publicName) { return -1; }
          if(a.publicName > b.publicName) { return 1; }
          return 0;
      })

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {bursatil.analistas[this.props.currentlanguage]}
          </h1>
          <Grid divided="vertically" style={{ padding: "3rem 0rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={4} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {bursatil.compañia[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={8}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {bursatil.analista[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={4}>
                <h2 style={{ fontSize: "1.5rem" }}>E-mail</h2>
              </Grid.Column>
            </Grid.Row>
            {this.array.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={4} style={{ paddingLeft: "2rem" }}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "institution",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "name",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <a>
                      {GetTextFromContent(
                        item,
                        "email",
                        this.props.currentlanguage
                      )}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {bursatil.analistas[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ paddingTop: "3rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={4} style={{ paddingLeft: "3rem" }}>
                <h2>{bursatil.compañia[this.props.currentlanguage]}</h2>
              </Grid.Column>
              <Grid.Column width={6}>
                <h2>{bursatil.analista[this.props.currentlanguage]}</h2>
              </Grid.Column>
              <Grid.Column width={6}>
                <h2>E-mail</h2>
              </Grid.Column>
            </Grid.Row>
            {this.array.map(item => {
              return (
                <Grid.Row>
                  <Grid.Column width={4} style={{ paddingLeft: "3rem" }}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "institution",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "name",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <a>
                      {GetTextFromContent(
                        item,
                        "email",
                        this.props.currentlanguage
                      )}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {bursatil.analistas[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ paddingTop: "3rem" }}>
            <Grid.Row columns={3}>
              <Grid.Column width={5} style={{}}>
                <h2 style={{ fontSize: "1.3rem" }}>
                  {bursatil.compañia[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={6}>
                <h2 style={{ fontSize: "1.3rem" }}>
                  {bursatil.analista[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={5}>
                <h2 style={{ fontSize: "1.3rem" }}>E-mail</h2>
              </Grid.Column>
            </Grid.Row>
            {this.array.map(item => {
              return (
                <Grid.Row>
                  <Grid.Column width={5} style={{ paddingLeft: "3rem" }}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "institution",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <label style={{ color: "gray" }}>
                      {GetTextFromContent(
                        item,
                        "name",
                        this.props.currentlanguage
                      )}
                    </label>
                  </Grid.Column>
                  <Grid.Column style={{ wordWrap: "break-word" }} width={5}>
                    <a>
                      {GetTextFromContent(
                        item,
                        "email",
                        this.props.currentlanguage
                      )}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Analistas)
);
