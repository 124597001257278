import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { consorcioara } from "../../assets/Language";
import {
  GetContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent,
  GetRichTextFromContent
} from "../../scripts";

class EstructuraAccionaria extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(
          response[0],
          "shareholdingStructure",
          "main"
        );

        api.getImageOf(pageId).then(responseImages => {
          this.sectionImages = GetContent(
            responseImages[0],
            "shareholdingStructure",
            "main"
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "4rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.estructura[this.props.currentlanguage]}
          </h1>
          <p>
            {GetRichTextFromContent(
              this.sectionContent,
              "text1",
              this.props.currentlanguage
            )}
          </p>
          <Image
            size="large"
            src={GetImageFromContent(
              this.sectionImages,
              "img1",
              this.props.currentlanguage
            )}
            centered
          />
          <p>
            {GetRichTextFromContent(
              this.sectionContent,
              "text2",
              this.props.currentlanguage
            )}
          </p>
          <Image
            size="large"
            src={GetImageFromContent(
              this.sectionImages,
              "img2",
              this.props.currentlanguage
            )}
            centered
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <p>
              {consorcioara.source[this.props.currentlanguage]}
              <label style={{ paddingLeft: "0.5rem" }}>
                {GetTextFromContent(
                  this.sectionContent,
                  "source",
                  this.props.currentlanguage
                )}
              </label>
            </p>
          </div>
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(EstructuraAccionaria)
);
