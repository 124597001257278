import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { consorcioara } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import {
  GetContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent
} from "../../scripts";

class PresenciaGeografica extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(
          response[0],
          "geographicPresence",
          "main"
        );

        // 

        api.getImageOf(pageId).then(responseImages => {
          this.sectionImages = GetContent(
            responseImages[0],
            "geographicPresence",
            "main"
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.presenciageo[this.props.currentlanguage]}
          </h1>
          <Comptitle
            simple
            title={GetTextFromContent(
              this.sectionContent,
              "title",
              this.props.currentlanguage
            )}
            body={GetTextFromContent(
              this.sectionContent,
              "subtitle",
              this.props.currentlanguage
            )}
            style={{ paddingBottom: "3rem" }}
          />
          <Image
            size="massive"
            centered
            src={GetImageFromContent(
              this.sectionImages,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.presenciageo[this.props.currentlanguage]}
          </h1>
          <Comptitle
            simple
            title={GetTextFromContent(
              this.sectionContent,
              "title",
              this.props.currentlanguage
            )}
            body={GetTextFromContent(
              this.sectionContent,
              "subtitle",
              this.props.currentlanguage
            )}
            style={{ paddingBottom: "3rem" }}
          />
          <Image
            size="massive"
            centered
            src={GetImageFromContent(
              this.sectionImages,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem 4rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {consorcioara.presenciageo[this.props.currentlanguage]}
          </h1>
          <Comptitle
            simple
            title={GetTextFromContent(
              this.sectionContent,
              "title",
              this.props.currentlanguage
            )}
            body={GetTextFromContent(
              this.sectionContent,
              "subtitle",
              this.props.currentlanguage
            )}
            style={{ paddingBottom: "3rem" }}
          />
          <Image
            size="massive"
            centered
            src={GetImageFromContent(
              this.sectionImages,
              "img",
              this.props.currentlanguage
            )}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(PresenciaGeografica)
);
