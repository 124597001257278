import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Image, Responsive, Segment } from "semantic-ui-react";
import API from "../../API/API";
import { bursatil } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import {
  GetContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent,
  GetRichTextFromContent
} from "../../scripts";

class Dividendos extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "stockInfo").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "dividends", "main");

        api.getImageOf(pageId).then(responseImages => {
          this.sectionImages = GetContent(
            responseImages[0],
            "dividends",
            "main"
          );
          this.forceUpdate();
        });
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <Comptitle
            simple
            title={bursatil.dividendos[this.props.currentlanguage]}
            body={GetTextFromContent(
              this.sectionContent,
              "body",
              this.props.currentlanguage
            )}
            titlesize="2.5rem"
            titlecolor="#014078"
            marginTitle="0 0 1rem"
            titlebold
          />
        </Container>
        <div style={{ backgroundColor: "#F2F5F7", margin: "5rem 0rem" }}>
          <Container>
            <Grid style={{ padding: "3rem 0rem" }} columns={2}>
              <Grid.Column width={4}>
                <Segment
                  style={{
                    boxShadow: "0px 30px 103px #B7B6B8",
                    border: 0,
                    width: "18rem",
                    height: "16rem"
                  }}
                >
                  <div style={{ padding: "2rem" }}>
                    <p style={{ color: "grey", fontSize: "1.6rem" }}>
                      {bursatil.info[this.props.currentlanguage]}{" "}
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        {bursatil.info2[this.props.currentlanguage]}{" "}
                        {GetTextFromContent(
                          this.sectionContent,
                          "total",
                          this.props.currentlanguage
                        )}{" "}
                        {bursatil.info3[this.props.currentlanguage]}
                      </label>
                    </p>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column width={12}>
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        this.sectionImages,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                    height: "16rem"
                  }}
                />
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <Container>
          <p style={{ color: "gray" }}>
            {GetTextFromContent(
              this.sectionContent,
              "footer",
              this.props.currentlanguage
            )}
          </p>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <Comptitle
            simple
            title={bursatil.dividendos[this.props.currentlanguage]}
            body={GetRichTextFromContent(
              this.sectionContent,
              "body",
              this.props.currentlanguage
            )}
            titlesize="2.5rem"
            titlecolor="#014078"
            titlebold
          />
        </Container>
        <div style={{ backgroundColor: "#F2F5F7", margin: "5rem 0rem 2rem" }}>
          <Container>
            <Grid style={{ padding: "3rem 0rem" }} columns={2}>
              <Grid.Row style={{ justifyContent: "center", display: "flex" }}>
                <Segment
                  style={{
                    boxShadow: "0px 30px 103px #B7B6B8",
                    border: 0,
                    width: "18rem"
                  }}
                >
                  <div style={{ padding: "1rem 2rem" }}>
                    <p style={{ color: "grey", fontSize: "1.6rem" }}>
                      {bursatil.info[this.props.currentlanguage]}{" "}
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        {bursatil.info2[this.props.currentlanguage]}{" "}
                        {GetTextFromContent(
                          this.sectionContent,
                          "total",
                          this.props.currentlanguage
                        )}{" "}
                        {bursatil.info3[this.props.currentlanguage]}{" "}
                      </label>
                    </p>
                  </div>
                </Segment>
              </Grid.Row>
              <Grid.Row width={11} style={{ paddingTop: "4rem" }}>
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      GetImageFromContent(
                        this.sectionImages,
                        "img",
                        this.props.currentlanguage
                      ) +
                      ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                    height: "16rem"
                  }}
                />
              </Grid.Row>
            </Grid>
          </Container>
        </div>
        <Container>
          <p style={{ color: "gray" }}>
            {GetRichTextFromContent(
              this.sectionContent,
              "footer",
              this.props.currentlanguage
            )}
          </p>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <Comptitle
            simple
            title={bursatil.dividendos[this.props.currentlanguage]}
            body={GetRichTextFromContent(
              this.sectionContent,
              "body",
              this.props.currentlanguage
            )}
            titlesize="2.5rem"
            titlecolor="#014078"
            titlebold
          />
        </Container>
        <div style={{ backgroundColor: "#F2F5F7", margin: "5rem 0rem 3rem" }}>
          <Container>
            <Grid stackable style={{ padding: "3rem 0rem" }} columns={2}>
              <Grid.Column width={5}>
                <Segment
                  style={{
                    boxShadow: "0px 30px 103px #B7B6B8",
                    border: 0,
                    width: "18rem",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                >
                  <div style={{ padding: "1rem 2rem" }}>
                    <p style={{ color: "grey", fontSize: "1.6rem" }}>
                      {bursatil.info[this.props.currentlanguage]}{" "}
                      <label style={{ color: "black", fontWeight: "bold" }}>
                        {bursatil.info2[this.props.currentlanguage]}{" "}
                        {GetTextFromContent(
                          this.sectionContent,
                          "total",
                          this.props.currentlanguage
                        )}{" "}
                        {bursatil.info3[this.props.currentlanguage]}{" "}
                      </label>
                    </p>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column width={11}>
                <div style={{ paddingTop: "3rem" }}>
                  <Image
                    src={GetImageFromContent(
                      this.sectionImages,
                      "img",
                      this.props.currentlanguage
                    )}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        <Container>
          <p style={{ color: "gray" }}>
            {GetRichTextFromContent(
              this.sectionContent,
              "footer",
              this.props.currentlanguage
            )}
          </p>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Dividendos)
);
