import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container } from 'semantic-ui-react';
import API from '../API/API';
import { etica } from '../assets/Language';
import ColoredSegments from '../components/ColoredSegments';
import HeaderComp from '../components/HeaderComp';
import SubMenu from '../components/SubMenu';
import {
  GetContent,
  GetFileFromContent,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent,
} from '../scripts';
import '../style/globalstyle.css';

const submenu = [
  {
    id: 1,
    name: 'Línea Ética',
  },
];

const submenueng = [
  {
    id: 1,
    name: 'Ethical Line',
  },
];

class LineaEticaPage extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerImage = {};
    this.state = {
      currentArray: [],
    };
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'etica').id;
      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], 'header', 'main');
        this.forceUpdate();
      });

      api.getContentOf(pageId).then((response) => {
        this.sectionContent = GetContent(response[0], 'lineaEtica', 'main');

        this.forceUpdate();
      });
      api.getFilesOf(pageId).then((responseFiles) => {
        const filesCheck = GetContent(responseFiles[0], 'lineaEtica', 'main');
        const arrayFiles = [];
        let data = {};
        data.name = etica.codigo[this.props.currentlanguage];
        data.files = GetFileFromContent(
          filesCheck,
          'file',
          this.props.currentlanguage
        );
        data.bgcolor = '#74B1D4';
        arrayFiles.push(data);
        this.setState({ currentArray: arrayFiles });
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  render() {
    return (
      <div>
        <HeaderComp
          bgimages="etica"
          imageBackground={GetImageFromContent(
            this.headerImage,
            'img',
            this.props.currentlanguage
          )}
        />
        <SubMenu
          submenu={this.props.currentlanguage === '_es' ? submenu : submenueng}
        />
        <div style={{ padding: '5rem 0rem' }}>
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: GetRichTextFromContent(
                  this.sectionContent,
                  'txt1',
                  this.props.currentlanguage
                ),
              }}
            />

            <ColoredSegments
              segmentHeight="13rem"
              list={this.state.currentArray}
              singleFile={true}
              fileName={'file'}
            />
          </Container>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(LineaEticaPage)
);
