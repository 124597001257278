import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { corporativo } from "../../assets/Language";
import ModalGeneralInfo from "../../components/ModalGeneralInfo";
import {
  GetContent,
  GetPage,
  GetRichTextFromContent,
  GetSlider,
  GetTextFromContent
} from "../../scripts";

class EquipoDirectivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      currentSelected: {}
    };
    this.verifyPages = this.verifyPages.bind(this);
    this.topInfoContent = {};
    this.arrayDirectives = [];
    this.arrayRealStates = [];
    this.arrayCorporate = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;
      api.getContentOf(pageId).then(response => {
        this.topInfoContent = GetContent(response[0], "managment", "topInfo");
        if (this.arrayDirectives.length === 0) {
          const tempAdmins = GetSlider(response[0], "managment", "directive");
          for (let i = 0; i < tempAdmins.length; i++) {
            const currentAdmin = tempAdmins[i];
            const addAdmin = {
              id: i,
              name: GetTextFromContent(
                currentAdmin,
                "name",
                this.props.currentlanguage
              ),
              cargo: GetTextFromContent(
                currentAdmin,
                "position",
                this.props.currentlanguage
              ),
              body: GetRichTextFromContent(
                currentAdmin,
                "body",
                this.props.currentlanguage
              )
            };
            this.arrayDirectives.push(addAdmin);
          }
        }
        if (this.arrayRealStates.length === 0) {
          const tempAudit = GetSlider(response[0], "managment", "realState");
          for (let i = 0; i < tempAudit.length; i++) {
            const currentAudit = tempAudit[i];
            const addAudit = {
              id: i,
              name: GetTextFromContent(
                currentAudit,
                "name",
                this.props.currentlanguage
              ),
              cargo: GetTextFromContent(
                currentAudit,
                "position",
                this.props.currentlanguage
              ),
              body: GetRichTextFromContent(
                currentAudit,
                "body",
                this.props.currentlanguage
              )
            };
            this.arrayRealStates.push(addAudit);
          }
        }
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {corporativo.equipo[this.props.currentlanguage]}
          </h1>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.equipo[this.props.currentlanguage]}
          </h1>
          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayDirectives.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.divisionlist[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayRealStates.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {corporativo.equipo[this.props.currentlanguage]}
          </h1>
          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayDirectives.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.divisionlist[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayRealStates.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {corporativo.equipo[this.props.currentlanguage]}
          </h1>
          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayDirectives.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>

          <h1 style={{ padding: "1rem 0rem", fontSize: "1.5rem" }}>
            {corporativo.divisionlist[this.props.currentlanguage]}
          </h1>

          <Grid divided="vertically" style={{ padding: "2rem 0rem" }}>
            <Grid.Row columns={2}>
              <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.nombre[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
              <Grid.Column width={10}>
                <h2 style={{ fontSize: "1.5rem" }}>
                  {corporativo.posicion[this.props.currentlanguage]}
                </h2>
              </Grid.Column>
            </Grid.Row>
            {this.arrayRealStates.map(item => {
              return (
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={6} style={{ paddingLeft: "2rem" }}>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          currentSelected: item,
                          showModal: true
                        });
                      }}
                    >
                      {item.name}
                    </a>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <label style={{}}>{item.cargo}</label>
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
        <ModalGeneralInfo
          showModal={this.state.showModal}
          closeModal={() => {
            this.setState({ showModal: false });
          }}
          title={this.state.currentSelected.name}
          body={this.state.currentSelected.body}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(EquipoDirectivo)
);
