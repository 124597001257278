import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { GetPage } from "../scripts";
import API from "../API/API";

class MetaTagComp extends React.Component {
  constructor(props) {
    super(props);
    this.dickref = React.createRef();
    this.MetaTags = [];
    this.verifyPages = this.verifyPages.bind(this);
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const currentPage = GetPage(pages, this.props.section);
      this.dickapi = new API();
      this.dickapi.getContentOf(currentPage.id).then(response => {
        this.MetaTags = response[0].metaTags;
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderMetaTags() {
    return (
      <MetaTags>
        {this.MetaTags.map((tag, index) => {
          if (tag.name === "title") {
            return <title key={index}>{tag.content}</title>;
          } else if (tag.isProperty === true) {
            return (
              <meta key={index} property={tag.name} content={tag.content} />
            );
          } else {
            return <meta key={index} name={tag.name} content={tag.content} />;
          }
        })}
      </MetaTags>
    );
  }

  render() {
    return (
      <div>
        <div className="wrapper">{this.renderMetaTags()}</div>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages } = data;
  return {
    pages
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(MetaTagComp)
);
