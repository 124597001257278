import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Responsive, Segment } from "semantic-ui-react";
import API from "../../API/API";
import {
  imgfort1,
  imgfort2,
  imgfort3,
  imgfort4,
  imgfort5,
  imgfort6,
  imgfort7
} from "../../assets/Assets";
import { consorcioara } from "../../assets/Language";
import {
  GetArrayALike,
  GetImageFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayImage
} from "../../scripts";

const fortalezasara = [
  { id: 1, icon: imgfort1, name: "RESERVA TERRITORIAL ESTRATÉGICA" },
  { id: 2, icon: imgfort2, name: "SOLIDEZ FINANCIERA" },
  {
    id: 3,
    icon: imgfort3,
    name: "DIVERSIFICACIÓN GEOGRÁFICA Y DE PRODUCTO"
  },
  { id: 4, icon: imgfort4, name: "INTEGRACIÓN VERTICAL" },
  { id: 5, icon: imgfort5, name: "PROCESOS DE CONSTRUCCIÓN FLEXIBLE" },
  {
    id: 6,
    icon: imgfort6,
    name: "EQUIPO DIRECTIVO Y COLABORADORES CON GRAN EXPERIENCIA"
  },
  { id: 7, icon: imgfort7, name: "GOBIERNO CORPORATIVO ROBUSTO" }
];

class Fortalezasara extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.array = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "strengths", "strengths");

        

        api.getImageOf(pageId).then(responseImages => {
          
          this.array = UpdateArrayImage(
            responseImages[0],
            "strengths",
            this.array
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: "3rem 0rem 6rem", backgroundColor: "#F2F5F7" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.fortalezas[this.props.currentlanguage]}
          </h1>
          <Grid centered columns={5} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column
                  key={index}
                  verticalAlign="middle"
                  style={{ padding: 0 }}
                >
                  <Segment
                    style={{
                      height: "254px",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "white",
                      justifyContent: "center",
                      border: "0.5px solid #E2E2E4",
                      boxShadow: "0px 0px 0px"
                    }}
                    placeholder
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        paddingTop: "12%"
                      }}
                    >
                      <div style={{ height: "71px", width: "71px" }}>
                        <div
                          style={{
                            backgroundImage:
                              "url(" +
                              GetImageFromContent(
                                item,
                                "img",
                                this.props.currentlanguage
                              ) +
                              ")",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "5rem"
                          }}
                        />
                      </div>
                      <div style={{ height: "2rem" }} />
                      <div style={{ height: "71px", width: "100%" }}>
                        <label style={{ fontSize: "0.9rem" }}>
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </div>
                  </Segment>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "4rem 0rem 6rem", backgroundColor: "#F2F5F7" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.fortalezas[this.props.currentlanguage]}
          </h1>
          <Grid centered columns={5} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column
                  key={index}
                  verticalAlign="middle"
                  style={{ padding: 0, height: "13rem" }}
                >
                  <Segment
                    style={{
                      height: "13rem",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "white",
                      justifyContent: "center",
                      border: "0.5px solid #E2E2E4",
                      boxShadow: "0px 0px 0px"
                    }}
                    placeholder
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        paddingTop: "12%"
                      }}
                    >
                      <div style={{ height: "71px", width: "71px" }}>
                        <div
                          style={{
                            backgroundImage:
                              "url(" +
                              GetImageFromContent(
                                item,
                                "img",
                                this.props.currentlanguage
                              ) +
                              ")",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                      <div style={{ height: "2rem" }} />
                      <div style={{ height: "71px", width: "100%" }}>
                        <label style={{ fontSize: "0.9rem" }}>
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </div>
                  </Segment>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem 4rem", backgroundColor: "#F2F5F7" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.fortalezas[this.props.currentlanguage]}
          </h1>
          <Grid centered columns={2} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column
                  key={index}
                  verticalAlign="middle"
                  style={{ padding: 0, height: "13rem" }}
                >
                  <Segment
                    style={{
                      height: "13rem",
                      display: "flex",
                      borderRadius: 0,
                      backgroundColor: "white",
                      justifyContent: "center",
                      border: "0.5px solid #E2E2E4",
                      boxShadow: "0px 0px 0px"
                    }}
                    placeholder
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        paddingTop: "12%"
                      }}
                    >
                      <div style={{ height: "71px", width: "71px" }}>
                        <div
                          style={{
                            backgroundImage:
                              "url(" +
                              GetImageFromContent(
                                item,
                                "img",
                                this.props.currentlanguage
                              ) +
                              ")",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "100%",
                            height: "100%"
                          }}
                        />
                      </div>
                      <div style={{ height: "2rem" }} />
                      <div style={{ height: "71px", width: "100%" }}>
                        <label style={{ fontSize: "0.9rem" }}>
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </div>
                  </Segment>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Fortalezasara)
);
