import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { corporativo } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import {
  GetArrayALike,
  GetFileFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile
} from "../../scripts";

const descargas = [
  {
    id: 1,
    name: "Compulsa de Estatutos 2012",
    bgcolor: "#74B1D4",
    disabled: false
  },
  {
    id: 2,
    name: "Certificación Compulsa de Estatutos 2006",
    bgcolor: "#74B1D4",
    disabled: false
  },
  {
    id: 3,
    name: "Compulsa de estatutos 2006",
    bgcolor: "#74B1D4",
    disabled: false
  },
  {
    id: 4,
    name: "Compulsa de Estatutos 2003",
    bgcolor: "#74B1D4",
    disabled: false
  }
];

class Estatutos extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.array = [];
    this.currentArray = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "statutes", "file");

        api.getFilesOf(pageId).then(responseFiles => {
          this.array = UpdateArrayFile(
            responseFiles[0],
            "statutes",
            this.array
          );
          this.currentArray = [];
          for (let i = 0; i < this.array.length; i++) {
            const currentFile = this.array[i];
            ;
            let data = {};
            data.id = currentFile.value;
            data.name = GetTextFromContent(
              currentFile,
              "title",
              this.props.currentlanguage
            );
            data.files = GetFileFromContent(
              currentFile,
              "file",
              this.props.currentlanguage
            );
            data.bgcolor = "#74B1D4";
            this.currentArray.push(data);
          }
          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  bubbleSort = arr => {
    const l = arr.length;
    for (let i = 0; i < l; i++) {
      for (let j = 0; j < l - 1 - i; j++) {
        if (arr[j].id > arr[j + 1].id) {
          [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
        }
      }
    }

    return arr;
  };

  renderInDesktop() {
    this.bubbleSort(this.currentArray);

    return (
      <div style={{ padding: " 5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {corporativo.estatutos[this.props.currentlanguage]}
          </h1>

          <ColoredSegments
            segmentHeight="14rem"
            list={this.currentArray}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Estatutos)
);
