import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { corporativo } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import YearSelector from "../../components/YearSelector";
import {
  GetPage,
  GetSlider,
  GetTextFromContent,
  UpdateArrayFile
} from "../../scripts";

const convocatorias = [
  {
    id: 1,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2018",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 2,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2017",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 3,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2016",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 4,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2015",
    bgcolor: "#8CC366",
    disabled: true
  }
];

const propuestas = [
  {
    id: 1,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2018",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 2,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2017",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 3,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2016",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 4,
    name: "Asamblea General Ordinaria de Accionistas 24 Abril 2015",
    bgcolor: "#79bee9",
    disabled: true
  }
];

class AsambleaAccionistas extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.arrayCalls = [];
    this.currentCallValue = {};
    this.currentCallInfo = {
      dataArray: [],
      dataFiles: []
    };

    this.arrayProposals = [];
    this.currentProposalValue = {};
    this.currentProposalInfo = {
      dataArray: [],
      dataFiles: []
    };

    this.arrayAgreements = [];
    this.currentAgreementValue = {};
    this.currentAgreementInfo = {
      dataArray: [],
      dataFiles: []
    };

    this.arrayNotices = [];
    this.currentNoticeValue = {};
    this.currentNoticeInfo = {
      dataArray: [],
      dataFiles: []
    };

    this.arrayAudits = [];
    this.currentAuditValue = {};
    this.currentAuditInfo = {
      dataArray: [],
      dataFiles: []
    };
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "corporate").id;

      api.getContentOf(pageId).then(response => {
        api.getFilesOf(pageId).then(responseFiles => {
          let tempArrayCalls = GetSlider(response[0], "investors", "calls");
          let tempArrayProposals = GetSlider(
            response[0],
            "investors",
            "proposals"
          );
          let tempArrayAgreements = GetSlider(
            response[0],
            "investors",
            "agreement"
          );
          let tempArrayNotices = GetSlider(response[0], "investors", "notice");
          let tempArrayAudits = GetSlider(response[0], "investors", "auditors");

          tempArrayCalls = UpdateArrayFile(
            responseFiles[0],
            "investors",
            tempArrayCalls
          );
          if (this.arrayCalls.length === 0) {
            for (let i = 0; i < tempArrayCalls.length; i++) {
              const currentYear = tempArrayCalls[i];

              const dataContentFiles = currentYear.dataContentFiles;
              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                "year",
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.dataArray = [];
              dataset.dataFiles = dataContentFiles.filter(file => {
                return file.name.includes("file");
              });
              for (let d = 0; d < 3; d++) {
                const dataAdd = {
                  id: d,
                  name: GetTextFromContent(
                    currentYear,
                    "name" + (d + 1).toString()
                  ),
                  bgcolor: "#74B1D4"
                };
                dataset.dataArray.push(dataAdd);
              }
              selector.data = dataset;
              if (selector.text) {
                this.arrayCalls.unshift(selector);
              }
            }
            this.currentCallValue = this.arrayCalls[0].value;
            this.currentCallInfo = this.arrayCalls[0].data;
          }

          tempArrayProposals = UpdateArrayFile(
            responseFiles[0],
            "investors",
            tempArrayProposals
          );
          if (this.arrayProposals.length === 0) {
            for (let i = 0; i < tempArrayProposals.length; i++) {
              const currentYear = tempArrayProposals[i];

              const dataContentFiles = currentYear.dataContentFiles;
              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                "year",
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.dataArray = [];
              dataset.dataFiles = dataContentFiles.filter(file => {
                return file.name.includes("file");
              });
              for (let d = 0; d < 3; d++) {
                const dataAdd = {
                  id: d,
                  name: GetTextFromContent(
                    currentYear,
                    "name" + (d + 1).toString(),
                    this.props.currentlanguage
                  ),
                  bgcolor: "#74B1D4"
                };
                dataset.dataArray.push(dataAdd);
              }
              selector.data = dataset;
              if (selector.text) {
                this.arrayProposals.unshift(selector);
              }
            }
            this.currentProposalValue = this.arrayProposals[0].value;
            this.currentProposalInfo = this.arrayProposals[0].data;
          }

          tempArrayAgreements = UpdateArrayFile(
            responseFiles[0],
            "investors",
            tempArrayAgreements
          );
          if (this.arrayAgreements.length === 0) {
            for (let i = 0; i < tempArrayAgreements.length; i++) {
              const currentYear = tempArrayAgreements[i];

              const dataContentFiles = currentYear.dataContentFiles;
              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                "year",
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.dataArray = [];
              dataset.dataFiles = dataContentFiles.filter(file => {
                return file.name.includes("file");
              });
              for (let d = 0; d < 3; d++) {
                const dataAdd = {
                  id: d,
                  name: GetTextFromContent(
                    currentYear,
                    "name" + (d + 1).toString()
                  ),
                  bgcolor: "#74B1D4"
                };
                dataset.dataArray.push(dataAdd);
              }
              selector.data = dataset;
              if (selector.text) {
                this.arrayAgreements.unshift(selector);
              }
            }
            this.currentAgreementValue = this.arrayAgreements[0].value;
            this.currentAgreementInfo = this.arrayAgreements[0].data;
          }

          tempArrayNotices = UpdateArrayFile(
            responseFiles[0],
            "investors",
            tempArrayNotices
          );
          if (this.arrayNotices.length === 0) {
            for (let i = 0; i < tempArrayNotices.length; i++) {
              const currentYear = tempArrayNotices[i];

              const dataContentFiles = currentYear.dataContentFiles;
              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                "year",
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.dataArray = [];
              dataset.dataFiles = dataContentFiles.filter(file => {
                return file.name.includes("file");
              });
              for (let d = 0; d < 3; d++) {
                const dataAdd = {
                  id: d,
                  name: GetTextFromContent(
                    currentYear,
                    "name" + (d + 1).toString(),
                    this.props.currentlanguage
                  ),
                  bgcolor: "#74B1D4"
                };
                dataset.dataArray.push(dataAdd);
              }
              selector.data = dataset;
              if (selector.text) {
                this.arrayNotices.unshift(selector);
              }
            }
            this.currentNoticeValue = this.arrayNotices[0].value;
            this.currentNoticeInfo = this.arrayNotices[0].data;
          }

          tempArrayAudits = UpdateArrayFile(
            responseFiles[0],
            "investors",
            tempArrayAudits
          );
          if (this.arrayAudits.length === 0) {
            for (let i = 0; i < tempArrayAudits.length; i++) {
              const currentYear = tempArrayAudits[i];

              const dataContentFiles = currentYear.dataContentFiles;
              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                "year",
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.dataArray = [];
              dataset.dataFiles = dataContentFiles.filter(file => {
                return file.name.includes("file");
              });
              for (let d = 0; d < 3; d++) {
                const dataAdd = {
                  id: d,
                  name: GetTextFromContent(
                    currentYear,
                    "name" + (d + 1).toString(),
                    this.props.currentlanguage
                  ),
                  bgcolor: "#74B1D4"
                };
                dataset.dataArray.push(dataAdd);
              }
              selector.data = dataset;
              if (selector.text) {
                this.arrayAudits.unshift(selector);
              }
            }
            this.currentAuditValue = this.arrayAudits[0].value;
            this.currentAuditInfo = this.arrayAudits[0].data;
          }

          /*
          for (let i = 0; i < this.array.length; i++) {
            const currentFile = this.array[i];
            
            let data = {};
            data.name = GetTextFromContent(currentFile, "title");
            data.files = GetFileFromContent(currentFile, "file");
            data.bgcolor = "#8CC366";
            this.currentArrayFiles = [
              ...this.currentArrayFiles,
              ...currentFile.dataContentFiles.filter(file => {
                return file.name.includes("file");
              })
            ];
            this.currentArray.push(data);
            
            
          }
          */
          this.forceUpdate();

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {corporativo.asamblea[this.props.currentlanguage]}
          </h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.convocatorias[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayCalls}
              value={this.currentCallValue}
              returnYearSelected={returnData => {
                this.currentCallInfo = returnData.data;
                this.currentCallValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentCallInfo.dataArray}
            files={this.currentCallInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.propuestas[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayProposals}
              value={this.currentProposalValue}
              returnYearSelected={returnData => {
                this.currentProposalInfo = returnData.data;
                this.currentProposalValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentProposalInfo.dataArray}
            files={this.currentProposalInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.acuerdos[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayAgreements}
              value={this.currentAgreementValue}
              returnYearSelected={returnData => {
                this.currentAgreementInfo = returnData.data;
                this.currentAgreementValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAgreementInfo.dataArray}
            files={this.currentAgreementInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.aviso[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayNotices}
              value={this.currentNoticeValue}
              returnYearSelected={returnData => {
                this.currentNoticeInfo = returnData.data;
                this.currentNoticeValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentNoticeInfo.dataArray}
            files={this.currentNoticeInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.adicional[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayAudits}
              value={this.currentAuditValue}
              returnYearSelected={returnData => {
                this.currentAuditInfo = returnData.data;
                this.currentAuditValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAuditInfo.dataArray}
            files={this.currentAuditInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {corporativo.asamblea[this.props.currentlanguage]}
          </h1>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.convocatorias[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayCalls}
              value={this.currentCallValue}
              returnYearSelected={returnData => {
                this.currentCallInfo = returnData.data;
                this.currentCallValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentCallInfo.dataArray}
            files={this.currentCallInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.propuestas[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayProposals}
              value={this.currentProposalValue}
              returnYearSelected={returnData => {
                this.currentProposalInfo = returnData.data;
                this.currentProposalValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentProposalInfo.dataArray}
            files={this.currentProposalInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.acuerdos[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayAgreements}
              value={this.currentAgreementValue}
              returnYearSelected={returnData => {
                this.currentAgreementInfo = returnData.data;
                this.currentAgreementValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAgreementInfo.dataArray}
            files={this.currentAgreementInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.aviso[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayNotices}
              value={this.currentNoticeValue}
              returnYearSelected={returnData => {
                this.currentNoticeInfo = returnData.data;
                this.currentNoticeValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentNoticeInfo.dataArray}
            files={this.currentNoticeInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "1.6rem",
                fontWeight: "normal"
              }}
            >
              {corporativo.adicional[this.props.currentlanguage]}
            </p>
            <YearSelector
              options={this.arrayAudits}
              value={this.currentAuditValue}
              returnYearSelected={returnData => {
                this.currentAuditInfo = returnData.data;
                this.currentAuditValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAuditInfo.dataArray}
            files={this.currentAuditInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {corporativo.asamblea[this.props.currentlanguage]}
          </h1>
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "normal"
            }}
          >
            {corporativo.convocatorias[this.props.currentlanguage]}
          </p>
          <div style={{}}>
            <YearSelector
              options={this.arrayCalls}
              value={this.currentCallValue}
              returnYearSelected={returnData => {
                this.currentCallInfo = returnData.data;
                this.currentCallValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentCallInfo.dataArray}
            files={this.currentCallInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "normal"
            }}
          >
            {corporativo.propuestas[this.props.currentlanguage]}
          </p>
          <div style={{}}>
            <YearSelector
              options={this.arrayProposals}
              value={this.currentProposalValue}
              returnYearSelected={returnData => {
                this.currentProposalInfo = returnData.data;
                this.currentProposalValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentProposalInfo.dataArray}
            files={this.currentProposalInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "normal"
            }}
          >
            {corporativo.acuerdos[this.props.currentlanguage]}
          </p>
          <div style={{}}>
            <YearSelector
              options={this.arrayAgreements}
              value={this.currentAgreementValue}
              returnYearSelected={returnData => {
                this.currentAgreementInfo = returnData.data;
                this.currentAgreementValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAgreementInfo.dataArray}
            files={this.currentAgreementInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "normal"
            }}
          >
            {corporativo.aviso[this.props.currentlanguage]}
          </p>
          <div style={{}}>
            <YearSelector
              options={this.arrayNotices}
              value={this.currentNoticeValue}
              returnYearSelected={returnData => {
                this.currentNoticeInfo = returnData.data;
                this.currentNoticeValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentNoticeInfo.dataArray}
            files={this.currentNoticeInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />

          <p
            style={{
              fontSize: "1.6rem",
              fontWeight: "normal"
            }}
          >
            {corporativo.adicional[this.props.currentlanguage]}
          </p>
          <div style={{}}>
            <YearSelector
              options={this.arrayAudits}
              value={this.currentAuditValue}
              returnYearSelected={returnData => {
                this.currentAuditInfo = returnData.data;
                this.currentAuditValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <ColoredSegments
            segmentHeight="13rem"
            list={this.currentAuditInfo.dataArray}
            files={this.currentAuditInfo.dataFiles}
            fontSize="1rem"
            style={{ marginBottom: "4rem" }}
            fileName={"file"}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(AsambleaAccionistas)
);
