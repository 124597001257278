import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container } from 'semantic-ui-react';

import API from '../../API/API';
import {
  GetContent,
  GetImageFromContent,
  GetTextFromContent,
} from '../../scripts';

class Home1 extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.headerContent = {};
    this.headerImage = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = pages[0].id;
      api.getContentOf(pageId).then((response) => {
        this.headerContent = GetContent(response[0], 'header', 'main');
        this.forceUpdate();
      });

      api.getImageOf(pageId).then((responseImage) => {
        this.headerImage = GetContent(responseImage[0], 'header', 'main');
        this.forceUpdate();
      });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div>
        <div
          style={{
            backgroundImage:
              'url(' +
              GetImageFromContent(
                this.headerImage,
                'img',
                this.props.currentlanguage
              ) +
              ')',
          }}
          className='homepageimage'
        />
        {/* <div className="gradient" style={{ height: "50rem" }}> */}
        <Container className='header-container'>
          <div
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              width: '81%',
              textAlign: 'center',
              color: 'white',
            }}
          >
            <h1 className='header-title'>
              {GetTextFromContent(
                this.headerContent,
                'title',
                this.props.currentlanguage
              )}
            </h1>
          </div>
        </Container>
        {/* </div> */}
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Home1)
);
