import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Responsive
} from "semantic-ui-react";
import API from "../../API/API";
import { logo } from "../../assets/Assets";
import { financiera } from "../../assets/Language";
import {
  GetArrayALike,
  GetFileFromContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile,
  UpdateArrayImage
} from "../../scripts";

const presentaciones = [
  {
    id: 1,
    img: logo,
    text: "Emisiones de Certificados Bursátiles ARA 17 y ARA 17-2",
    file: "Presentación Roadshow"
  },
  { id: 2, img: logo, text: "", file: "Presentación Corporativa" },
  { id: 3, img: logo, text: "ARA Day", file: "Presentación Ara Day" }
];



const textosPresentaciones = [
  {_es:"Presentación Roadshow", _en:"Roadshow Presentation"}, 
  {_es:"Presentación Corporativa", _en:"Corporate Presentation"}, 
  {_es:"Reporte de Bonos Sustentables", _en:"Sustainable Bond Report"}, 
]
const getTextoPresentaciones = (index, idioma) => {
  if(index >= textosPresentaciones.length) return "File";
  return idioma == "_es" ? textosPresentaciones[index]._es : textosPresentaciones[index]._en
};

class PresentacionCorporativa extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = {};
    this.currentValue = "";
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "finacial").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(
          response[0],
          "corporativePresentation",
          "presentation"
        );

        

        api.getImageOf(pageId).then(responseImages => {
          this.array = UpdateArrayImage(
            responseImages[0],
            "corporativePresentation",
            this.array
          );
          
          api.getFilesOf(pageId).then(responseFiles => {
            
            this.array = UpdateArrayFile(
              responseFiles[0],
              "corporativePresentation",
              this.array
            );

            this.forceUpdate();
          });
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: " 5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {financiera.presentacion[this.props.currentlanguage]}
          </h1>

          <Grid centered columns={3} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column textAlign="center" key={index}>
                  <div style={{ backgroundColor: "#F2F5F7" }}>
                    <Grid.Row style={{ height: "10rem", paddingTop: "2rem" }}>
                      <Image
                        style={{ width: "15rem" }}
                        centered
                        src={GetImageFromContent(
                          item,
                          "img",
                          this.props.currentlanguage
                        )}
                      />
                    </Grid.Row>
                    <Grid.Row style={{ height: "10rem" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <label
                          style={{
                            width: "70%",
                            color: "gray",
                            fontSize: "1.1rem",
                            paddingTop: "4rem"
                          }}
                        >
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        height: "8rem",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Header
                        icon
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          paddingTop: "1rem"
                        }}
                        href={GetFileFromContent(
                          item,
                          "file",
                          this.props.currentlanguage
                        )}
                        target="_blank"
                      >
                        <Icon
                          style={{ fontSize: "2rem" }}
                          color="blue"
                          name="download"
                        />
                        <label style={{ fontSize: "1.5rem" }}>
                          {
                           getTextoPresentaciones(index, this.props.currentlanguage)
                           }
                        </label>
                        <br />
                        <label
                          style={{ fontSize: "0.9rem", fontWeight: "normal" }}
                        >
                          {GetTextFromContent(
                            item,
                            "fileDate",
                            this.props.currentlanguage
                          )}
                        </label>
                      </Header>
                    </Grid.Row>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: " 5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {financiera.presentacion[this.props.currentlanguage]}
          </h1>

          <Grid centered columns={3} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column textAlign="center">
                  <div style={{ backgroundColor: "#F2F5F7" }}>
                    <Grid.Row style={{ height: "10rem" }}>
                      <Image
                        style={{ width: "15rem" }}
                        centered
                        src={GetImageFromContent(
                          item,
                          "img",
                          this.props.currentlanguage
                        )}
                      />
                    </Grid.Row>
                    <Grid.Row style={{ height: "10rem", paddingTop: "3rem" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <label
                          style={{
                            width: "70%",
                            color: "gray",
                            fontSize: "1.1rem"
                          }}
                        >
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        height: "10rem",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Header
                        icon
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          paddingTop: "1rem"
                        }}
                        href={GetFileFromContent(
                          item,
                          "file",
                          this.props.currentlanguage
                        )}
                        target="_blank"
                      >
                        <Icon
                          style={{ fontSize: "2rem" }}
                          color="blue"
                          name="download"
                        />
                        <label style={{ fontSize: "1.5rem" }}>
                        {
                          getTextoPresentaciones(index, this.props.currentlanguage)
                        }
                        </label>
                        <br />
                        <label
                          style={{ fontSize: "0.9rem", fontWeight: "normal" }}
                        >
                          {GetTextFromContent(
                            item,
                            "fileDate",
                            this.props.currentlanguage
                          )}
                        </label>
                      </Header>
                    </Grid.Row>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2rem" }}>
            {financiera.presentacion[this.props.currentlanguage]}
          </h1>

          <Grid stackable centered columns={3} style={{ paddingTop: "2rem" }}>
            {this.array.map((item, index) => {
              return (
                <Grid.Column textAlign="center">
                  <div style={{ backgroundColor: "#F2F5F7" }}>
                    <Grid.Row style={{ height: "10rem", paddingTop: "1rem" }}>
                      <Image
                        style={{ width: "15rem" }}
                        centered
                        src={GetImageFromContent(
                          item,
                          "img",
                          this.props.currentlanguage
                        )}
                      />
                    </Grid.Row>
                    <Grid.Row style={{ padding: "3rem 0rem" }}>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center"
                        }}
                      >
                        <label
                          style={{
                            width: "70%",
                            color: "gray",
                            fontSize: "1.1rem"
                          }}
                        >
                          {GetTextFromContent(
                            item,
                            "title",
                            this.props.currentlanguage
                          )}
                        </label>
                      </div>
                    </Grid.Row>
                    <Grid.Row
                      style={{
                        height: "8rem",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Header
                        icon
                        style={{
                          backgroundColor: "white",
                          width: "90%",
                          paddingTop: "1rem"
                        }}
                        href={GetFileFromContent(
                          item,
                          "file",
                          this.props.currentlanguage
                        )}
                        target="_blank"
                      >
                        <Icon
                          style={{ fontSize: "2rem" }}
                          color="blue"
                          name="download"
                        />
                        <label style={{ fontSize: "1.5rem" }}>
                        {
                         getTextoPresentaciones(index, this.props.currentlanguage)
                        }
                        </label>
                        <br />
                        <label
                          style={{ fontSize: "0.9rem", fontWeight: "normal" }}
                        >
                          {GetTextFromContent(
                            item,
                            "fileDate",
                            this.props.currentlanguage
                          )}
                        </label>
                      </Header>
                    </Grid.Row>
                  </div>
                </Grid.Column>
              );
            })}
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(PresentacionCorporativa)
);
