import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive, Modal } from "semantic-ui-react";
import API from "../../API/API";
import { financiera } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import YearSelector from "../../components/YearSelector";
import {
  GetArrayALike,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile
} from "../../scripts";

const informes = [
  {
    id: 1,
    name: "Primer Trimestre",
    bgcolor: "#8CC366"
  },
  {
    id: 2,
    name: "Segundo Trimestre",
    bgcolor: "#8CC366"
  },
  {
    id: 3,
    name: "Tercer Trimestre",
    bgcolor: "#8CC366"
  },
  {
    id: 4,
    name: "Cuarto Trimestre",
    bgcolor: "#8CC366"
  }
];
const reportes = [
  {
    id: 1,
    name: "Primer Trimestre",
    bgcolor: "#53B692"
  },
  {
    id: 2,
    name: "Segundo Trimestre",
    bgcolor: "#53B692"
  },
  {
    id: 3,
    name: "Tercer Trimestre",
    bgcolor: "#53B692"
  },
  {
    id: 4,
    name: "Cuarto Trimestre",
    bgcolor: "#53B692"
  }
];
const conferencias = [
  {
    id: 1,
    name: "Primer Trimestre",
    bgcolor: "#5DC0CD"
  },
  {
    id: 2,
    name: "Segundo Trimestre",
    bgcolor: "#5DC0CD"
  },
  {
    id: 3,
    name: "Tercer Trimestre",
    bgcolor: "#5DC0CD"
  },
  {
    id: 4,
    name: "Cuarto Trimestre",
    bgcolor: "#5DC0CD"
  }
];
const conferenciasaudio = [
  {
    id: 1,
    name: "Primer Trimestre",
    bgcolor: "#74B1D4"
  },
  {
    id: 2,
    name: "Segundo Trimestre",
    bgcolor: "#74B1D4"
  },
  {
    id: 3,
    name: "Tercer Trimestre",
    bgcolor: "#74B1D4"
  },
  {
    id: 4,
    name: "Cuarto Trimestre",
    bgcolor: "#74B1D4"
  }
];
const informesen = [
  {
    id: 1,
    name: "First Quarter",
    bgcolor: "#8CC366"
  },
  {
    id: 2,
    name: "Second Quarter",
    bgcolor: "#8CC366"
  },
  {
    id: 3,
    name: "Third Quarter",
    bgcolor: "#8CC366"
  },
  {
    id: 4,
    name: "Fourth Quarter",
    bgcolor: "#8CC366"
  }
];
const reportesen = [
  {
    id: 1,
    name: "First Quarter",
    bgcolor: "#53B692"
  },
  {
    id: 2,
    name: "Second Quarter",
    bgcolor: "#53B692"
  },
  {
    id: 3,
    name: "Third Quarter",
    bgcolor: "#53B692"
  },
  {
    id: 4,
    name: "Fourth Quarter",
    bgcolor: "#53B692"
  }
];
const conferenciasen = [
  {
    id: 1,
    name: "First Quarter",
    bgcolor: "#5DC0CD"
  },
  {
    id: 2,
    name: "Second Quarter",
    bgcolor: "#5DC0CD"
  },
  {
    id: 3,
    name: "Third Quarter",
    bgcolor: "#5DC0CD"
  },
  {
    id: 4,
    name: "Fourth Quarter",
    bgcolor: "#5DC0CD"
  }
];
const conferenciasaudioen = [
  {
    id: 1,
    name: "First Quarter",
    bgcolor: "#74B1D4"
  },
  {
    id: 2,
    name: "Second Quarter",
    bgcolor: "#74B1D4"
  },
  {
    id: 3,
    name: "Third Quarter",
    bgcolor: "#74B1D4"
  },
  {
    id: 4,
    name: "Fourth Quarter",
    bgcolor: "#74B1D4"
  }
];

class ReportesTrimestrales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formModal: false
    };
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = {};
    this.currentValue = "";
    this.array = [];
    this.fileArray = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "finacial").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(
          response[0],
          "quarterlyReports",
          "quarterly"
        );

        api.getFilesOf(pageId).then(responseFiles => {
          this.array = UpdateArrayFile(
            responseFiles[0],
            "quarterlyReports",
            this.array
          );
          this.currentYears = [];

          for (let i = 0; i < this.array.length; i++) {
            const currentYear = this.array[i];
            const dataContentFiles = currentYear.dataContentFiles;
            let selector = {};
            selector.key = currentYear.id;
            selector.value = currentYear.id;
            selector.text = GetTextFromContent(
              currentYear,
              "year",
              this.props.currentlanguage
            );
            const dataset = {};
            dataset.id = currentYear.id;
            const allDirectors = dataContentFiles.filter(file => {
              return file.name.includes("director");
            });
            const allBmv = dataContentFiles.filter(file => {
              return file.name.includes("bmv");
            });
            const allConferences = dataContentFiles.filter(file => {
              return file.name.includes("conferences");
            });
            const allAudio = dataContentFiles.filter(file => {
              return file.name.includes("audio");
            });
            dataset.fileDirector = allDirectors;
            dataset.fileBmv = allBmv;
            dataset.fileConferences = allConferences;
            dataset.fileAudio = allAudio;
            selector.data = dataset;
            if (selector.text) {
              this.currentYears.unshift(selector);
            }
          }
          this.currentValue = this.currentYears[0].value;
          this.currentInfo = this.currentYears[0].data;
          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  close = () => this.setState({ formModal: false });

  renderModal() {
    return (
      <Modal open={this.state.formModal} closeIcon={true} onClose={this.close}>
        <label>hola</label>
      </Modal>
    );
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        {this.renderModal()}
        <Container>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
              {financiera.reportestri[this.props.currentlanguage]}
            </h1>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={returnData => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>

          <ColoredSegments
            title={financiera.director[this.props.currentlanguage]}
            list={this.props.currentlanguage === "_es" ? informes : informesen}
            fileName={"director"}
            files={this.currentInfo.fileDirector}
            style={{ paddingTop: "3rem" }}
          />
          <ColoredSegments
            title={financiera.bmv[this.props.currentlanguage]}
            list={this.props.currentlanguage === "_es" ? reportes : reportesen}
            fileName={"bmv"}
            files={this.currentInfo.fileBmv}
            style={{ paddingTop: "3rem" }}
          />
          <ColoredSegments
            title={financiera.conferencias[this.props.currentlanguage]}
            list={
              this.props.currentlanguage === "_es"
                ? conferencias
                : conferenciasen
            }
            fileName={"conferences"}
            files={this.currentInfo.fileConferences}
            style={{ paddingTop: "3rem" }}
          />
          {/* <div
            onClick={() => {
              this.setState({ formModal: true });
            }}
            style={{ backgroundColor: "red" }}
          > */}
          <ColoredSegments
            modalFirst={false}
            title={financiera.audio[this.props.currentlanguage]}
            list={
              this.props.currentlanguage === "_es"
                ? conferenciasaudio
                : conferenciasaudioen
            }
            fileName={"audio"}
            files={this.currentInfo.fileAudio}
            style={{ paddingTop: "3rem" }}
          />
          {/* </div> */}
        </Container>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ReportesTrimestrales)
);
