import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { Responsive, Transition } from 'semantic-ui-react';
import API from './API/API';
import Footer from './components/Footer';
import MenuComp from './components/MenuComp';
import MenuItems from './components/MenuItems';
import MetaTagComp from './components/MetaTagComp';
import BursatilPage from './pages/BursatilPage';
import BuzonTransparencia from './pages/BuzonTransparencia';
import ConsorcioAraPage from './pages/ConsorcioAraPage';
import CorporativoPage from './pages/CorporativoPage';
import EventosPage from './pages/EventosPage';
import FinancierosPage from './pages/FinancierosPage';
import HomePage from './pages/HomePage';
import LineaEticaPage from './pages/LineaEticaPage';
import Privacy from './pages/Privacy';
import ResponsiblePersons from './pages/ResponsiblePersons';
import SearchPage from './pages/SearchPage';
import SustentabilidadPage from './pages/SustentabilidadPage';
import { setAPI, setLanguage, setPages } from './redux/actions';
import './style/globalstyle.css';
import './style/quill.snow.css';

const menuoptions = [
  {
    id: 1,
    name: 'Consorcio ara',
    suboptions: [
      {
        name: 'Perfil Corporativo',
        navigateto: '/consorcio/perfil-corporativo',
      },
      { name: 'Filosofía', navigateto: '/consorcio/filosofia' },
      { name: 'Historia', navigateto: '/consorcio/historia' },
      { name: 'Fortalezas Ara', navigateto: '/consorcio/fortalezas-ara' },
      { name: 'Portafolio de productos', navigateto: '/consorcio/portafolio' },
      {
        name: 'Presencia geográfica',
        navigateto: '/consorcio/presencia-geografica',
      },
      {
        name: 'Estructura accionaria',
        navigateto: '/consorcio/estructura-accionaria',
      },
      { name: 'Reconocimientos', navigateto: '/consorcio/reconocimientos' },
    ],
  },
  {
    id: 2,
    name: 'Financiera',
    suboptions: [
      {
        name: 'Reportes trimestrales',
        navigateto: '/financieros/reportes-trimestrales',
      },
      { name: 'Reportes anuales', navigateto: '/financieros/reportes-anuales' },
      {
        name: 'Presentación corporativa',
        navigateto: '/financieros/presentacion-corporativa',
      },
      { name: 'Calificaciones', navigateto: '/financieros/calificaciones' },
    ],
  },
  {
    id: 3,
    name: 'Bursátil',
    suboptions: [
      { name: 'Cotización de la acción', navigateto: '/bursatil/cotizacion' },
      {
        name: 'Gráfica interactiva',
        navigateto: '/bursatil/grafica-interactiva',
      },
      {
        name: 'Precios históricos',
        navigateto: '/bursatil/precios-historicos',
      },
      { name: 'Dividendos', navigateto: '/bursatil/dividendos' },
      { name: 'Analistas', navigateto: '/bursatil/analistas' },
      {
        name: 'Prospectos de colocación',
        navigateto: '/bursatil/prospectos-colocacion',
      },
    ],
  },
  {
    id: 4,
    name: 'Gobierno Corporativo',
    suboptions: [
      {
        name: 'Consejo y comités',
        navigateto: '/corporativo/consejo-comites',
      },
      { name: 'Equipo directivo', navigateto: '/corporativo/equipo-directivo' },
      { name: 'Estatutos', navigateto: '/corporativo/estatutos' },
      {
        name: 'Mejores prácticas',
        navigateto: '/corporativo/mejores-practicas',
      },
      { name: 'Código de ética', navigateto: '/corporativo/codigo-etica' },
      {
        name: 'Asamblea de accionistas',
        navigateto: '/corporativo/asamblea-accionistas',
      },
    ],
  },
  {
    id: 5,
    name: 'Sustentabilidad',
    suboptions: [
      {
        name: 'Vivienda y entorno sustentable',
        navigateto: '/sustentabilidad/vivienda',
      },
      { name: 'ISV análisis', navigateto: '/sustentabilidad/analisis' },
      { name: 'Informe', navigateto: '/sustentabilidad/informe' },
      { name: 'Fundación ara', navigateto: '/sustentabilidad/fundacion-ara' },
      {
        name: "Declaratoria de compromiso de Edge",
        navigateto: "/sustentabilidad/declaratoria-edge",
      }
    ],
  },
  {
    id: 6,
    name: 'Eventos',
    navigateto: '/eventos',
    suboptions: [
      {
        name: 'Eventos',
        navigateto: '/eventos',
      },
    ],
  },
  {
    id: 7,
    name: 'Línea Ética',
    suboptions: [
      {
        name: 'Línea Ética',
        navigateto: '/linea-etica',
      },
    ],
  },
];

const menuoptionseng = [
  {
    id: 1,
    name: 'Company',
    suboptions: [
      {
        name: 'Corporate Profile',
        navigateto: '/consorcio/perfil-corporativo',
      },
      { name: 'Philosophy', navigateto: '/consorcio/filosofia' },
      { name: 'History', navigateto: '/consorcio/historia' },
      { name: 'Ara Strengths', navigateto: '/consorcio/fortalezas-ara' },
      { name: 'Product Portfolio', navigateto: '/consorcio/portafolio' },
      {
        name: 'Geographic Footprint',
        navigateto: '/consorcio/presencia-geografica',
      },
      {
        name: 'Ownership Structure',
        navigateto: '/consorcio/estructura-accionaria',
      },
      { name: 'Awards', navigateto: '/consorcio/reconocimientos' },
    ],
  },
  {
    id: 2,
    name: 'Financial',
    suboptions: [
      {
        name: 'Quarterly Reports',
        navigateto: '/financieros/reportes-trimestrales',
      },
      { name: 'Annual Reports', navigateto: '/financieros/reportes-anuales' },
      {
        name: 'Corporate Presentation',
        navigateto: '/financieros/presentacion-corporativa',
      },
      { name: 'Credit Ratings', navigateto: '/financieros/calificaciones' },
    ],
  },
  {
    id: 3,
    name: 'Stock',
    suboptions: [
      { name: 'Stock Quote', navigateto: '/bursatil/cotizacion' },
      {
        name: 'Stock Chart',
        navigateto: '/bursatil/grafica-interactiva',
      },
      {
        name: 'Historical Prices',
        navigateto: '/bursatil/precios-historicos',
      },
      { name: 'Dividends', navigateto: '/bursatil/dividendos' },
      { name: 'Analyst Coverage', navigateto: '/bursatil/analistas' },
      {
        name: 'Offering Prospectuses',
        navigateto: '/bursatil/prospectos-colocacion',
      },
    ],
  },
  {
    id: 4,
    name: 'Corporate Governance',
    suboptions: [
      {
        name: 'Board and Committees',
        navigateto: '/corporativo/consejo-comites',
      },
      {
        name: 'Corporate Directors',
        navigateto: '/corporativo/equipo-directivo',
      },
      { name: 'Bylaws', navigateto: '/corporativo/estatutos' },
      {
        name: 'Best Practices',
        navigateto: '/corporativo/mejores-practicas',
      },
      { name: 'Code of Ethics', navigateto: '/corporativo/codigo-etica' },
      {
        name: "Shareholders' Meeting",
        navigateto: '/corporativo/asamblea-accionistas',
      },
    ],
  },
  {
    id: 5,
    name: 'Sustainability',
    suboptions: [
      {
        name: 'Housing and Sustainable Environment',
        navigateto: '/sustentabilidad/vivienda',
      },
      { name: 'HSI Analysis', navigateto: '/sustentabilidad/analisis' },
      { name: 'Report', navigateto: '/sustentabilidad/informe' },
      { name: 'ARA Foundation', navigateto: '/sustentabilidad/fundacion-ara' },
      {
        name: "EDGE Commitment Statement",
        navigateto: "/sustentabilidad/declaratoria-edge",
      }
    ],
  },
  {
    id: 6,
    name: 'Press',
    navigateto: '/eventos',
    suboptions: [
      {
        name: 'Press',
        navigateto: '/eventos',
      },
    ],
  },
  {
    id: 7,
    name: 'Ethical Line',
    suboptions: [
      {
        name: 'Ethical Line',
        navigateto: '/linea-etica',
      },
    ],
  },
];

export const getLangOptions = (lng) => {
  const returnLang = lng === '_es' ? menuoptions : menuoptionseng;
  return returnLang;
};

class Navigator extends Component {
  state = { activeItem: 'INICIO', mobileMenuVisible: false };

  componentWillMount() {
    const api = new API();
    api.getPages('Consorcio Ara').then((responsePages) => {
      this.props.setPages(responsePages[0].pages);
    });
  }

  componentDidMount() {
    if (
      sessionStorage.getItem('currentlanguage') === null ||
      sessionStorage.getItem('currentlanguage') === ''
    ) {
      this.props.setLanguage('_es');
    } else {
      this.props.setLanguage(sessionStorage.getItem('currentlanguage'));
    }
  }

  renderPages() {
    if (this.state.mobileMenuVisible === false) {
      return (
        <div>
          <MetaTagComp section="home" />
          <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/consorcio" component={ConsorcioAraPage} />
            <Route path="/bursatil" component={BursatilPage} />
            <Route path="/financieros" component={FinancierosPage} />
            <Route path="/corporativo" component={CorporativoPage} />
            <Route path="/sustentabilidad" component={SustentabilidadPage} />
            <Route path="/eventos" component={EventosPage} />
            <Route path="/buzon" component={BuzonTransparencia} />
            <Route path="/privacidad" component={Privacy} />
            <Route path="/responsable" component={ResponsiblePersons} />
            <Route path="/busqueda" component={SearchPage} />
            <Route path="/linea-etica" component={LineaEticaPage} />
          </Switch>
          <Footer />
        </div>
      );
    }
  }

  renderInDesktop() {
    return (
      <div>
        <MenuComp listitem={getLangOptions(this.props.currentlanguage)} />
        {this.renderPages()}
      </div>
    );
  }

  renderInMobile() {
    return (
      <div>
        <MenuComp
          onClickMobileMenu={() => {
            this.setState({
              mobileMenuVisible: !this.state.mobileMenuVisible,
            });
          }}
          mobileMenuVisible={this.state.mobileMenuVisible}
          listitem={menuoptions}
        />
        <Transition
          visible={this.state.mobileMenuVisible}
          animation="fade"
          duration={300}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                backgroundColor: 'black',
              }}
              className="menum"
              align="center"
            >
              <MenuItems
                onClickMobileMenu={() => {
                  this.setState({
                    mobileMenuVisible: !this.state.mobileMenuVisible,
                  });
                }}
                listitem={getLangOptions(this.props.currentlanguage)}
              />
            </div>
          </div>
        </Transition>
        {this.renderPages()}
      </div>
    );
  }

  renderInTablet() {
    return (
      <div>
        <MenuComp
          onClickMobileMenu={() => {
            this.setState({
              mobileMenuVisible: !this.state.mobileMenuVisible,
            });
          }}
          mobileMenuVisible={this.state.mobileMenuVisible}
          listitem={menuoptions}
        />
        <Transition
          visible={this.state.mobileMenuVisible}
          animation="fade"
          duration={300}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                backgroundColor: 'black',
              }}
              className="menum"
              align="center"
            >
              <MenuItems
                onClickMobileMenu={() => {
                  this.setState({
                    mobileMenuVisible: !this.state.mobileMenuVisible,
                  });
                }}
                listitem={getLangOptions(this.props.currentlanguage)}
              />
            </div>
          </div>
        </Transition>
        {this.renderPages()}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ state, data }) => {
  const { pages, currentlanguage } = data;
  return {
    state,
    pages,
    currentlanguage,
  };
};

const mapDispatchProps = (dispatch) => {
  return {
    setPages: (pages) => {
      dispatch(setPages(pages));
    },
    setAPI: (api) => {
      dispatch(setAPI(api));
    },
    setLanguage: (currentlanguage) => {
      dispatch(setLanguage(currentlanguage));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchProps
  )(Navigator)
);
