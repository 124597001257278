import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import { banner1 } from "../../assets/Assets";
import SliderComponent from "../../components/SliderComponent";

const slider = [
  {
    id: 1,
    img: banner1,
    desc:
      "Alrededor de 167mil metros cuadrados de área bruta arrendable en 6 centros comerciales, unicentros y minicentros."
  }
];

class Home3 extends Component {
  constructor(props) {
    super(props);
  }

  renderInDesktop() {
    return (
      <div style={{ paddingBottom: "8rem" }}>
        <div
          style={{
            backgroundColor: "#EDF4FA",
            height: "20rem",
            width: "100%",
            position: "absolute",
            zIndex: -2
          }}
        />
        <div
          style={{
            backgroundColor: "#00457C",
            height: "26rem",
            width: "100%",
            position: "absolute",
            zIndex: -1,
            top: "112rem"
          }}
        />
        <Container>
          <SliderComponent
            slider={this.slider}
            bgtextcolor="rgba(0,0,0,0.5)"
            desccolor="white"
            bodysize="1.4rem"
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ paddingBottom: "8rem" }}>
        <div
          style={{
            backgroundColor: "#EDF4FA",
            height: "20rem",
            width: "100%",
            position: "absolute",
            zIndex: -2
          }}
        />
        <div
          style={{
            backgroundColor: "#00457C",
            height: "28rem",
            width: "100%",
            position: "absolute",
            zIndex: -1,
            top: "108rem"
          }}
        />
        <Container>
          <SliderComponent
            slider={this.slider}
            bgtextcolor="rgba(0,0,0,0.5)"
            desccolor="white"
            bodysize="1.4rem"
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ backgroundColor: "#EDF4FA", paddingBottom: "8rem" }}>
        <Container>
          <SliderComponent
            slider={this.slider}
            bgtextcolor="rgba(0,0,0,0.5)"
            desccolor="white"
            bodysize="0.8rem"
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Home3)
);
