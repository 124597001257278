import React, { Component } from 'react';
import {
  Grid,
  Header,
  Icon,
  Responsive,
  Segment,
  Form,
  Checkbox,
  Button,
  Modal,
} from 'semantic-ui-react';
import { GetFileFromArray } from '../scripts';
import YearSelector from './YearSelector';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class ColoredSegments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFirst: false,
    };
  }
  componentWillReceiveProps(nextProps) {}

  close = () => this.setState({ formModal: false });

  renderModal(href) {
    return (
      <Modal
        open={this.state.formFirst}
        onClose={this.close}
        style={{ padding: 50 }}
        closeIcon
      >
        <Form>
          <Form.Field>
            <label>Nombre</label>
            <input placeholder='Nombre' />
          </Form.Field>
          <Form.Field>
            <label>E-mail</label>
            <input placeholder='E-mail' />
          </Form.Field>
          <Form.Field>
            <label>Compañía</label>
            <input placeholder='Compañía' />
          </Form.Field>
          <Form.Field>
            <label>Teléfono</label>
            <input placeholder='Teléfono' />
          </Form.Field>
          <a href={href}>Enviar</a>
          <Button onClose={this.close} type='submit'>
            Cancelar
          </Button>
        </Form>
      </Modal>
    );
  }

  renderInDesktop() {
    return (
      <div style={{ ...this.props.style }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: this.props.marginbot,
          }}
        >
          <h2
            style={{
              fontSize: '1.6rem',
              fontWeight: 'normal',
              paddingBottom: '2rem',
            }}
          >
            {this.props.title}
          </h2>
          {this.props.selector ? (
            <YearSelector options={this.props.options} />
          ) : (
            ''
          )}
        </div>
        <Grid columns={4}>
          {this.props.list.map((item, index) => {
            const fileNameAdd =
              this.props.onlyFileName === true ? '' : (index + 1).toString();
            let getFile = GetFileFromArray(
              this.props.files,
              this.props.fileName + fileNameAdd,
              this.props.currentlanguage
            );
            if (this.props.singleFile === true) {
              getFile = item.files || '';
            }
            return (
              <Grid.Column
                computer={4}
                tablet={4}
                mobile={8}
                centered
                style={{ width: '100%' }}
              >
                {this.props.modalFirst ? (
                  <>
                    {this.renderModal(getFile)}
                    <Segment
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: item.bgcolor,
                        border: 0,
                        boxShadow: '0px 0px 0px',
                        width: '100%',
                        borderRadius: 0,
                        cursor: getFile === '' ? 'default' : 'pointer',
                        height: this.props.segmentHeight,
                      }}
                      onClick={() => {
                        this.setState({ formFirst: true });
                      }}
                      target='_blank'
                      disabled={getFile === '' ? true : false}
                    >
                      <Header
                        icon
                        style={{
                          fontSize: '1.3rem',
                          margin: '2rem 0rem',
                        }}
                      >
                        <Icon
                          size='large'
                          style={{
                            fontSize: '1.5em',
                            marginBottom: '1rem',
                            color: 'white',
                          }}
                          name='download'
                        />{' '}
                        <label
                          style={{
                            fontSize: this.props.fontSize || '1.3rem',
                            fontWeight: 'normal',
                            color: 'white',
                            textTransform: 'uppercase',
                          }}
                        >
                          {item.name}
                        </label>
                      </Header>
                    </Segment>
                  </>
                ) : (
                  <Segment
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: item.bgcolor,
                      border: 0,
                      boxShadow: '0px 0px 0px',
                      width: '100%',
                      borderRadius: 0,
                      cursor: getFile === '' ? 'default' : 'pointer',
                      height: this.props.segmentHeight,
                    }}
                    href={getFile}
                    target='_blank'
                    disabled={getFile === '' ? true : false}
                  >
                    <Header
                      icon
                      style={{
                        fontSize: '1.3rem',
                        margin: '2rem 0rem',
                      }}
                    >
                      <Icon
                        size='large'
                        style={{
                          fontSize: '1.5em',
                          marginBottom: '1rem',
                          color: 'white',
                        }}
                        name='download'
                      />{' '}
                      <label
                        style={{
                          fontSize: this.props.fontSize || '1.3rem',
                          fontWeight: 'normal',
                          color: 'white',
                          textTransform: 'uppercase',
                        }}
                      >
                        {item.name}
                      </label>
                    </Header>
                  </Segment>
                )}
              </Grid.Column>
            );
          })}
        </Grid>
      </div>
    );
  }
  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ColoredSegments)
);
