import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Grid, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { consorcioara } from "../../assets/Language";
import Comptitle from "../../components/Comptitle";
import {
  GetContent,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent
} from "../../scripts";

class Filosofia extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(response[0], "philosophy", "main");

        

        this.forceUpdate();
      });

      api.getImageOf(pageId).then(responseImage => {
        this.sectionImages = GetContent(responseImage[0], "philosophy", "main");
        // 
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.filosofia[this.props.currentlanguage]}
          </h1>
          <Grid columns={2} style={{ paddingTop: "2rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgMission",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.mision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "mission",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.vision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "vision",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgVision",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2} style={{ paddingTop: "2rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgValues",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.valores[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "values",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.filosofia[this.props.currentlanguage]}
          </h1>
          <Grid columns={2} style={{ paddingTop: "2rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgMission",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.mision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "mission",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.vision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "vision",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgVision",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
          </Grid>
          <Grid columns={2} style={{ paddingTop: "2rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgValues",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.valores[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "values",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {consorcioara.filosofia[this.props.currentlanguage]}
          </h1>
          <Grid stackable columns={2} style={{ paddingTop: "2rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgMission",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.mision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "mission",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable columns={2} style={{ padding: "1.5rem 0rem" }}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgVision",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.vision[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "vision",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
          <Grid stackable columns={2} style={{}}>
            <Grid.Column width={8}>
              <Image
                fluid
                src={GetImageFromContent(
                  this.sectionImages,
                  "imgValues",
                  this.props.currentlanguage
                )}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width={8}>
              <Comptitle
                richtext
                title={consorcioara.valores[this.props.currentlanguage]}
                titlesize="1.5rem"
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: GetRichTextFromContent(
                    this.sectionContent,
                    "values",
                    this.props.currentlanguage
                  )
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Filosofia)
);
