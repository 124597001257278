import React, { Component } from "react";
import { Container, Responsive } from "semantic-ui-react";
import { bursatil } from "../../assets/Language";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class GraficaInteractiva extends Component {
  constructor(props) {
    super(props);
  }
  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {bursatil.grafica[this.props.currentlanguage]}
          </h1>
          <div style={{ height: "32rem" }}>
            {this.props.currentlanguage === "_es" ? (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            ) : (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica_ing.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            )}
          </div>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {bursatil.grafica[this.props.currentlanguage]}
          </h1>
          <div style={{ height: "32rem" }}>
            {this.props.currentlanguage === "_es" ? (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            ) : (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica_ing.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            )}
          </div>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "3rem"
            }}
          >
            {bursatil.grafica[this.props.currentlanguage]}
          </h1>
          <div style={{ height: "32rem" }}>
            {this.props.currentlanguage === "_es" ? (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            ) : (
              <iframe
                height="500"
                width="100%"
                style={{ border: 0 }}
                src="https://www.pixelsiete.com/clientes/web/financialdata/ara/interactiva_grafica_ing.php"
                allow-forms
                allow-pointer-lock
                allow-popups
                allow-same-origin
                allow-scripts
                allow-top-navigation
              />
            )}
          </div>
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(GraficaInteractiva)
);
