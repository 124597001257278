import React, { Component } from "react";
import { Dropdown, Responsive } from "semantic-ui-react";

class YearSelector extends Component {
  constructor(props) {
    super(props);
  }
  renderInDesktop() {
    return (
      <Dropdown
        placeholder="Reportes"
        selection
        options={this.props.options}
        fluid
        value={this.props.value}
        style={{
          borderRadius: "20px",
          border: "2px solid #014078",
          width: "15rem",
          height: "3rem",
          ...this.props.style
        }}
        onChange={(event, data) => {
          const dataReturn = this.props.options.find(option => {
            return option.value === data.value;
          });

          this.props.returnYearSelected(dataReturn);
        }}
      />
    );
  }
  renderInTablet() {
    return (
      <Dropdown
        placeholder="Reportes"
        selection
        options={this.props.options}
        fluid
        value={this.props.value}
        style={{
          borderRadius: "20px",
          border: "2px solid #014078",
          width: "15rem",
          height: "3rem",
          ...this.props.style
        }}
        onChange={(event, data) => {
          const dataReturn = this.props.options.find(option => {
            return option.value === data.value;
          });

          
          this.props.returnYearSelected(dataReturn);
        }}
      />
    );
  }
  renderInMobile() {
    return (
      <Dropdown
        placeholder="Reportes"
        selection
        options={this.props.options}
        fluid
        value={this.props.value}
        style={{
          borderRadius: "20px",
          border: "2px solid #014078",
          width: "auto",
          height: "3rem",
          ...this.props.style
        }}
        onChange={(event, data) => {
          const dataReturn = this.props.options.find(option => {
            return option.value === data.value;
          });

          
          this.props.returnYearSelected(dataReturn);
        }}
      />
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
export default YearSelector;
