import React from "react";
import { Responsive } from "semantic-ui-react";

export const styleBasic = {
  backgroundColor: "transparent",
  border: "0px",
  margin: 0,
  padding: 0,
  elevation: 0,
  borderRadius: 0,
  borderColor: 0,
  borderBottomColor: "transparent",
  borderBottomWidth: 0,
  boxShadow: "0px 0px 0px"
};

export const backgroundCentered = {
  position: "fixed",
  backgroundSize: "cover",
  backgroundPosition: "center"
};

export const styleCenteredObject = {
  display: "-webkit-flex",
  WebkitAlignContent: "center",
  WebkitAlignSelf: "center",
  WebkitAlignItems: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  alignSelf: "center",
  textAlign: "center"
};

export const styleBasicCenteredObject = {
  ...styleBasic,
  ...styleCenteredObject
};

export const separationPages = () => {
  return (
    <div>
      <Responsive {...Responsive.onlyMobile}>
        <div style={{ height: "2rem" }} />
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <div style={{ height: "3rem" }} />
      </Responsive>
      <Responsive {...Responsive.onlyComputer}>
        <div style={{ height: "4rem" }} />
      </Responsive>
    </div>
  );
};
