import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Container } from 'semantic-ui-react';
import API from '../../API/API';
import { consorcioara } from '../../assets/Language';
import Comptitle from '../../components/Comptitle';
import { GetContent, GetPage, GetRichTextFromContent } from '../../scripts';

class PerfilCorporativo extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.sectionImages = {};
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'ara').id;

      api.getContentOf(pageId).then((response) => {
        this.sectionContent = GetContent(
          response[0],
          'corporateProfile',
          'main'
        );

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ margin: '3rem 0rem' }}>
        <Container>
          <div>
            <Comptitle
              simple
              title={consorcioara.perfilcorp[this.props.currentlanguage]}
              titlecolor="#014078"
              titlebold
              titlesize="2.5rem"
            />
          </div>
          <div style={{ paddingBottom: '1rem' }}>
            <div
              dangerouslySetInnerHTML={{
                __html: GetRichTextFromContent(
                  this.sectionContent,
                  'body',
                  this.props.currentlanguage
                ),
              }}
            />
          </div>
        </Container>
      </div>
    );
  }

  render() {
    return <div>{this.renderInDesktop()}</div>;
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(PerfilCorporativo)
);
