import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { sustentabilidad } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import { GetContent, GetPage, GetTextFromContent } from "../../scripts";

const convocatorias = [
  {
    id: 1,
    name: "Informe Anual de 2017",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 2,
    name: "Informe Anual de 2016",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 3,
    name: "Informe Anual de 2015",
    bgcolor: "#79bee9",
    disabled: false
  },
  {
    id: 4,
    name: "Informe Anual de 2014",
    bgcolor: "#79bee9",
    disabled: true
  }
];

class Informe extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.array = [];
    this.arrayFiles = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "sustainability").id;
      api.getContentOf(pageId).then(response => {
        const currentContent = GetContent(response[0], "report", "main");
        api.getFilesOf(pageId).then(responseFiles => {
          const currentFiles = GetContent(responseFiles[0], "report", "main");
          const dataContentFiles = currentFiles.dataContentFiles;
          this.arrayFiles = dataContentFiles.filter(file => {
            return file.name.includes("file");
          });
          this.array = [];
          for (let i = 0; i < 3; i++) {
            const dataToCreate = {
              id: i,
              name: GetTextFromContent(
                currentContent,
                "name" + (i + 1).toString(),
                this.props.currentlanguage
              ),
              bgcolor: "#79bee9"
            };
            this.array.push(dataToCreate);
            
            
            
          }
          this.forceUpdate();
        });
        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {sustentabilidad.informe[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            list={this.array}
            fileName={"file"}
            files={this.arrayFiles}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "2rem"
            }}
          >
            {sustentabilidad.informe[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            list={this.array}
            fileName={"file"}
            files={this.arrayFiles}
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1
            style={{
              color: "#014078",
              fontSize: "2.5rem",
              paddingBottom: "1rem"
            }}
          >
            {sustentabilidad.informe[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            list={this.array}
            fileName={"file"}
            files={this.arrayFiles}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Informe)
);
