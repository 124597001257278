import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Grid, Image, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { certification, esrcolor, premio1 } from "../../assets/Assets";
import { consorcioara } from "../../assets/Language";
import {
  GetArrayALike,
  GetImageFromContent,
  GetPage,
  GetRichTextFromContent,
  UpdateArrayImage
} from "../../scripts";

{
  /* <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
    Reconocimientos
  </h1>
  <Comptitle
    simple
    title="Premio Nacional de Vivienda 2016"
    body="Consorcio ARA recibió el Premio Nacional de Vivienda 2016 por su desarrollo habitacional “Misión de las Flores III” en la categoría de Interés Social, en la cual se consideraron aspectos como la ubicación, diseño urbano, densificación, factibilidad de servicios, conectividad, financiamiento, asequibilidad, así como de accesibilidad para personas con discapacidad. Misión de las Flores III está ubicado en el Municipio de Solidaridad en Playa del Carmen, Quintana Roo, cuenta con una superficie de poco más de 150,000m2 en donde se edificaron 1,200 viviendas de tipo vertical en 4 niveles."
  /> */
}

const reconocimientos = [
  {
    id: 1,
    title: "Premio Nacional de Vivienda 2016",
    body:
      "Consorcio ARA recibió el Premio Nacional de Vivienda 2016 por su desarrollo habitacional “Misión de las Flores III” en la categoría de Interés Social, en la cual se consideraron aspectos como la ubicación, diseño urbano, densificación, factibilidad de servicios, conectividad, financiamiento, asequibilidad, así como de accesibilidad para personas con discapacidad. Misión de las Flores III está ubicado en el Municipio de Solidaridad en Playa del Carmen, Quintana Roo, cuenta con una superficie de poco más de 150,000m2 en donde se edificaron 1,200 viviendas de tipo vertical en 4 niveles.",
    img: premio1
  },
  {
    id: 2,
    title: "Great Place To Work",
    body:
      "En 2017 el Great Place To Work Institute Mexico certificó a Consorcio ARA como un gran lugar para trabajar, en la categoría de 500 a 5,000 colaboradores y multinacionales. Este hecho nos llena de orgullo y nos compromete a seguir construyendo lugares y equipos de trabajo de excelencia, además de invertir y desarrollar el talento de nuestra gente.",
    img: certification
  },
  {
    id: 3,
    title: "Empresa Socialmente Responsable",
    body:
      "Desde el año 2006 Consorcio ARA ha recibido el distintivo de Empresa Socialmente Responsable que otorga el Centro Mexicano para la Filantropía (CEMEFI), por ser una Compañía con un desempeño económico, social y ambiental responsable en toda la gestión.",
    img: esrcolor
  }
];

class Reconocimientos extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.array = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "ara").id;

      api.getContentOf(pageId).then(response => {
        this.array = GetArrayALike(response[0], "acknowledgments", "award");

        api.getImageOf(pageId).then(responseImages => {
          
          this.array = UpdateArrayImage(
            responseImages[0],
            "acknowledgments",
            this.array
          );

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ margin: "1rem 0rem" }}>
        <Grid columns={2}>
          <Grid.Column style={{ padding: "5rem 0rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "60%" }}>
                <Grid>
                  <Grid.Row style={{ height: "5rem" }}>
                    <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
                      {consorcioara.reconocimientos[this.props.currentlanguage]}
                    </h1>
                  </Grid.Row>
                </Grid>
                {this.array.map(item => {
                  return (
                    <Grid>
                      <Grid.Row
                        style={{ height: "25rem", paddingBottom: "3rem" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: GetRichTextFromContent(
                              item,
                              "body",
                              this.props.currentlanguage
                            )
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{ backgroundColor: "#EDF4FA", padding: "5rem 0rem" }}
            textAlign="center"
            align="center"
          >
            <Grid.Row style={{ height: "5rem" }} />
            {this.array.map(item => {
              return (
                <Grid>
                  <Grid.Row style={{ height: "25rem", paddingBottom: "3rem" }}>
                    <div
                      style={{
                        backgroundImage:
                          "url(" +
                          GetImageFromContent(
                            item,
                            "img",
                            this.props.currentlanguage
                          ) +
                          ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                  </Grid.Row>
                </Grid>
              );
            })}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ margin: "0rem" }}>
        <Grid columns={2} style={{ width: "100%", margin: 0 }}>
          <Grid.Column style={{ padding: "5rem 0rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "85%" }}>
                <Grid>
                  <Grid.Row>
                    <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
                      {consorcioara.reconocimientos[this.props.currentlanguage]}
                    </h1>
                  </Grid.Row>
                </Grid>
                {this.array.map(item => {
                  return (
                    <Grid style={{ paddingBottom: "5rem" }}>
                      <Grid.Row style={{ height: "20rem" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: GetRichTextFromContent(
                              item,
                              "body",
                              this.props.currentlanguage
                            )
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            style={{ backgroundColor: "#EDF4FA", padding: "5rem 0rem" }}
            textAlign="center"
            align="center"
          >
            <Grid.Row style={{ height: "5rem" }} />
            {this.array.map(item => {
              return (
                <Grid style={{ width: "100%", justifyContent: "center" }}>
                  <Grid.Row style={{ height: "25rem", paddingBottom: "0rem" }}>
                    <div
                      style={{
                        backgroundImage:
                          "url(" +
                          GetImageFromContent(
                            item,
                            "img",
                            this.props.currentlanguage
                          ) +
                          ")",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        width: "80%",
                        height: "100%"
                      }}
                    />
                  </Grid.Row>
                </Grid>
              );
            })}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ margin: "2.5rem 0rem" }}>
        <Grid stackable columns={2}>
          <Grid.Column style={{ padding: "5rem 0rem" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{}}>
                <Grid>
                  <Grid.Row style={{ paddingLeft: "1rem" }}>
                    <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
                      {consorcioara.reconocimientos[this.props.currentlanguage]}
                    </h1>
                  </Grid.Row>
                </Grid>
                {this.array.map(item => {
                  return (
                    <Grid stackable style={{ paddingBottom: "5rem" }}>
                      <Grid.Column
                        style={{
                          backgroundColor: "#EDF4FA"
                        }}
                      >
                        <Image
                          src={GetImageFromContent(
                            item,
                            "img",
                            this.props.currentlanguage
                          )}
                          style={{
                            maxHeight: "23rem",
                            marginTop: "2rem",
                            marginBottom: "2rem"
                          }}
                          centered
                        />
                      </Grid.Column>
                      <Grid.Column
                        style={{
                          paddingBottom: "2rem",
                          width: "90%"
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: GetRichTextFromContent(
                              item,
                              "body",
                              this.props.currentlanguage
                            )
                          }}
                        />
                      </Grid.Column>
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Reconocimientos)
);
