import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Container, Responsive } from "semantic-ui-react";
import API from "../../API/API";
import { bursatil } from "../../assets/Language";
import ColoredSegments from "../../components/ColoredSegments";
import {
  GetArrayALike,
  GetContent,
  GetFileFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile
} from "../../scripts";

const informes = [
  {
    id: 1,
    name: "Prospecto Definitivo",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 2,
    name: "Suplemento Definitivo",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 3,
    name: "Oferta Secundaria 2006",
    bgcolor: "#8CC366",
    disabled: false
  },
  {
    id: 4,
    name: "Oferta Pública Inicial 1996",
    bgcolor: "#8CC366",
    disabled: false
  }
];

class ProspectosDeColocacion extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.sectionContent = {};
    this.array = [];
    this.currentArray = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "stockInfo").id;

      api.getContentOf(pageId).then(response => {
        this.sectionContent = GetContent(
          response[0],
          "placementProspects",
          "info"
        );

        this.array = GetArrayALike(
          response[0],
          "placementProspects",
          "prospects"
        );

        

        api.getFilesOf(pageId).then(responseFiles => {
          
          this.array = UpdateArrayFile(
            responseFiles[0],
            "placementProspects",
            this.array
          );
          this.currentArray = [];
          for (let i = 0; i < this.array.length; i++) {
            const currentFile = this.array[i];
            
            let data = {};
            data.name = GetTextFromContent(
              currentFile,
              "title",
              this.props.currentlanguage
            );
            data.files = GetFileFromContent(
              currentFile,
              "file",
              this.props.currentlanguage
            );
            data.bgcolor = "#8CC366";
            this.currentArray.push(data);
            
          }
          this.forceUpdate();

          this.forceUpdate();
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {bursatil.prospectos[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            segmentHeight="12rem"
            list={this.currentArray}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: "5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2.5rem" }}>
            {bursatil.prospectos[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            list={this.currentArray}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "0.5rem" }}
            segmentHeight="11rem"
            fontSize="1rem"
          />
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: "2.5rem 0rem" }}>
        <Container>
          <h1 style={{ color: "#014078", fontSize: "2rem" }}>
            {bursatil.prospectos[this.props.currentlanguage]}
          </h1>
          <ColoredSegments
            list={this.currentArray}
            singleFile={true}
            fileName={"file"}
            style={{ fontSize: "1rem" }}
          />
        </Container>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ProspectosDeColocacion)
);
