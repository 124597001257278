import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Responsive,
} from 'semantic-ui-react';
import API from '../../API/API';
import { logoara } from '../../assets/Assets';
import { financiera } from '../../assets/Language';
import MaskedImg from '../../components/MaskedImg';
import YearSelector from '../../components/YearSelector';
import {
  GetArrayALike,
  GetFileFromContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent,
  UpdateArrayFile,
  UpdateArrayImage,
} from '../../scripts';

const years = [
  { key: 1, text: '2017', value: '2017' },
  { key: 2, text: '2016', value: '2016' },
  { key: 3, text: '2015', value: '2015' },
  { key: 4, text: '2014', value: '2014' },
  { key: 5, text: '2013', value: '2013' },
];
class ReportesAnuales extends Component {
  constructor(props) {
    super(props);
    this.verifyPages = this.verifyPages.bind(this);
    this.currentInfo = {};
    this.currentValue = '';
    this.array = [];
    this.currentYears = [];
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, 'finacial').id;

      api.getContentOf(pageId).then((response) => {
        this.array = GetArrayALike(response[0], 'annualReports', 'anual');

        api.getImageOf(pageId).then((responseImages) => {
          this.array = UpdateArrayImage(
            responseImages[0],
            'annualReports',
            this.array
          );
          api.getFilesOf(pageId).then((responseFiles) => {
            this.array = UpdateArrayFile(
              responseFiles[0],
              'annualReports',
              this.array
            );
            this.currentYears = [];

            for (let i = 0; i < this.array.length; i++) {
              const currentYear = this.array[i];

              let selector = {};
              selector.key = currentYear.id;
              selector.value = currentYear.id;
              selector.text = GetTextFromContent(
                currentYear,
                'year',
                this.props.currentlanguage
              );
              const dataset = {};
              dataset.id = currentYear.id;
              dataset.leftFile = GetFileFromContent(
                currentYear,
                'leftFile',
                this.props.currentlanguage
              );
              dataset.leftFileTitle = GetTextFromContent(
                currentYear,
                'leftFileTitle',
                this.props.currentlanguage
              );
              dataset.rightFile = GetFileFromContent(
                currentYear,
                'rightFile',
                this.props.currentlanguage
              );
              dataset.rightFileTitle = GetTextFromContent(
                currentYear,
                'rightFileTitle',
                this.props.currentlanguage
              );
              dataset.img = GetImageFromContent(
                currentYear,
                'img',
                this.props.currentlanguage
              );
              selector.data = dataset;

              if (selector.text) {
                this.currentYears.unshift(selector);
              }
            }
            this.currentValue = this.currentYears[0].value;
            this.currentInfo = this.currentYears[0].data;
            this.forceUpdate();
          });
        });

        this.forceUpdate();
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  renderInDesktop() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 style={{ color: '#014078', fontSize: '2.5rem' }}>
              {financiera.reportesan[this.props.currentlanguage]}
            </h1>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <Grid columns={2} style={{ paddingTop: '3rem' }}>
            <Grid.Column width={10}>
              <MaskedImg
                singleButton
                img={this.currentInfo.img}
                file={this.currentInfo.leftFile}
                fileName={financiera.reporte[this.props.currentlanguage]}
                date={this.currentInfo.leftFileTitle}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <div style={{ backgroundColor: '#EFF4F7', height: '100%' }}>
                <Image src={logoara} style={{ padding: '4rem' }} />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    padding: '3rem 3rem',
                    width: '96%',
                  }}
                >
                  <Button
                    style={{
                      borderRadius: 0,
                      backgroundColor: 'white',
                      color: 'black',
                      fontWeight: 'normal',
                      width: '22rem',
                    }}
                    href={this.currentInfo.rightFile}
                    target='_blank'
                  >
                    <Header icon style={{ width: '100%', fontSize: '1.3rem' }}>
                      <Icon
                        size='small'
                        style={{ fontSize: '1.5em' }}
                        color='blue'
                        name='download'
                      />{' '}
                      <label
                        style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
                      >
                        {financiera.informe[this.props.currentlanguage]}
                      </label>
                      <br />
                      {this.currentInfo.rightFileTitle}
                    </Header>
                  </Button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInTablet() {
    return (
      <div style={{ padding: '5rem 0rem' }}>
        <Container>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 style={{ color: '#014078', fontSize: '2.5rem' }}>
              {financiera.reportesan[this.props.currentlanguage]}
            </h1>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
          <Grid columns={2} style={{ paddingTop: '3rem' }}>
            <Grid.Column width={8}>
              <div>
                <div
                  style={{
                    backgroundImage: 'url(' + this.currentInfo.img + ')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'right',
                    backgroundRepeat: 'no-repeat',

                    height: '30rem',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <h1
                      style={{
                        color: 'white',
                        padding: '2rem 0rem 0rem 3rem',
                        fontSize: '2.5rem',
                      }}
                    >
                      {this.currentInfo.leftFileTitle}
                    </h1>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        padding: '2rem 1.5rem',
                        width: '96%',
                      }}
                    >
                      <Button
                        style={{
                          borderRadius: 0,
                          backgroundColor: 'white',
                          color: 'black',
                          fontWeight: 'normal',
                          width: '22rem',
                        }}
                        href={this.currentInfo.leftFile}
                        target='_blank'
                      >
                        <Header icon style={{ fontSize: '1.3rem' }}>
                          <Icon
                            size='large'
                            style={{ fontSize: '1.5em' }}
                            color='blue'
                            name='download'
                          />{' '}
                          <label
                            style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
                          >
                            {financiera.reporte[this.props.currentlanguage]}
                          </label>
                        </Header>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ backgroundColor: '#EFF4F7', height: '100%' }}>
                <Image src={logoara} style={{ padding: '4rem' }} />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    padding: '2rem 1.5rem',
                  }}
                >
                  <Button
                    style={{
                      borderRadius: 0,
                      backgroundColor: 'white',
                      color: 'black',
                      fontWeight: 'normal',
                      width: '22rem',
                    }}
                    href={this.currentInfo.rightFile}
                    target='_blank'
                  >
                    <Header icon style={{ width: '100%', fontSize: '1.3rem' }}>
                      <Icon
                        size='small'
                        style={{ fontSize: '1.5em' }}
                        color='blue'
                        name='download'
                      />{' '}
                      <label
                        style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
                      >
                        {financiera.informe[this.props.currentlanguage]}
                      </label>
                    </Header>
                  </Button>
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </div>
    );
  }
  renderInMobile() {
    return (
      <div style={{ padding: '2.5rem 0rem' }}>
        <Container>
          <h1 style={{ color: '#014078', fontSize: '2.5rem' }}>
            {financiera.reportesan[this.props.currentlanguage]}
          </h1>
          <div style={{ paddingTop: '1rem' }}>
            <YearSelector
              options={this.currentYears}
              value={this.currentValue}
              returnYearSelected={(returnData) => {
                this.currentInfo = returnData.data;
                this.currentValue = returnData.value;
                this.forceUpdate();
              }}
            />
          </div>
        </Container>

        <Grid stackable columns={2} style={{ paddingTop: '3rem' }}>
          <Grid.Column width={10}>
            <div>
              <div
                style={{
                  backgroundImage: 'url(' + this.currentInfo.img + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '30rem',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <h1
                    style={{
                      color: 'white',
                      padding: '2rem 0rem 0rem 3rem',
                      fontSize: '2.5rem',
                    }}
                  >
                    {this.props.title}
                  </h1>
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      padding: '3rem 1.5rem',
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: 0,
                        backgroundColor: 'white',
                        color: 'black',
                        fontWeight: 'normal',
                        width: '22rem',
                      }}
                    >
                      <Header icon style={{ fontSize: '1.3rem' }}>
                        <Icon
                          size='large'
                          style={{ fontSize: '1.5em' }}
                          color='blue'
                          name='download'
                        />{' '}
                        <label
                          style={{ fontSize: '1.5rem', fontWeight: 'normal' }}
                        >
                          {financiera.reporte[this.props.currentlanguage]}
                        </label>
                      </Header>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={6}>
            <div style={{ backgroundColor: '#EFF4F7', height: '100%' }}>
              <Image src={logoara} style={{ padding: '4rem' }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  bottom: 0,
                  paddingBottom: '2rem',
                }}
              >
                <Button
                  style={{
                    borderRadius: 0,
                    backgroundColor: 'white',
                    color: 'black',
                    fontWeight: 'normal',
                    width: '22rem',
                  }}
                >
                  <Header icon style={{ width: '100%', fontSize: '1.3rem' }}>
                    <Icon
                      size='small'
                      style={{ fontSize: '1.5em' }}
                      color='blue'
                      name='download'
                    />{' '}
                    <label style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
                      {financiera.informe[this.props.currentlanguage]}
                    </label>
                    <br />
                    2017
                  </Header>
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}
const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage } = data;
  return {
    pages,
    currentlanguage,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(ReportesAnuales)
);
