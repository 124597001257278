import * as types from "../types";

const INITIAL_STATE = {
  pages: [],
  api: {},
  currentlanguage: "_es"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_PAGES: {
      return { ...state, pages: action.pages };
    }
    case types.SET_API: {
      return { ...state, api: action.api };
    }
    case types.SET_LANGUAGE: {
      sessionStorage.setItem("currentlanguage", action.currentlanguage);
      return { ...state, currentlanguage: action.currentlanguage };
    }
    default: {
      return state;
    }
  }
};
