import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Modal,
  Responsive,
  TextArea
} from "semantic-ui-react";
import API from "../API/API";
import { contactmodal } from "../assets/Language";
import {
  GetContent,
  GetImageFromContent,
  GetPage,
  GetTextFromContent
} from "../scripts";
// import { bg1 } from "../assets/Assets";
import Comptitle from "./Comptitle";

const asunto = [
  {
    id: 1,
    text: "Relación de inversionistas",
    value: "Relación de inversionistas"
  },
  { id: 2, text: "Soy Cliente", value: "Soy Cliente" },
  { id: 3, text: "Quiero Comprar", value: "Quiero Comprar" },
  { id: 4, text: "Proveedores", value: "Proveedores" },
  { id: 5, text: "Recursos Humanos", value: "Recursos Humanos" },
  { id: 6, text: "Otro", value: "Otro" }
];

const asuntoen = [
  {
    id: 1,
    text: "Investors Relations",
    value: "Investors Relations"
  },
  { id: 2, text: "I'm client", value: "I'm client" },
  { id: 3, text: "I want to buy", value: "I want to buy" },
  { id: 4, text: "Suppliers", value: "Suppliers" },
  { id: 5, text: "Human Resources", value: "Human Resources" },
  { id: 6, text: "Other", value: "Other" }
];

class FormComp extends Component {
  constructor(props) {
    super(props);
    this.modal = {};
    this.contact = {};
    this.sectionImages = {};
    this.verifyPages = this.verifyPages.bind(this);
  }

  verifyPages(pages) {
    if (pages.length > 0) {
      const api = new API();
      const pageId = GetPage(pages, "home").id;

      api.getContentOf(pageId).then(response => {
        this.modal = GetContent(response[0], "modalcontent", "info");

        this.forceUpdate();
      });

      // this.props.api.getImageOf(pageId).then(responseImages => {
      //   this.sectionImages = GetContent(responseImages[0], "info", "modal");
      //   
      // });
    }
  }

  componentDidMount() {
    this.verifyPages(this.props.pages);
  }

  componentWillReceiveProps(nextProps) {
    this.verifyPages(nextProps.pages);
  }

  state = {
    name: "",
    email: "",
    submittedEmail: "",
    comentario: "",
    Tel: "",
    subject: "",
    modalOpen: false
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    const { name, email, comment, Tel, subject } = this.state;
    const mensaje =
      "<p>-------------------------Contacto de interesado-----------------------------</p>" +
      "<p>Nombre del contacto:</p>" +
      name +
      "<p></p>" +
      "Asunto: " +
      subject +
      "<p></p>" +
      "<p>Correo del contacto:</p>" +
      email +
      "<p></p>" +
      "<p>Teléfono: </p>" +
      Tel +
      "<p>Mensaje: </p>" +
      comment +
      "<p></p>";

    const correo =
      GetTextFromContent(this.modal, "contactMail") ||
      "dzamarripa@guaostudio.com";

    this.dickapi = new API();
    this.dickapi
      .sendMail(correo, mensaje, "Interesado en Consorcios")
      .then(response => {});
    this.setState({ submittedEmail: email });
    this.setState({ email: "", name: "", comment: "", Tel: "", subject: [] });
    setTimeout(() => {
      this.setState({ submittedEmail: "" });
    }, 3000);
  };

  showModal() {
    const { submittedEmail, modalOpen } = this.state;
    if (modalOpen === true && submittedEmail !== "") {
      return (
        <Modal
          size="large"
          style={{
            borderRadius: 10
          }}
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Modal.Content
            image
            style={{
              height: "20rem",
              // backgroundImage:
              //   "url(" + GetImageFromContent(this.sectionImages, "bg") + ")",
              backgroundSize: "cover",
              borderRadius: 10
            }}
          >
            <Grid columns={2}>
              <Grid.Column width={3} verticalAlign="middle" textAlign="right">
                <Icon fitted name="envelope open outline" size="massive" />
              </Grid.Column>
              <Grid.Column width={11} verticalAlign="middle">
                <Container>
                  <Modal.Description>
                    <Comptitle
                      simple
                      titlesize="2rem"
                      titlecolor="black"
                      bodycolor="black"
                      bodysize="1.2rem"
                      title={GetTextFromContent(
                        this.modal,
                        "title",
                        this.props.currentlanguage
                      )}
                      titlesize="1.3rem"
                      body={GetTextFromContent(
                        this.modal,
                        "desc",
                        this.props.currentlanguage
                      )}
                      bodysize="1rem"
                    />
                  </Modal.Description>
                </Container>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      );
    } else {
      return <div />;
    }
  }

  showModalMobile() {
    const { submittedEmail, modalOpen } = this.state;
    if (modalOpen === true && submittedEmail !== "") {
      return (
        <Modal
          size="large"
          style={{
            borderRadius: 10
          }}
          open={this.state.modalOpen}
          onClose={this.handleClose}
        >
          <Modal.Content
            image
            style={{
              height: "20rem",
              backgroundImage:
                "url(" + GetImageFromContent(this.sectionImages, "bg") + ")",
              backgroundSize: "cover",
              borderRadius: 10
            }}
          >
            <Grid stackable centered columns={2}>
              <Grid.Column width={3} verticalAlign="middle" textAlign="center">
                <Icon fitted name="envelope open outline" size="large" />
              </Grid.Column>
              <Grid.Column width={11} verticalAlign="middle">
                <Container>
                  <Modal.Description>
                    <Comptitle
                      simple
                      titlesize="2rem"
                      titlecolor="white"
                      bodycolor="black"
                      bodysize="1.2rem"
                      title={GetTextFromContent(
                        this.modal,
                        "title",
                        this.props.currentlanguage
                      )}
                      titlesize="1.3rem"
                      body={GetTextFromContent(
                        this.modal,
                        "desc",
                        this.props.currentlanguage
                      )}
                      bodysize="1rem"
                    />
                  </Modal.Description>
                </Container>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      );
    } else {
      return <div />;
    }
  }

  renderInDesktop() {
    const { name, email, comment, Tel, subject } = this.state;

    return (
      <div>
        <Form onSubmit={this.handleSubmit} style={{ padding: 0 }}>
          <Form.Input
            fluid
            placeholder={contactmodal.nombre[this.props.currentlanguage]}
            required
            name="name"
            value={name}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",

              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            placeholder={contactmodal.email[this.props.currentlanguage]}
            required
            name="email"
            value={email}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            placeholder={contactmodal.telefono[this.props.currentlanguage]}
            name="Tel"
            value={Tel}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />
          <Form.Field required>
            <Dropdown
              selection
              placeholder={contactmodal.asunto[this.props.currentlanguage]}
              options={this.props.currentlanguage === "_es" ? asunto : asuntoen}
              style={{
                height: "3.5rem",
                borderWidth: "50"
              }}
              onChange={(event, data) => {
                
                this.setState({ subject: data.value });
              }}
            />
          </Form.Field>

          <Form.Input
            style={{
              height: "8rem",
              paddingBottom: "1rem",
              boxShadow: "0px 0px 0px",
              border: 0
            }}
            fluid
            name="comment"
            value={comment}
            control={TextArea}
            onChange={this.handleChange}
            size="medium"
            placeholder={contactmodal.mensaje[this.props.currentlanguage]}
            required
          />

          <Button
            color="pink"
            floated="left"
            style={{
              height: "3rem",
              width: "12rem",
              backgroundColor: "#79bee9",
              color: "white",
              borderRadius: "25px"
            }}
            onClick={this.handleOpen}
          >
            {contactmodal.boton[this.props.currentlanguage]}
          </Button>
          {this.showModal()}
        </Form>
      </div>
    );
  }

  renderInTablet() {
    const { name, email, comment, Tel } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="Nombre"
            required
            name="name"
            value={name}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",

              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            label="Correo electrónico"
            required
            name="email"
            value={email}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            label="Teléfono"
            name="Tel"
            value={Tel}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />
        </Form.Group>

        <Form.Input
          style={{
            height: "4.5rem",
            backgroundColor: "#F4F4F4",
            paddingBottom: "1rem",
            boxShadow: "0px 0px 0px",
            border: 0
          }}
          name="comment"
          value={comment}
          control={TextArea}
          onChange={this.handleChange}
          size="medium"
          label="Mensaje"
          required
        />

        <Button
          color="green"
          floated="right"
          style={{ height: "3rem", width: "13rem" }}
          onClick={this.handleOpen}
        >
          ENVIAR MENSAJE
          <Icon name="right arrow" />
        </Button>
        {this.showModal()}
      </Form>
    );
  }
  renderInMobile() {
    const { name, email, comment, Tel } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            fluid
            label="Nombre"
            required
            name="name"
            value={name}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",

              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            label="Correo electrónico"
            required
            name="email"
            value={email}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />

          <Form.Input
            fluid
            label="Teléfono"
            name="Tel"
            value={Tel}
            onChange={this.handleChange}
            style={{
              height: "3.5rem",
              borderWidth: "50"
            }}
          />
        </Form.Group>

        <Form.Input
          style={{
            height: "4.5rem",
            backgroundColor: "#F4F4F4",
            paddingBottom: "1rem",
            boxShadow: "0px 0px 0px",
            border: 0
          }}
          fluid
          name="comment"
          value={comment}
          control={TextArea}
          onChange={this.handleChange}
          size="medium"
          label="Mensaje"
          required
        />

        <Button
          color="green"
          floated="right"
          style={{ height: "3rem", width: "12rem" }}
          onClick={this.handleOpen}
        >
          ENVIAR MENSAJE
          <Icon name="right arrow" />
        </Button>
        {this.showModalMobile()}
      </Form>
    );
  }
  render() {
    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          {this.renderInMobile()}
        </Responsive>
        <Responsive {...Responsive.onlyTablet}>
          {this.renderInTablet()}
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          {this.renderInDesktop()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { pages, currentlanguage, user, api } = data;
  return {
    pages,
    currentlanguage
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(FormComp)
);
